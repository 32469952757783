import { SILVER_GRAY } from "#app/colors/colors"
import { DateOutput } from "#app/components"
import { DepositorDto, OrganisationalPersonDto } from "@fixrate/fixrate-query"
import { Stack, Typography } from "@mui/material"
import { isAfter } from "date-fns"
import { useTranslation } from "react-i18next"

type UserInviteStatusProps = {
    person: OrganisationalPersonDto
    depositor: DepositorDto
}

const UserInviteStatus = ({ person, depositor }: UserInviteStatusProps) => {
    const {t} = useTranslation()

    const userInvite = depositor.userInvites.find(invite => invite.id === person.associatedInviteId)
    const inviteNotificationIsSent = !!userInvite?.lastNotification
    const inviteExpired = userInvite ? isAfter(new Date(), new Date(userInvite.expirationDate)) : false

    return userInvite ? (
        <Stack>
            <Typography variant='caption' sx={{ color: SILVER_GRAY[500] }}>
                { !inviteExpired && inviteNotificationIsSent && <i className='ri-time-line' /> }
                { !inviteExpired && !inviteNotificationIsSent && <i className='ri-mail-add-line' /> }
                { inviteExpired && <i className='ri-hourglass-line' /> }

                {inviteExpired &&
                    t('blocks-UsersList.inviteStatusExpired', {
                        date: DateOutput.formatDate(userInvite.requested),
                        expiryDate: DateOutput.formatDate(
                            userInvite.expirationDate
                        ),
                    })}

                {!inviteExpired &&
                    inviteNotificationIsSent &&
                    t('blocks-UsersList.inviteStatusActiveShort', {
                        expiryDate: DateOutput.formatDate(
                            userInvite.expirationDate
                        ),
                    })}

                {!inviteExpired &&
                    !inviteNotificationIsSent &&
                    t('blocks-UsersList.inviteStatusNotSent', {
                        expiryDate: DateOutput.formatDate(
                            userInvite.expirationDate
                        ),
                    })}
            </Typography>
        </Stack>
    ) : null
}

export { UserInviteStatus }
