import { useCommand } from "#app/services/beta";
import { useSelector } from "#app/state/useSelector";
import { LoadingButton } from "@mui/lab";
import { FormControlLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import FixrateManWaveImg from './fixrate-wave.svg?url';

export default function BeforeRegistration() {
    const {t} = useTranslation()

    const {
        depositorHasSingleOwner: depositorHasSingleOwnerCommand,
        setDepositorSupportCategory: setDepositorSupportCategoryCommand,
    } = useCommand()
    const sessionId = useSelector(state => state.session?.id)
    const selectedDepositor = useSelector(state => state.depositor)
    const [onlyOwner, setOnlyOwner] = useState<'yes' | 'no' | null>(null)
    const [submittingOnlyOwners, setSubmittingOnlyOwners] = useState(false)

    async function startRegistration() {
        if (onlyOwner === null) return

        setSubmittingOnlyOwners(true)
        const personId = selectedDepositor.people.find(person => person.associatedUserId === sessionId)?.personId

        const {waitForCommand} = await depositorHasSingleOwnerCommand(selectedDepositor.id, onlyOwner === 'yes', personId)
        const success = await waitForCommand()
        if (success) {
            // Continue to next step
        }
        setSubmittingOnlyOwners(false)
    }

    return (
        <Grid maxWidth={860} container sx={{flexDirection: { xs: 'column', md: null }, padding: { xs: '2rem', md: null} }}>
            <Grid order={{xs: 2, md: 1 }} item md={8}>
                <h1>{t('pages-OrganizationWizard.completeRegistration')}</h1>
                <p>{t('pages-OrganizationWizard.registrationInfo')}</p>
                <h3>{t('pages-OrganizationWizard.onlyOwnerQuestion')}</h3>
                <RadioGroup
                    aria-labelledby="onlyOwner"
                    defaultValue=""
                    name="onlyOwnerGroup"
                    value={onlyOwner}
                    onChange={(e) => setOnlyOwner(e.target.value as 'yes' | 'no')}
                >
                    <FormControlLabel data-cy="yesRadio" value="yes" control={<Radio/>} label={t('pages-OrganizationWizard.onlyOwnerYes')}/>
                    <FormControlLabel data-cy="noRadio" value="no" control={<Radio/>} label={t('pages-OrganizationWizard.onlyOwnerNo')}/>
                </RadioGroup>
                <LoadingButton data-cy="startRegistrationButton" loading={submittingOnlyOwners} sx={{marginTop: '2rem'}} variant={'contained'} onClick={startRegistration}>{t('pages-OrganizationWizard.startRegistration')}</LoadingButton>
            </Grid>
            <Grid order={{xs: 1, md: 2}} item md={3}>
                <img src={FixrateManWaveImg} alt={''}/>
            </Grid>
        </Grid>
    )
}