import ProposalCards from '#app/pages/PartnerProposals/components/ProposalCards'
import { switchOrganisation } from '#app/services/thunks/session'
import { PageHeader, PageLayout } from '#components'
import CustomerDetailAdvisors from '#pages/Customers/CustomerDetail/CustomerDetailAdvisors'
import CustomerDetailEndPartnership from '#pages/Customers/CustomerDetail/CustomerDetailEndPartnership'
import CustomerDetailInfo from '#pages/Customers/CustomerDetail/CustomerDetailInfo'
import CustomerDetailInvite from '#pages/Customers/CustomerDetail/CustomerDetailInvite'
import CustomerDetailInviteResend from '#pages/Customers/CustomerDetail/CustomerDetailInviteResend'
import CustomerDetailPartnership from '#pages/Customers/CustomerDetail/CustomerDetailPartnership'
import CustomerDetailRemoveAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailRemoveAdvisor'
import CustomerDetailSelectAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailSelectAdvisor'
import CustomerDetailSetPrimaryAdvisor from '#pages/Customers/CustomerDetail/CustomerDetailSetPrimaryAdvisor'
import useOrganizationWarnings from "#services/useOrganizationWarnings"
import { useSelector } from '#state/useSelector'
import { DepositorWarning, DepositorWarningType } from '@fixrate/fixrate-query'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { isFinishedWithOnboarding } from '../CustomerOverview/CustomerOverview'
import { CustomerDetailRegistrationStatus } from './CustomerDetailRegistrationStatus/CustomerDetailRegistrationStatus'
import StatusCard from './StatusCard'

type Params = {
    depositorId?: string
    modal: 'add' | 'set-primary' | 'remove' | 'resend-invite' | 'end-partnership'
}

type WarningMapType = {
    [key in DepositorWarningType]: string | null;
}

export default function CustomerDetail() {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams<Params>()
    const associations = useSelector(state => state.session.associations)

    const DepositorWarningMessage = (warning: DepositorWarningType, count: number) : string | null => {
        const warningMap: WarningMapType = {
            'MISSING_SECURITY_ROLES': t('pages-customers.warningMissingSecurityRoles'),
            'MISSING_ORGANISATIONAL_ROLES': t('pages-customers.warningMissingOrganisationalRoles'),
            'MISSING_OWNERS_DECLARATION': t('pages-customers.warningMissingOwnersDeclaration'),
            'CUSTOMER_DECLARATION_PERSON_GALLERY_CHANGED': t('pages-customers.warningCustomerDeclarationPersonGalleryChanged'),
            'MISSING_CUSTOMER_DECLARATION': t('pages-customers.warningMissingCustomerDeclaration'),
            'MISSING_SETTLEMENT_ACCOUNT': t('pages-customers.warningMissingSettlementAccount'),
            'TERMS_NOT_ACCEPTED': t('pages-customers.warningTermsNotAccepted'),
            'NEED_INFO_FOR_CUSTOMER_DECLARATION': t('pages-customers.warningNeedInfoForCustomerDeclaration'),
            'AUTHORIZATION_DOCUMENT_NOT_SIGNED': t('pages-customers.warningAuthorizationDocumentNotSigned'),
            'AUTHORIZATION_DOCUMENT_MISSING': t('pages-customers.warningAuthorizationDocumentMissing'),
            'PERSON_NEEDS_INVITE': t('pages-customers.warningPersonNeedsInvite'),
            'PERSON_NEEDS_INFO_FOR_INVITE': t('pages-customers.warningPersonNeedsInfoForInvite'),
            'AUTHORIZATION_SIGNER_NEEDS_INFO_FOR_INVITE': t('pages-customers.warningAuthorizationSignerNeedsInfoForInvite'),
            'INVITE_HAS_EXPIRED': t('pages-customers.warningInviteHasExpired'),
            'INVITE_IS_NOT_SENT': t('pages-customers.warningInviteIsNotSent'),
            'MISSING_AUTHORIZATION_DOCUMENT': t('pages-customers.warningMissingAuthorizationDocument'),
            'MISSING_IDENTIFICATION_DOCUMENT': t('pages-customers.warningMissingIdentificationDocument'),
            'AUTHORIZATION_SIGNER_NEEDS_INVITE': t('pages-customers.warningAuthorizationSignerNeedsInvite'),
            'IMPORTED_PEOPLE_CHANGES_NOT_ACKNOWLEDGED': null,
            'MISSING_AUTHORIZATION_SIGNERS': null,
            'MISSING_BENEFICIAL_OWNERS': null,
        }
        if (!warningMap[warning]) {
            return null
        }

        return count > 1 ? warningMap[warning] + " (" + count + ")" : warningMap[warning]
    }


    const partner = useSelector(state => state.partner)
    const customer = partner?.customers.find(c => c.depositorId === params.depositorId)
    const warnings = useOrganizationWarnings(customer)

    if (!customer) {
        return null
    }

    const invites = customer.userInvites.map(userInvite => <CustomerDetailInvite key={userInvite.id} userInvite={userInvite} depositorId={customer.depositorId}/>)
    const finishedWithOnboarding = isFinishedWithOnboarding(customer)
    const isAccountant = partner.partnerCategory === "ACCOUNTANT"
    const canAccessCustomerAccount = associations?.find(a => a.organisation?.id === customer.depositorId) ? true : false

    function onSelectRole(navigateAfterSwitch: () => void) {
        dispatch(switchOrganisation('DEPOSITOR', customer.depositorId, navigateAfterSwitch))
    }

    const getWarningMessages = (warnings: DepositorWarning[]) => {
        return [...new Set(warnings.map(warning => DepositorWarningMessage(warning.type, warnings.filter(w => w.type === warning.type).length))
            .filter(message => message !== null))]
    }

    const termsWarnings = getWarningMessages(warnings.terms)
    const settlementAccountWarnings = getWarningMessages(warnings.accounts)
    const customerDeclarationWarnings = getWarningMessages(warnings.customerDeclaration)
    const organisationalRolesWarnings = getWarningMessages(warnings.organisationalRoles)
    const usersWarnings = getWarningMessages(warnings.users)
    const hasPendingPartnerProposals = customer?.partnerRelations?.find(pr => pr.partnerId === partner.id)?.partnerProposals?.some(pp => pp.status === 'PENDING') || false

    return (
        <>
            <PageHeader icon="ri-building-line" title={customer.name} backToLink={'/customer'}/>
            <PageLayout>
                <Stack spacing={3} maxWidth={'140rem'} px={{xs: 1, md: 0}}>
                    <CustomerDetailInfo/>
                    { (canAccessCustomerAccount || partner.partnerCategory === "SUPPORT") && (
                        <>
                            { ((finishedWithOnboarding && !hasPendingPartnerProposals) || !isAccountant) && (
                                <Stack spacing={2} justifyContent={'space-between'} direction={'row'} maxWidth={'80rem'} flexWrap={'wrap'}>
                                    <StatusCard
                                        title={t('pages-customers.contractWithFixrate')}
                                        description={t('pages-customers.agreementDescription')}
                                        icon={'handshakeFill'}
                                        buttonText={t('pages-customers.goToAgreements')}
                                        onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/terms`))}
                                        warnings={termsWarnings}
                                        disabled={!canAccessCustomerAccount}
                                    />
                                    <StatusCard
                                        title={t('pages-customers.settlementAccount')}
                                        description={t('pages-customers.settlementAccountDescription')}
                                        icon={'bankFill'}
                                        buttonText={t('pages-customers.goToSettlementAccount')}
                                        onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/accounts`))}
                                        warnings={settlementAccountWarnings}
                                        disabled={!canAccessCustomerAccount}
                                    />
                                    <StatusCard
                                        title={t('pages-customers.usersAndRoles')}
                                        description={t('pages-customers.usersAndRolesDescription')}
                                        icon={'roleGroupFill'}
                                        buttonText={t('pages-customers.goToUsersAndRoles')}
                                        onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/users/all`))}
                                        warnings={[...usersWarnings, ...organisationalRolesWarnings]}
                                        disabled={!canAccessCustomerAccount}
                                    />
                                    <StatusCard
                                        title={t('pages-customers.customerDeclaration')}
                                        description={t('pages-customers.customerDeclarationDescription')}
                                        icon={'secureDocumentFill'}
                                        buttonText={t('pages-customers.goToCustomerDeclaration')}
                                        onClick={() => onSelectRole(() => navigate(`/organizations/${customer.depositorId}/customerdecl`))}
                                        warnings={customerDeclarationWarnings.filter((item,
                                            index) => customerDeclarationWarnings.indexOf(item) === index)}
                                        disabled={!canAccessCustomerAccount}
                                    />
                                </Stack>
                            )}
                            { (!finishedWithOnboarding && isAccountant) && (
                                <CustomerDetailRegistrationStatus customer={customer}/>
                            )}
                        </>
                    )}
                    { (hasPendingPartnerProposals) && (
                        <Stack my={1} spacing={2}>
                            <Typography variant="h3">
                                <i className="ri-mail-send-line"/>
                                <span>{t('pages-customers.sentProposals')}</span>
                            </Typography>    
                            <ProposalCards proposals={customer.partnerRelations.find(pr => pr.partnerId === partner.id)?.partnerProposals || []}/>
                        </Stack>
                    )}

                    <CustomerDetailAdvisors/>
                    {(partner.partnerCategory === 'ACCOUNTANT' || partner.partnerCategory === 'ASSET_MANAGER') &&  <CustomerDetailPartnership/>}
                    {invites}
                </Stack>
            </PageLayout>
            {{
                'add': <CustomerDetailSelectAdvisor/>,
                'set-primary': <CustomerDetailSetPrimaryAdvisor/>,
                'remove': <CustomerDetailRemoveAdvisor/>,
                'resend-invite': <CustomerDetailInviteResend/>,
                'end-partnership': <CustomerDetailEndPartnership/>,
            }[params.modal]}
        </>
    )
}
