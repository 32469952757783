import ChooseDepositor from '#app/layers/ChooseDepositor/ChooseDepositor'
import Login from '#app/layers/Login/Login'
import Pin from '#app/layers/Pin/Pin'
import InviteLandingPage from '#app/layers/Signup/InviteLandingPage'
import RegisterCompanyNO from '#app/layers/Signup/RegisterCompanyNO'
import RegisterCompanySE from '#app/layers/Signup/RegisterCompanySE'
import Signup from '#app/layers/Signup/Signup'
import Welcome from '#app/layers/Signup/Welcome'
import Theme from '#pages/Theme'
import { updateAdStatus } from '#services/adactive'
import { useSessionBroadcast } from '#services/sessionBroadcast'
import { registerLanding, syncSessionState } from '#services/thunks/session'
import { setCurrentUrl } from '#state/reducers/supportPane'
import { useSelector } from '#state/useSelector'
import { AssociationDto, AuthenticationLevel } from '@fixrate/fixrate-security'
import { parse } from 'query-string'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Location, Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom'
import AppLoadingPage from './AppLoadingPage/AppLoadingPage'
import Layout from './Layout'
import DepositorPartnerProposalSignup from './PartnerDepositorProposalWizard/DepositorPartnerProposalSignup'
import * as analytics from '#app/services/analytics'
import {useShoppingCartServerSync} from '#services/useShoppingCart'

const OPEN_PATHS = [
    /^\/$/,
    /^\/marketplace$/,
    /^\/marketplace\/$/,
    /^\/marketplace\/\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b$/i,
    /^\/marketplace\/onboarding$/,
    /^\/marketplace\/onboarding\/$/,
    /^\/marketplace\/onboarding\/bank$/,
    /^\/marketplace\/onboarding\/reports$/,
    /^\/marketplace\/onboarding\/potential$/,
]

const hasQueryParam = (location, paramName) => {
    const query = parse(location.search)
    return query[paramName] !== undefined
}

const getQueryParam = (location, paramName) : string => {
    const query = parse(location.search)
    return query[paramName]
}

const showAppLoading = ({authenticationLevel, messagesIsLoaded, unknownState} : { authenticationLevel: AuthenticationLevel, messagesIsLoaded: boolean, unknownState: boolean }) => unknownState || (!messagesIsLoaded && authenticationLevel === 'AUTHENTICATED')

const showPartnerProposal = (authenticationLevel: AuthenticationLevel, location: Location) => {
    const pathNameIsProposal = location.pathname.startsWith('/partner/proposals/')
    return authenticationLevel !== 'AUTHENTICATED' && pathNameIsProposal
}

const showPartnerAuthorization = (authenticationLevel: AuthenticationLevel, location: Location) => {
    const pathNameIsPartnerAuthorization = location.pathname.startsWith('/partner/signature/')
    return authenticationLevel !== 'AUTHENTICATED' && pathNameIsPartnerAuthorization
}

function showInviteLanding(authenticationLevel: AuthenticationLevel, location: Location) {
    return (authenticationLevel === 'UNKNOWN' || authenticationLevel === 'KNOWN') && location.pathname.startsWith('/invite/') && !hasQueryParam(location, 'continue')
}

function showWelcome(authenticationLevel: AuthenticationLevel, location: Location) {
    return (
        authenticationLevel === 'UNKNOWN' &&
        location.pathname === '/marketplace' &&
        !hasQueryParam(location, 'login') &&
        !hasQueryParam(location, 'register') &&
        !hasQueryParam(location, 'sso')
    )
}

function showLoginInRegistrationMode(authenticationLevel: AuthenticationLevel, location: Location) {
    return authenticationLevel === 'UNKNOWN' && location.pathname === '/marketplace' && hasQueryParam(location, 'register')
}

function showSignup(authenticationLevel: AuthenticationLevel) {
    return authenticationLevel === 'IDENTIFIED'
}

function showRegisterCompany(authenticated: boolean, location: Location) {
    return authenticated && location.pathname === '/marketplace' && hasQueryParam(location, 'registerCompany')
}

function showPin(authenticationLevel: AuthenticationLevel) {
    return authenticationLevel === 'LOCKED'
}

function showLogin({ authenticated, authenticationLevel, location } : { authenticated: boolean, authenticationLevel: AuthenticationLevel, location: Location }) {
    return !authenticated && !(authenticationLevel === 'IDENTIFIED') && (!OPEN_PATHS.find(pathRegex => location.pathname.match(pathRegex)) || hasQueryParam(location, 'login'))
}

function pageView({ authenticated, authenticationLevel, location, association, associations } : { authenticated: boolean, authenticationLevel: AuthenticationLevel, location: Location, association: AssociationDto, associations: AssociationDto[]}) {
    let url = location.pathname
    if (showLogin({ authenticated, authenticationLevel, location})) {
        url = '/login'
    }
    if (showSignup(authenticationLevel)) {
        url = '/signup'
    }
    analytics.pageView({path: url + location.search, association, associations})
}

type FoceLoginReturn = {
    forceLogin: boolean
    idpId?: string[]
}

function forceLogin({ requestedOrganisationId, association, associations, authenticated } :  { requestedOrganisationId: string, association: AssociationDto, associations: AssociationDto[], authenticated: boolean }  ): FoceLoginReturn {
    const hasUnresolvedRequest = requestedOrganisationId != null && association?.organisation.id !== requestedOrganisationId
    const hasOnlySingleUnaccessibleAssociation = associations?.length === 1
    if (hasOnlySingleUnaccessibleAssociation) {
        if (authenticated && associations[0].missingIdpIds.length > 0) {
            return { forceLogin: true, idpId: associations[0].missingIdpIds }
        }
    }
    if (hasUnresolvedRequest) {
        const requestedAssociation = associations.find(a => a.organisation.id === requestedOrganisationId)
        if (requestedAssociation) {
            return {
                forceLogin: true,
                idpId: requestedAssociation.missingIdpIds,
            }
        }
    }
    return { forceLogin: false } // No need to force login
}

function depositorNotChosen({authenticated, association} : {authenticated: boolean, association: AssociationDto}) {
    if (authenticated && !association) {
        return true
    }

    return false
}

const Root = () => {
    const location = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const sessionBroadcast = useSessionBroadcast()

    // Loads the shopping cart from the server and keeps it in sync
    useShoppingCartServerSync()

    // NB!! Be very careful and precise with selectors here. If the result of these selectors changes, then the whole page will re-render
    // Only select exactly the data you actually need

    //@ts-ignore because unknownState is not added to the session DTO at the moment
    const unknownState = useSelector(state => state.session?.unknownState)

    const authenticated = useSelector(state => state.session?.authenticated)
    const authenticationLevel = useSelector(state => state.session?.authenticationLevel)
    const association = useSelector(state => state.session?.association)
    const associations = useSelector(state => state.session?.associations)
    const primaryDomain = useSelector(state => state.session.primaryDomain)
    const messagesIsLoaded = useSelector(state => state.loaded.messages)
    const requestedOrganisationId = useSelector(state => state.session?.requestedOrganisationId)

    // Starts a job that runs every second and updates the status flags for all ads
    useEffect(() => {
        const job = setInterval(() => {
            dispatch(updateAdStatus)
        }, 1000) // runs every second
        return () => clearInterval(job)
    }, [dispatch])

    // Updates the current url in redux when the url changes
    useEffect(() => {
        dispatch(setCurrentUrl(location.pathname))
    }, [dispatch, location])

    // Register the landing page if the url contains utm_ parameters
    useEffect(() => {
        if (location.search.includes('utm_')) {
            dispatch(registerLanding(window.location.href, document.referrer))
        }
    }, [dispatch, location])

    useEffect(() => {
        dispatch(syncSessionState())
        sessionBroadcast.init()
    }, [dispatch, sessionBroadcast])

    useEffect(() => {
        pageView({authenticated, authenticationLevel, location, association, associations})
    }, [location, association, authenticated, authenticationLevel, associations])

    if (location.pathname.includes("/partner/logged-out/")) {
        return (
            <Theme>
                <Layout />
            </Theme>
        )
    }

    const afterLoginSuccessful = () => {
        if (location.pathname === '/marketplace' || location.pathname === '/marketplace?login') {
            navigate("/")
        }
    }

    const getOverlay = () => {
        if (showPartnerAuthorization(authenticationLevel, location)) {
            return (
                <Routes>
                    <Route path='/partner/signature/:partnerId/:inviteId' element={<DepositorPartnerProposalSignup signingRedirect />} />
                    <Route path='/partner/signature/:partnerId' element={<DepositorPartnerProposalSignup signingRedirect />} />
                </Routes>
            )
        }

        if (showPartnerProposal(authenticationLevel, location)) {
            return (
                <Routes>
                    <Route path='/partner/proposals/:partnerId/:proposalId/:inviteId' element={<DepositorPartnerProposalSignup />} />
                    <Route path='/partner/proposals/:partnerId/:proposalId' element={<DepositorPartnerProposalSignup />} />
                </Routes>
            )
        }

        if (showInviteLanding(authenticationLevel, location)) {
            const inviteId = location.pathname.substring('/invite/'.length)
            return <InviteLandingPage inviteId={inviteId} />
        }

        if (showRegisterCompany(authenticated, location)) {
            switch (primaryDomain) {
                case 'fixrate.no':
                    return <RegisterCompanyNO />
                case 'fixrate.se':
                    return <RegisterCompanySE />
            }
            return <RegisterCompanyNO />
        }

        if (showWelcome(authenticationLevel, location)) {
            return <Welcome />
        }

        // Show login overlay in registration mode
        if (showLoginInRegistrationMode(authenticationLevel, location)) {
            return <Login registration={true} />
        }

        // We force the Signup component if not authenticated, but identified (that is, user logged in but not a user yet)
        if (showSignup(authenticationLevel)) {
            return <Signup />
        }

        // Show login overlay if user is not authenticated and path is not an open path
        if (showLogin({ authenticated, authenticationLevel, location })) {
            const method = getQueryParam(location, 'method')
            return <Login method={[method]} onLoginSuccess={() => afterLoginSuccessful()} />
        }

        // We force to unlock with pin if session has timed out
        if (showPin(authenticationLevel)) {
            return <Pin />
        }

        // Show login overlay if the user has requested an organisation that requires a different Idp than the current
        const forceLoginResult = forceLogin({ requestedOrganisationId, association, associations, authenticated })
        if (forceLoginResult.forceLogin) {
            return <Login forceIdp={true} method={forceLoginResult.idpId} logoutOnCancel={true} />
        }

        if (depositorNotChosen({authenticated, association})) {
            return <ChooseDepositor />
        }

        return null
    }

    // If unknownState then session is not loaded from server yet
    if (showAppLoading({authenticationLevel, messagesIsLoaded, unknownState})) {
        return <AppLoadingPage />
    }

    return (
        <Theme>
            <Layout />
            {getOverlay()}
        </Theme>
    )
}

export default Root
