import { BURNT_ORANGE, SUCCESS_GREEN } from "#app/colors/colors"
import { useBottomNavigation } from "#app/services/useBottomNavigation"
import { Alert, Avatar, Box, Chip, Divider, Paper, Slide, Snackbar, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { WizardStep } from "./OrganizationWizard"

const WizardContent = ({ step, visibleError, hideVisibleError } : { step: WizardStep, visibleError: boolean, hideVisibleError: () => void }) => {
    const {t} = useTranslation()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('lg'))
    const iconSize = isMobile ? "3rem" : "5rem"
    useBottomNavigation()
    return (
        <Paper 
            sx={{overflow: {xs: "auto", lg: "hidden"}, width: "100%", position: "relative", height: {xs: "100%", md: "auto"}}}>
            <Stack justifyContent={"space-between"} minHeight={{lg: "50rem"}} height={{xs: "100%", lg: "auto"}}>
                <Box>
                    <Stack 
                        direction={{xs: "row"}}
                        justifyContent="space-between"
                        alignItems={{xs: "center"}}
                        px={{xs: 2, lg: 4}}
                        py={{xs: 1.6, lg: 2.2}}>
                        <Stack direction="row" spacing={{xs: 1, lg: 2}} alignItems="center">
                            <Box width={iconSize} height={iconSize} sx={{
                                backgroundSize: `${iconSize} ${iconSize}`,
                                backgroundRepeat: "no-repeat",
                                backgroundPosition: "center center",
                                backgroundImage: `url(${step.icon})`}}></Box>
                            <Typography m={0} fontSize={{xs: "1.6rem", sm: "1.8rem", lg: null}} variant="h2">{t('pages-OrganizationWizard.' + step.name)}</Typography>
                        </Stack>
                        { isMobile ? (
                            <Avatar sx={{backgroundColor: step.done ? SUCCESS_GREEN[50] : BURNT_ORANGE[50], color: step.done ? SUCCESS_GREEN[900] : BURNT_ORANGE[900] }}>
                                <i className={`ri-${step.done ? "checkbox-circle" : "error-warning"}-fill`} />
                            </Avatar>
                        ) : (
                            <Chip
                                label={step.done ? t('pages-OrganizationWizard.allDone') : t('pages-OrganizationWizard.notDone')}
                                color={step.done ? "success" : "error"}
                                icon={<i className={`ri-${step.done ? "checkbox-circle" : "error-warning"}-fill`} />}
                            />
                        )}
                    </Stack>
                    <Divider />
                    <Box px={{xs: 2, lg: 4}} py={3}>
                        { step.content }
                    </Box>
                </Box>
                { isMobile ? (
                    <Snackbar
                        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                        open={visibleError}
                        onClose={hideVisibleError}
                        autoHideDuration={5000}
                        sx={{bottom: "8rem"}}
                        color="error"
                        message={t('pages-OrganizationWizard.stepNotFinishedMessage')}
                    />
                ) : (
                    <Slide direction="up" in={visibleError}>
                        <Alert severity={'error'} sx={{boxShadow: "none"}}>
                            {t('pages-OrganizationWizard.stepNotFinishedMessage')}
                        </Alert>
                    </Slide>
                )}
            </Stack>
        </Paper>
    )
}

export default WizardContent