import { Animated, PageHeader, PageLayout } from "#app/components";
import { useBottomNavigation } from "#app/services/useBottomNavigation";
import useShoppingCart from "#app/services/useShoppingCart";
import { useSelector } from "#app/state/useSelector";
import { InvestmentCategory } from "@fixrate/fixrate-query";
import { BottomNavigation, BottomNavigationAction, Tab, Tabs, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Route, Routes, useLocation, useNavigate, useParams } from "react-router-dom";
import DashboardHeader from "../DashboardHeader/DashboardHeader";
import FundDetails from "../FundDetails/FundDetails";
import { FundCartButton } from "../FundMarketplace/FundCartButton/FundCartButton";
import FundMarketplace from "../FundMarketplace/FundMarketplace";
import fundStyles from "../FundMarketplace/FundMarketplace.module.scss";
import DepositMarketplace from "./DepositMarketplace";
import DepositMarketplaceOverview from "./DepositMarketplaceOverview";
import {useFeatures} from '#services/useFeatures'
import SendPartnerProposal from "./SendPartnerProposal/SendPartnerProposal";
type FundCategoryRouteType = "money-market-fund" | "bond-fund"
type MenuItem = {label: string, value: string, icon: string, investmentCategory: InvestmentCategory, dataCy: string}

const MarketplaceTopMenu = () => {
    const navigate = useNavigate()
    const {t} = useTranslation()
    const {fundCategory} = useParams() as {depositCategory: string, fundCategory: FundCategoryRouteType | undefined}
    const theme = useTheme()
    const showBottomNav = useMediaQuery(theme.breakpoints.down('sm'))
    useBottomNavigation()
    const depositor = useSelector(state => state.depositor)
    const mandatePolicies = depositor?.mandate?.policies
    const features = useFeatures()

    const selectMarketplace = (value: "deposits" | FundCategoryRouteType) => {
        if (value === "deposits") {
            navigate("/marketplace/category/deposits/over-20")
        } else {
            navigate("/marketplace/category/funds/" + value)
        }
    }

    const currentCategory = fundCategory ? fundCategory : "deposits"
    const menuItems: MenuItem[] = [
        {
            label: t("enum-InvestmentCategory.BANK_DEPOSITS"),
            value: "deposits",
            icon: "ri-bank",
            investmentCategory: "BANK_DEPOSITS",
            dataCy: "depositsMenuItem",
        }
    ]
    if (features.fund) {
        menuItems.push(
            {
                label: t("enum-InvestmentCategory.MONEY_MARKET_FUNDS"),
                value: "money-market-fund",
                icon: "ri-line-chart",
                investmentCategory: "MONEY_MARKET_FUNDS",
                dataCy: "moneyMarketFundsMenuItem",
            })
        menuItems.push({
            label: t("enum-InvestmentCategory.BOND_FUNDS"),
            value: "bond-fund",
            icon: "ri-line-chart",
            investmentCategory: "BOND_FUNDS",
            dataCy: "bondFundsMenuItem",
        })
    }

    if (!currentCategory || menuItems.length === 1) {
        return null
    }

    return showBottomNav ? (
        <BottomNavigation
            showLabels
            value={currentCategory}
            onChange={(e, val) => selectMarketplace(val)}
            >
            { menuItems.length > 1 && menuItems.map(item => (
                <BottomNavigationAction data-cy={item.dataCy} label={item.label} value={item.value} key={item.value} icon={
                    <i className={item.icon + (item.value === currentCategory ? '-fill' : '-line')} />
                } />
            ))}
        </BottomNavigation>
    ) : (
        <Tabs sx={{"& .MuiTab-root": { fontSize: { xs: "1.4rem", md: "1.6rem" }}, mx: { xs: 2, md: 0 }, mb: 1}} value={currentCategory} onChange={(e, val) => selectMarketplace(val) } >
            { menuItems.length > 1 && menuItems.map(item => (
                <Tab data-cy={item.dataCy} label={item.label} value={item.value} key={item.value} icon={mandatePolicies?.find(policy => policy.investmentCategory === item.investmentCategory && policy.enabled) || item.investmentCategory === "BANK_DEPOSITS" ? null : <i className="ri-lock-line" /> } />
            ))}
        </Tabs>
    )
}

const MarketplaceLayout = ({ cartOpen, setCartOpen, isMarketplaceOverview } : { cartOpen: boolean, setCartOpen: (open: boolean) => void, isMarketplaceOverview: boolean }) => {
    const { t } = useTranslation()
    const shoppingCart = useShoppingCart('FUNDS')
    const [shoppingCartNotification, setShoppingCartNotification] = useState(true)
    const { fundCategory } = useParams()
    const features = useFeatures()
    const organisationType = useSelector(state => state.session.association?.organisationType)
    return (
        <>
            <PageHeader shadow={false} icon="ri-store-2-line" title={t('pagetitles.marketplace')}>
                { features.fund && fundCategory && (
                    <div className={fundStyles.cartButtonWrapper}>
                        <FundCartButton setCartOpen={setCartOpen} cartOpen={cartOpen}/>
                        {shoppingCart.getItemCount() > 0 && shoppingCartNotification && (
                            <Animated>
                                <div className={fundStyles.notification}>
                                    <div className={fundStyles.infoPopOver}></div>
                                    <div className={fundStyles.inner}>
                                        <span>{t('pages.fundMarketplace.activeItemsInCart', {count: shoppingCart.getItemCount()})}</span>
                                        <i className="ri-close-line" onClick={() => setShoppingCartNotification(false)}/>
                                    </div>
                                </div>
                            </Animated>
                        )}
                    </div>
                )}
            </PageHeader>
            <DashboardHeader />
            <PageLayout sx={{ pt: 3, pr: isMarketplaceOverview ? 0 : null}}>
                { organisationType !== "BANK" && <MarketplaceTopMenu /> }
                <Outlet />
            </PageLayout>
        </>
    )
}

export default function MarketplaceCategoryRoute() {
    const [cartOpen, setCartOpen] = useState(false)
    const location = useLocation()
    const isMarketplaceOverview = location.pathname === "/marketplace" || location.pathname === "/marketplace/category/deposits/overview"
    const features = useFeatures()

    return (
        <Routes>
            {features.fund && <Route path="funds/:fundCategory/:fundId" element={<FundDetails/>} />}
            <Route element={<MarketplaceLayout isMarketplaceOverview={isMarketplaceOverview} cartOpen={cartOpen} setCartOpen={setCartOpen} />}>
                <Route path="" element={<DepositMarketplaceOverview />} />
                {features.fund && <Route path="funds/:fundCategory" element={<FundMarketplace cartOpen={cartOpen} setCartOpen={setCartOpen} />} />}
                <Route path="deposits">
                    <Route path="overview/:adId" element={<DepositMarketplaceOverview />} />
                    <Route path="overview" element={<DepositMarketplaceOverview />} />
                    <Route path="under-20/:adId" element={<DepositMarketplace depositMarketPlaceType={'LOW'} url="/marketplace/category/deposits/under-20"/>} />
                    <Route path="under-20" element={<DepositMarketplace depositMarketPlaceType={'LOW'} url="/marketplace/category/deposits/under-20"/>} />
                    <Route path="over-20/:adId" element={<DepositMarketplace depositMarketPlaceType={'HIGH'} url="/marketplace/category/deposits/over-20"/>} />
                    <Route path="over-20" element={<DepositMarketplace depositMarketPlaceType={'HIGH'} url="/marketplace/category/deposits/over-20"/>} />
                </Route>
                <Route path="deposits">
                    <Route path="overview/:proposalAdId/send-proposal" element={
                        <>
                            <DepositMarketplaceOverview />
                            <SendPartnerProposal />
                        </>
                    } />
                    <Route path="under-20/:proposalAdId/send-proposal" element={
                        <>
                            <DepositMarketplace depositMarketPlaceType={'LOW'} url="/marketplace/category/deposits/under-20"/>
                            <SendPartnerProposal />
                        </>
                    } />
                    <Route path="over-20/:proposalAdId/send-proposal" element={
                        <>
                            <DepositMarketplace depositMarketPlaceType={'HIGH'} url="/marketplace/category/deposits/over-20"/>
                            <SendPartnerProposal />
                        </>
                    } />
                </Route>
            </Route>
        </Routes>
    )
}
