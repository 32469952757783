import React, {useState} from "react";
import {useCommand} from "#command";
import {useDispatch} from "react-redux";
import {showConfirmationModal} from "#state/reducers/confirmationModal";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography
} from "@mui/material";
import {DatePicker} from "#components";
import {LoadingButton} from "@mui/lab";
import {PlacementRow} from "#pages/FundAdminOverview/FundPlacements/AdminFundPlacementTable";
import {useTranslation} from "react-i18next";
import {FundDto, FundShareClassDto} from "@fixrate/fixrate-query";
import {useSelector} from "#state/useSelector";

const translationPath = 'pages.adminFundPlacements.changeShareClassDialog'

const DetailRow = ({children, title} : {children: React.ReactNode, title: string}) => {
    return (
        <Box sx={{display: 'flex', justifyContent: 'space-between', borderBottom: 'lightgray 1px solid', marginBottom: 4, paddingBottom: 4}}>
            <Typography variant={'body1'}>{title}:</Typography>
            {children}
        </Box>
    )
}

export default function ChangeFundShareClassDialog({pr, onClose}: {pr: PlacementRow, onClose: () => void}) {
    const {t} = useTranslation()
    const [fromShareClassId, setFromShareClassId] = useState<string | null>(pr.fundShareClassId)
    const [toShareClassId, setToShareClassId] = useState<string | null>(null)
    const [toFundId, setToFundId] = useState<string | null>(pr.fundId)
    const [allowFundChange, setAllowFundChange] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [transactionDate, setTransactionDate] = useState<Date | null>(null)
    const [type, setType] = useState('CHANGE')
    const {changeFundPlacementShareClass} = useCommand()
    const {moveFundPlacementShareClass} = useCommand()
    const dispatch = useDispatch()
    const funds = useSelector(state => state.funds)
    if (!pr || !funds) {
        return null
    }
    const availableToShareClasses = funds.find(f => f.id === toFundId).fundShareClasses

    function closeDialog() {
        setFromShareClassId(pr.fundShareClassId)
        setToFundId(pr.fundId)
        setToShareClassId(null)
        setTransactionDate(null)
        setAllowFundChange(false)
        onClose()
    }

    function onFromShareClassIdChange(id: string) {
        if (pr.fundShareClasses.some(sc => sc.id === id)) {
            setFromShareClassId(id)
        }
    }

    function onToFundIdChange(id: string) {
        if (funds.some(f => f.id === id)) {
            setToFundId(id)
        }
    }

    function onToShareClassIdChange(id: string) {
        if (availableToShareClasses.some(sc => sc.id === id && sc.fundId === toFundId)) {
            setToShareClassId(id)
        }
    }

    function onAllowFundChangeChange(allow: boolean) {
        if (!allow) {
            setToFundId(pr.fundId)
        }
        setAllowFundChange(allow)
    }

    async function handleSubmit() {
        if (!pr) return
        const fromShareClass = pr.fundShareClasses.find(sc => sc.id === fromShareClassId)
        const toShareClass = availableToShareClasses.find(sc => sc.id === toShareClassId)
        const toFund = funds.find(f => f.id === toFundId)
        if (!fromShareClass || !toShareClass || !toFund) {
            return
        }
        setSubmitting(true)
        let success = false
        if (type === 'CHANGE') {
            const {waitForCommand} = await changeFundPlacementShareClass(pr.id, fromShareClass.id, toShareClass.id, toFund.id, transactionDate)
            success = await waitForCommand()
        } else if (type === 'MOVE') {
            const {waitForCommand} = await moveFundPlacementShareClass(pr.id, fromShareClass.id, toShareClass.id, toFund.id, transactionDate)
            success = await waitForCommand()
        } else {
            return
        }
        if (success) {
            dispatch(showConfirmationModal({
                title: t(`${translationPath}.confirmationModal.title`),
                text: t(`${translationPath}.confirmationModal.text`, {fromShareClassName: fromShareClass.fullName, toShareClassName: toShareClass.fullName}),
                buttonText: t(`${translationPath}.confirmationModal.buttonText`)
            }))
            closeDialog()
        }
        setSubmitting(false)
    }

    function isSubmitDisabled(): boolean {
        return !toShareClassId || !toFundId || toShareClassId === fromShareClassId || !transactionDate
    }

    const shareClassSelectionIsValid = toShareClassId && fromShareClassId && toShareClassId !== fromShareClassId && availableToShareClasses.some(sc => sc.id === toShareClassId && sc.fundId === toFundId)

    return (
        <Dialog open={pr !== null} onClose={closeDialog} fullWidth maxWidth={'sm'}>
            <DialogTitle>{t(`${translationPath}.title`)}</DialogTitle>
            {pr && (
                <DialogContent>
                    <DetailRow title={t(`${translationPath}.depositor`)}>
                        {pr.depositorName}
                    </DetailRow>
                    <DetailRow title={'Andelsklassebytte eller flytting?'}>
                        <ActionTypeDropdown value={type} onChange={setType}/>
                    </DetailRow>
                    <DetailRow title={'Fra fond'}>
                        {pr.fund.name}
                    </DetailRow>
                    <DetailRow title={t(`${translationPath}.fromShareClass`)}>
                        <ShareClassDropdown
                            shareClasses={pr.fundShareClasses}
                            value={fromShareClassId}
                            disabledItemText={t(`${translationPath}.selectFromShareClass`)}
                            onShareClassSelect={onFromShareClassIdChange}
                        />
                    </DetailRow>
                    <DetailRow title={'Er den nye andelsklassen i et annet fond?'}>
                        <Select onChange={(e: SelectChangeEvent) => onAllowFundChangeChange(e.target.value === 'true')}
                                displayEmpty={true}
                                value={allowFundChange ? 'true' : 'false'}
                        >
                            <MenuItem value={'true'}>Ja</MenuItem>
                            <MenuItem value={'false'}>Nei</MenuItem>
                        </Select>
                    </DetailRow>
                    <DetailRow title={'Til fond'}>
                        <FundDropdown
                            funds={funds}
                            value={toFundId}
                            disabled={!allowFundChange}
                            disabledItemText={'Velg fond (til)'}
                            onFundSelect={onToFundIdChange}
                        />
                    </DetailRow>
                    <DetailRow title={t(`${translationPath}.toShareClass`)}>
                        <ShareClassDropdown
                            shareClasses={availableToShareClasses}
                            value={toShareClassId}
                            disabledItemText={t(`${translationPath}.selectToShareClass`)}
                            onShareClassSelect={onToShareClassIdChange}
                        />
                    </DetailRow>
                    {shareClassSelectionIsValid && (
                        <div>
                            <DetailRow title={t(`${translationPath}.transactionDate`)}>
                                <DatePicker
                                    id="transactionDateInput"
                                    selected={transactionDate}
                                    onChange={date => setTransactionDate(date)}
                                    customInput={<TextField label={t(`${translationPath}.transactionDate`)}
                                                            helperText={!transactionDate && t(`${translationPath}.transactionDateHelperText`)}
                                                            error={!transactionDate}
                                    />}
                                />
                            </DetailRow>
                            <DialogActions title={t(`${translationPath}.performChanges`)}>
                                <Button data-cy="cancelButton" onClick={closeDialog}>{t(`${translationPath}.cancel`)}</Button>
                                <LoadingButton
                                    variant={'contained'}
                                    disabled={isSubmitDisabled()}
                                    loading={submitting}
                                    onClick={handleSubmit}
                                >
                                    {t(`${translationPath}.performChanges`)}
                                </LoadingButton>
                            </DialogActions>
                        </div>
                    )}
                </DialogContent>
            )}
        </Dialog>
    )
}

type FundDropdownProps = {
    funds: FundDto[]
    value: string
    disabled: boolean
    disabledItemText: string
    onFundSelect: (value: string) => void
}

function FundDropdown({funds, value, disabled, disabledItemText, onFundSelect}: FundDropdownProps) {
    return (
        <Select onChange={(e: SelectChangeEvent) => onFundSelect(e.target.value)}
                displayEmpty={true}
                value={value ?? ''}
                disabled={disabled}
        >
            <MenuItem disabled value={''}>{disabledItemText}</MenuItem>
            {funds
                .map(f => (
                    <MenuItem key={f.id} value={f.id}>{f.name}</MenuItem>
                ))}
        </Select>
    )
}

type ShareClassDropdownProps = {
    shareClasses: FundShareClassDto[]
    value: string
    disabledItemText: string
    onShareClassSelect: (value: string) => void
}

function ShareClassDropdown({shareClasses, value, disabledItemText, onShareClassSelect}: ShareClassDropdownProps) {
    return (
        <Select autoWidth onChange={(e: SelectChangeEvent) => onShareClassSelect(e.target.value)}
                displayEmpty={true}
                value={value ?? ''}
        >
            <MenuItem disabled value={''}>{disabledItemText}</MenuItem>
            {shareClasses
                .map(sc => (
                    <MenuItem key={sc.id} value={sc.id} >{sc.fullName}</MenuItem>
                ))}
        </Select>
    )
}

function ActionTypeDropdown({value, onChange}: {value: string, onChange: (value: string) => void}) {
    return (
        <Select onChange={(e: SelectChangeEvent) => onChange(e.target.value)}
                displayEmpty={true}
                value={value ?? ''}
        >
            <MenuItem value={'CHANGE'}>Bytte</MenuItem>
            <MenuItem value={'MOVE'}>Flytting</MenuItem>
        </Select>
    )
}
