import { DepositorDto, DepositorWarningType, OrganisationalPersonDto } from "@fixrate/fixrate-query";
import { ErrorOutline } from "@mui/icons-material";
import { Button, Chip, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import EditPersonModal from "../../../OrganizationDetail/UsersAndRoles/components/EditPersonModal";

export const personHasWarning = ({ type, depositor, person } : { type: DepositorWarningType[], depositor: DepositorDto, person: OrganisationalPersonDto }): boolean =>
    depositor.warnings.some(warning => type.includes(warning.type) && (!warning.personId || warning.personId === person.personId))

export default function MissingInformationStep({
    depositor,
}: {
    depositor: DepositorDto;
}) {
    const [editPersonId, setEditPersonId] = useState<string | null>(null)
    const {t} = useTranslation()

    return (
        <>
            <Typography>
                {t('pages-OrganizationWizard.missingInformationStepIntro')}
            </Typography>
            <Table sx={{ mt: 3 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('common.name')}</TableCell>
                        <TableCell>{t('common.status')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {depositor?.people.map((person) => (
                        <TableRow key={person.personId}>
                            <TableCell>
                                {person?.firstName} {person?.lastName}
                            </TableCell>
                            <TableCell>
                                {personHasWarning({
                                    type: ["NEED_INFO_FOR_CUSTOMER_DECLARATION"],
                                    depositor: depositor,
                                    person: person,
                                }) ? (
                                    <Chip
                                        icon={<ErrorOutline />}
                                        data-cy="status-missing-information"
                                        label={t('common.missingInformation')}
                                        color="error"
                                    />
                                ) : (
                                    <Chip
                                        icon={<i className="ri-checkbox-circle-fill" />}
                                        data-cy="status-information-complete"
                                        label={t('common.finished')}
                                        color="success"
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {personHasWarning({
                                    type: ["NEED_INFO_FOR_CUSTOMER_DECLARATION"],
                                    depositor: depositor,
                                    person: person,
                                }) ? (
                                    <Button
                                        onClick={() =>
                                            setEditPersonId(person.personId)
                                        }
                                        startIcon={
                                            <i className="ri-add-circle-line" />
                                        }
                                        variant="outlined"
                                        size="small"
                                        data-cy="addInformationButton"
                                    >
                                        {t('common.addInformation')}
                                    </Button>
                                ) : (
                                    <Button
                                        startIcon={
                                            <i className="ri-pencil-line" />
                                        }
                                        onClick={() =>
                                            setEditPersonId(person.personId)
                                        }
                                        variant="text"
                                        size="small"
                                    >
                                        {t('common.editInformation')}
                                    </Button>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            {!!editPersonId && (
                <EditPersonModal
                    hideInvitationAlert={true}
                    wizardMode
                    person={depositor?.people?.find(
                        (p) => p.personId === editPersonId
                    )}
                    open={true}
                    onClose={() => setEditPersonId(null)}
                />
            )}
        </>
    );
}
