import { useEffect, useRef, useState } from 'react'
import { Chart } from 'chart.js'
import styles from './VolumePerSegment.module.scss'
import sharedStyles from '../BankDashboard.module.scss'
import classNames from 'classnames'
import { useTranslation } from 'react-i18next'
import {StaggData, StaggDataItem} from '#app/state/stagg'
import { CHART_COLORS } from '#app/colors/colors'

interface ChartCompProps {
    data: StaggData,
    error: boolean,
    title?: {
        icon?: string,
        text: string
    },
    tickValue?: string,
    type: 'bar' | 'line',
    labels?: (tags: { [key: string]: string }) => string,
    description?: string,
    spanGaps?: boolean,
    smallSize?: boolean,
    gradient?: boolean,
    width?: number,
    height?: number,
    dashedLine?: 'SSB',
    dataType?: 'time' | 'category',
    beginAtZero?: boolean,
    yAxisBuffer?: number,
    textColor?: string,
    gridColor?: string
}

export const ChartComp = ({
    data,
    error,
    title = null,
    tickValue = '',
    type,
    labels = null,
    description = '',
    spanGaps = true,
    smallSize =  true,
    gradient = false,
    width = 600,
    height = 500,
    dashedLine,
    dataType = 'category',
    beginAtZero = true,
    yAxisBuffer = 0,
    textColor = '#fff',
    gridColor = "rgba(250,250,250,0.1)"
}: ChartCompProps) => {

    const {t} = useTranslation()

    const colors = CHART_COLORS

    useEffect(() => {
        function formatLabels(tags) {
            if (labels !== null) {
                return labels(tags)
            } else {
                return tags;
            }
        }

        function formattedDatasets(data: StaggData, ctx: CanvasRenderingContext2D) {
            return data.length === 0 ? [] : data[0]?.series.map((series, index) => {
                let seriesHasDashedGaps = series.values?.filter(point => point[1] === null).length > 0;

                if (dashedLine !== undefined) {
                    seriesHasDashedGaps = series.tags.name === dashedLine;
                }

                const bgColor = colors[index]
                const bgGradient = ctx.createLinearGradient(0, 0, 0, 600)
                bgGradient.addColorStop(0, colors[index] + "70");
                bgGradient.addColorStop(0.6, colors[index] + "00");
                return {
                    label: formatLabels(series.tags),
                    data: series.values.map(value => {
                        if (value[1] === null) {
                            return null
                        } else {
                            return +(value[1].toFixed(2))
                        }
                    }),
                    backgroundColor: gradient ? bgGradient : bgColor,
                    borderColor: colors[index],
                    color: colors[index],
                    fill: gradient,
                    spanGaps: spanGaps,
                    pointRadius: seriesHasDashedGaps ? 1 : 1,
                    borderDash: seriesHasDashedGaps ? [6, 6] : [0, 0],
                    hoverRadius: 10,
                    hitRadius: 10,
                }
            })
        }

        if (chartRef.current && data.length !== 0) {
            chartRef.current.data.labels = data[0]?.series[0].values.map(value => value[0])
            chartRef.current.data.datasets = formattedDatasets(data, chartRef.current.ctx)
            chartRef.current.update()
        }
    }, [dashedLine, data, gradient, labels, spanGaps, colors])

    const chartRef = useRef<Chart | null>(null)

    const canvasCallback = (canvas: HTMLCanvasElement | null) => {
        const ctx = canvas?.getContext('2d')
        const values = data.flatMap((item: StaggDataItem) => item.series.flatMap(series => series.values.map(value => value[1])))
        const min = Math.min(...values)
        const max = Math.max(...values)
        if (ctx && !chartRef.current) {
            chartRef.current = new Chart(ctx, {
                type: type,
                data: {
                    labels: data[0]?.series[0].values.map(value => value[0]),
                    datasets: []
                },
                options: {
                    maintainAspectRatio: true,
                    color: textColor,
                    scales: {
                        x: {
                            ticks: {
                                font: {
                                    family: "'Montserrat'",
                                    weight: '600',
                                },
                                maxTicksLimit: 8,
                                autoSkipPadding: 30,
                                color: textColor
                            },
                            grid: {
                                color: gridColor
                            },
                            type: dataType,
                            time: {
                                unit: data[0]?.series[0].values.length > 60 ? 'month' : 'day',
                                displayFormats: {
                                    day: 'dd. MMM',
                                },
                                tooltipFormat: 'dd. MMMM yyyy',
                            },
                        },
                        y: {
                            beginAtZero: beginAtZero,
                            suggestedMin: yAxisBuffer ? min * (1 - yAxisBuffer) : min,
                            suggestedMax: yAxisBuffer ? max * (1 + yAxisBuffer) : max,
                            ticks: {
                                font: {
                                    family: "'Montserrat'",
                                    weight: '600'
                                },
                                color: textColor,
                                callback: (value) => `${value}` + tickValue
                            },
                            grid: {
                                color: gridColor
                            }
                        }
                    },
                    onResize: (chart, size) => {
                        chart.options.maintainAspectRatio = (size.height > 300)
                    },
                    plugins: {
                        legend: {
                            align: "end",
                            labels: {
                                color: textColor,
                                usePointStyle: true,
                                font: {
                                    size: 12,
                                    family: "'Montserrat'",
                                    weight: '600'
                                }
                            }
                        },
                    }
                },
            })
        }
    }
    return (
        <div className={smallSize ? classNames(sharedStyles.gridItem, styles.volumePerSegment, sharedStyles.chartWrapper) : sharedStyles.chartWrapper}>
            { title &&
                <h3 className={sharedStyles.whiteHeading}>
                    <i className={title.icon}/>
                    <span>{title.text}</span>
                </h3>
            }
            {!error && data &&
                <canvas style={{maxWidth: '100%'}} ref={canvasCallback} width={width} height={height}/>
            }
            {error &&
                <p style={{marginTop: 24}}>
                    <i className="ri-error-warning-line"/>
                    <span>{t('pages-BankDashboard.loadingFailed')}</span>
                </p>
            }
            {!error && description &&
                <p className={styles.explanationText}>{description}</p>
            }
        </div>
    )
}
