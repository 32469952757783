import { hasFundAccessSelector } from "#app/state/selectors"
import { useSelector } from "#app/state/useSelector"
import { isAfter } from "date-fns"
import getDepositValue from "./getDepositValue"

const usePortfolioValues = () => {
    const deposits = useSelector(state => state.deposits)
    const hasFundAccess = useSelector(hasFundAccessSelector)
    const depositsValue = deposits
        ?.filter(deposit => !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date()))
        .reduce((sum, deposit) => sum + getDepositValue(deposit), 0)

    const fundPlacements = useSelector(state => state.fundPlacements)
    const activeFundPlacements = hasFundAccess ? fundPlacements?.filter(fp => fp.unitQuantity !== 0) : []
    const fundsValue = activeFundPlacements.reduce((sum, fp) => sum + fp.currentValue, 0)

    const portfolioValue = depositsValue + fundsValue

    return {
        portfolio: portfolioValue,
        deposits: depositsValue,
        funds: fundsValue
    }
}

export {usePortfolioValues}
