import {
    DepositorDto, SecurityRole
} from '@fixrate/fixrate-query';
import { Box, Stack } from '@mui/material';
import POAList from './POAList';
import POASigners from './POASigners';

export type Proxy = {
    id: string,
    personId: string,
    firstName: string,
    lastName: string,
    roles: SecurityRole[],
    authorizationDocumentId: string,
    isUser: boolean,
    isInvitee: boolean
    isPerson: boolean
}

interface Props {
    depositor: DepositorDto,
}

export default function PowerOfAttorney({depositor}: Props) {
    return (
        <Box>
            <Stack spacing={4}>
                <POAList depositor={depositor} />
                <POASigners depositor={depositor} />
            </Stack>
        </Box>
    )
}