import { useSelector } from "#app/state/useSelector";
import { DepositorDto, OrganisationalRole, SecurityRole } from "@fixrate/fixrate-query";
import { Box, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import BadgeList from "../../components/BadgeList";
import { Signatories } from "./Signatories";
import styles from './PowerOfAttorney.module.scss';

function Procuration({depositor}: {depositor: DepositorDto}) {
    const {t} = useTranslation()
    const procuration = useSelector(state => state.companyPublicInformation.find(c => c.nationalIdentity === depositor?.nationalIdentity)?.procuration)

    return (
        <div className={styles.signingRights}>
            <p className={styles.signatureHeader}>
                <i className="ri-archive-drawer-line"/>
                <span>{t('pages-organizations.poaProcurationRuleDescription')}</span>
            </p>
            {procuration && (
                <div className={classNames(styles.data, styles.signatureConfiguration)}>
                    <pre className={styles.signatories}>{procuration}</pre>
                </div>
            )}
            {!procuration && <p className={classNames(styles.noData, styles.signatureConfiguration)}><i
                className={'ri-error-warning-line'}/><span>{t('pages-organizations.poaRuleNA')}</span></p>}
        </div>
    )
}

export default function POASigners({depositor, inline} : {depositor: DepositorDto, inline?: boolean}) {
    const {t} = useTranslation()
    const [selectedOpenRole, setSelectedOpenRole] = useState<SecurityRole | OrganisationalRole | null>(null)
    const nonSelectableRoles = ['DEPOSITOR_PARTNER_SUPPORT']
    const selectablePeople = depositor.people.filter(person => person.securityRoles.every(role => !nonSelectableRoles.includes(role)))

    return (
        <Stack spacing={2}>
            { !inline && (
                <Box>
                    <Typography variant='h2'>{t('pages-organizations.poaGrantTitle')}</Typography>
                    <Typography>{t('pages-organizations.poaGrantDescription')}</Typography>
                </Box>
            )}
            {inline && (
                <Typography>{t('pages-organizations.poaGrantDescription')}</Typography>
            )}
            <Stack spacing={2}>
                <BadgeList
                    selectedOpenRole={selectedOpenRole}
                    setSelectedOpenRole={setSelectedOpenRole}
                    depositor={depositor}
                    people={selectablePeople}
                    securityRole={'DEPOSITOR_SIGN_AUTHORIZATION'}
                    organisationalRole={null}
                    showPersonalWarnings={inline !== true}
                    namesWithRoles={true}
                    row
                />
                <Stack alignItems={"flex-start"} spacing={2}>
                    <Signatories nationalIdentity={depositor.nationalIdentity} />
                    <Procuration depositor={depositor} />
                </Stack>
            </Stack>
        </Stack>
    )
}