import PageHeader from '#app/components/PageHeader/PageHeader'
import config from '#app/config'
import {useConfirmationModal} from '#app/layers/ConfirmationModal/ConfirmationModal'
import {useInfoMessages} from '#app/layers/InfoMessages/InfoMessages'
import useCurrentDepositor from '#app/services/useCurrentDepositor'
import {useCommand} from '#command'
import {HelpIcon, LoadingSpinner} from '#components'
import OldButton from '#components/Button'
import {useTranslation} from '#components/i18n'
import Modal from '#components/Modal'
import {
    CustomerDeclarationProvider,
    useCustomerDeclaration
} from '#pages/organizations/CustomerDeclaration/CustomerDeclarationContext'
import {TextInput} from '#pages/organizations/CustomerDeclaration/widgets'
import {organisationalRoleTMap} from '#services/enumTranslationKeyMapping'
import {PersonValidator} from '#services/PersonValidator'
import useSupportPane from '#services/useSupportPane'
import {useSelector} from '#state/useSelector'
import {CustomerDeclaration as CustomerDeclarationType} from '@fixrate/fixrate-query'
import {Box, Button, Stack, useMediaQuery} from '@mui/material'
import classNames from 'classnames'
import {forwardRef, Fragment, useEffect, useRef, useState} from 'react'
import {Link, useNavigate} from 'react-router-dom'
import {useTracking} from 'react-tracking'
import styles from './CustomerDeclaration.module.scss'
import {useValidate, useValidateAll, Validate} from './Validate'
import {
    CheckboxWithLabel,
    CustomerDeclarationButton,
    List,
    Navigation,
    Question,
    Section,
    YesOrNoRadio
} from './widgets'
import {NavigationLink} from '#pages/organizations/CustomerDeclaration/widgets/Navigation'
import { createCustomerDeclarationDraft } from '#app/services/thunks/depositor'
import { useDispatch } from 'react-redux'

const API_BASE_URL = config().apiUrl

const About = forwardRef<HTMLDivElement>(function (_, ref) {

    const {t} = useTranslation('pages-organizations')
    const supportPane = useSupportPane()
    const session = useSelector(state => state.session)
    const {trackEvent} = useTracking({page: 'CustomerDeclaration', session})

    function showSupportArticle() {
        trackEvent({event: 'open-context-help'})
        supportPane.show('1c9f9f40107e416a92d65e4db1f49ac6')
    }

    return (
        <Section ref={ref} title="Om kundkännedomen">
            <p>
                För att kunna öppna ett konto och placera pengar i en bank måste du fylla i en enkel
                kundkännedom för er organisation. Kundkännedomen skickas som ett bilaga till banken när du eller någon
                annan i organisationen beställer en insättning.
            </p>
            <p>
                Kundkännedomen är nödvändig för att bankerna ska uppfylla penningtvättsförordningen och
                andra myndighetskrav.
            </p>
            <Box pt={2}>
                <Button variant={'outlined'} onClick={showSupportArticle} startIcon={<i className="ri-lifebuoy-line"/>}>
                    {t('helpForCompletion')}
                </Button>
            </Box>
        </Section>
    )
})

About.displayName = 'About'


const YearlyTurnOver = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Organisationens årliga omsättning" paths={['yearlyTurnover']}>
            <p>Ange en ungefärlig förväntad årlig omsättning. Använd gärna budgeterade siffror eller siffror från
                föregående års bokslut.</p>
            <Question path={'yearlyTurnover'}>
                <TextInput
                    path={'yearlyTurnover'}
                    placeholder={'Årlig omsättning'}
                    nativePlaceholder={'100 mill'}
                    unit={'kr'}
                    formatter={(value) => {
                        const number = value.replace(/,|\s/gmi, '')
                        if (number === '') return value

                        if (Number.isNaN(Number(number))) {
                            return value
                        }

                        return parseFloat(number).toLocaleString('sv-SE', {currency: 'SEK'})
                    }}
                    required
                />
            </Question>
        </Section>
    )
})

YearlyTurnOver.displayName = 'YearlyTurnOver'

const BusinessDescription = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Beskrivning av verksamheten" paths={['businessDescription']}>
            <p>Var vänlig beskriv verksamheten så utförligt och tydligt som möjligt.
                Ange också vilka produkter, varor och/eller tjänster ni säljer.</p>
            <Question path={'businessDescription'}>
                <TextInput
                    path={'businessDescription'}
                    placeholder={'Beskrivning av verksamheten'}
                    type="textarea"
                    required
                />
            </Question>
        </Section>
    )
})

BusinessDescription.displayName = 'BusinessDescription'

const BusinessSector = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Bransch" paths={['businessSector']}>
            <p>Inom vilken bransch verkar organisationen?</p>
            <Question path={'businessSector'}>
                <TextInput
                    path={'businessSector'}
                    placeholder={'Bransch'}
                    required
                />
            </Question>
        </Section>
    )
})

BusinessSector.displayName = 'BusinessSector'

const BusinessPermitRequired = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Krav på tilstånd" paths={['businessPermitRequired']}>
            <p>Kräver verksamheten någon form av tillstånd?</p>
            <Question path={'businessPermitRequired'}>
                <YesOrNoRadio
                    path={'businessPermitRequired'}
                    data-cy="businessPermitRequired"
                />
            </Question>

            <Question
                title="Beskriv tillstånden som krävs"
                path={'businessPermit'}
                hide={!customerDeclaration.businessPermitRequired}
            >
                <p>[mer utförlig text av vilken tillstånd som er aktuell]</p>
                <TextInput
                    path={'businessPermit'}
                    placeholder={'Krav på tilstånd'}
                    required
                />
            </Question>
        </Section>
    )
})

BusinessPermitRequired.displayName = 'BusinessPermitRequired'

const HasOperationsAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Bedriver organisationen verksamhet utanför Sverige?"
                 paths={['hasOperationsAbroad', 'operationsAbroad']}>

            <p>Har organisationen kontor eller bedriver organisationen verksamhet i andra länder?</p>
            <Question path={'hasOperationsAbroad'}>
                <YesOrNoRadio
                    path={'hasOperationsAbroad'}
                    data-cy="hasOperationsAbroad"
                />
            </Question>
            <Question
                title={'Länder utanför Sverige där organisationen har verksamhet'}
                path={'operationsAbroad'}
                hide={!customerDeclaration.hasOperationsAbroad}
                className={styles.fullWidth}
            >
                <p>Ange företagsnamn om detta avviker från företagsnamnet i Sverige.</p>
                <List
                    path={'operationsAbroad'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flera länder
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                path={`operationsAbroad.${index}.country`}
                                placeholder={'Land'}
                                required
                            />
                            <TextInput
                                path={`operationsAbroad.${index}.name`}
                                placeholder={'Företagsnamn'}
                            />
                        </Stack>
                    )}
                </List>
            </Question>
        </Section>
    )
})

HasOperationsAbroad.displayName = 'HasOperationsAbroad'

const PubliclyListed = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Är organisationen börsnoterad?" paths={['publiclyListed']}>
            <p>Är organisationen börsnoterad på Stockholms börs eller någon annan börs?</p>
            <Question path={'publiclyListed'}>
                <YesOrNoRadio
                    path={'publiclyListed'}
                    data-cy="publiclyListed"
                />
            </Question>
        </Section>
    )
})

PubliclyListed.displayName = 'PubliclyListed'

const HasParentCompany = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Har organisationen ett moderbolag?" hide={customerDeclaration.publiclyListed}
                 paths={['hasParentCompany', 'ultimateParent']}>
            <p>Ett moderbolag är ett bolag som inte är ett investeringsbolag ock som har aktiemajoritet eller på något
                annat sätt bestämmande inflytande över organisationen.
                Om organisationen har ett moderbolag räknas det som att vara en del av en koncern.</p>

            <Question path={'hasParentCompany'}>
                <YesOrNoRadio
                    path={'hasParentCompany'}
                    data-cy="hasParentCompany"
                />
            </Question>

            <Question
                title="Moderbolaget i koncernen"
                path={'ultimateParent'}
                hide={customerDeclaration.hasParentCompany !== true}
                className={styles.fullWidth}
            >
                <TextInput
                    path={'ultimateParent.name'}
                    placeholder={'Namn på moderbolaget'}
                    required
                />
                <TextInput
                    path={'ultimateParent.nationalIdentity'}
                    placeholder={'Organisationsnummer/utländskt identifikationsnummer'}
                    required
                />
                <TextInput
                    path={'ultimateParent.country'}
                    placeholder={'Land'}
                    required
                />
            </Question>

            <Question
                title="Är moderbolaget börsnoterat?"
                path={'ultimateParent.publiclyListed'}
                hide={customerDeclaration.hasParentCompany !== true}
            >
                <YesOrNoRadio path={'ultimateParent.publiclyListed'}/>
            </Question>

            <Question
                title="Beskriv relationen till moderbolaget"
                path={'relationToUltimateParent'}
                hide={customerDeclaration.hasParentCompany !== true}
                className={styles.fullWidth}
            >
                <p>Beskriv kort relationen mellan organisationen och moderbolaget i koncernen.</p>
                <TextInput
                    path={'relationToUltimateParent'}
                    placeholder="Företagsstruktur"
                    nativePlaceholder={'Beskriv var organisationen är placerat i företagsstrukturen'}
                    required
                    type="textarea"
                />
            </Question>

        </Section>
    )
})

HasParentCompany.displayName = 'HasParentCompany'

const HasSubsidiaries = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Har organisationen dotterbolag?" hide={customerDeclaration.publiclyListed}
                 paths={['hasSubsidiaries', 'relationToSubsidiaries']}>
            <p>Ett dotterbolag är ett bolag som ägs eller på något annat sätt kontrolleras av ett moderbolag.</p>

            <Question path={'hasSubsidiaries'}>
                <YesOrNoRadio
                    path={'hasSubsidiaries'}
                    data-cy="hasSubsidiaries"
                />
            </Question>

            <Question
                title="Beskriv relationen till dotterbolaget"
                path={'relationToSubsidiaries'}
                hide={customerDeclaration.hasSubsidiaries !== true}
                className={styles.fullWidth}
            >
                <p>Beskriv kort relationen mellan organisationen och dotterbolaget i koncernen.</p>
                <TextInput
                    path={'relationToSubsidiaries'}
                    placeholder="Företagsstruktur"
                    nativePlaceholder={'Beskriv var organisationen är placerat i företagsstrukturen'}
                    required
                    type="textarea"
                />
            </Question>

        </Section>
    )
})

HasSubsidiaries.displayName = 'HasSubsidiaries'

const MainlyPassiveIncome = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Har organisationen huvudsakligen passiva inkomster/tillgångar?"
                 paths={['mainlyPassiveIncome']}>
            <p>Passiva inkomster är:</p>
            <ul className={styles.infoList}>
                <li>Ränteintäkter.</li>
                <li>Dividender/utdelning och liknande utbetalningar.</li>
                <li>Ägarintäkter och royalties, med undantag för hyresintäkter och royalties från aktiv verksamhet helt
                    eller delvis utförd av organisationens anställda.
                </li>
                <li>Livräntor.</li>
                <li>Nettovinster från försäljning eller byte av finansiella instrument och andra finansiella produkter
                    som genererar passiva inkomster motsvarande inkomster nämnda ovan.
                </li>
                <li>Nettovinster från transaktioner i finansiella instrument och andra finansiella produkter (inklusive
                    terminer, optioner och liknande transaktioner).
                </li>
                <li>Netto valutavinster.</li>
                <li>Nettointäkter från finansiella instrument som innebär betalning från en part till en annan vid givna
                    intervaller beräknade på grundval av ett närmare angivet index på ett nominellt värde i utbyte mot
                    specificerade motprestationer eller ett löfte om att betala motsvarande belopp (swap).
                </li>
                <li>Belopp mottagna från försäkringsavtal med återköpsvärde.</li>
            </ul>
            <Question path={'mainlyPassiveIncome'}>
                <YesOrNoRadio
                    path={'mainlyPassiveIncome'}
                    data-cy="mainlyPassiveIncome"
                />
            </Question>
        </Section>
    )
})

MainlyPassiveIncome.displayName = 'MainlyPassiveIncome'

const AccountingBalance = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Bokföringsmässig balans" paths={['accountingBalanceOver20millEuro']}>
            <p>Har organisationen en bokföringsmässig balans som motsvarar minst 20 miljoner euro?</p>
            <Question path={'accountingBalanceOver20millEuro'}>
                <YesOrNoRadio
                    path={'accountingBalanceOver20millEuro'}
                    data-cy="accountingBalanceOver20millEuro"
                />
            </Question>
        </Section>
    )
})

AccountingBalance.displayName = 'AccountingBalance'

const EquityCapital = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Eget kapital" paths={['equityCapitalOver2millEuro']}>
            <p>Har organisationen ett eget kapital som motsvarar minst 2 miljoner euro?</p>
            <Question path={'equityCapitalOver2millEuro'}>
                <YesOrNoRadio
                    path={'equityCapitalOver2millEuro'}
                    data-cy="equityCapitalOver2millEuro"
                />
            </Question>
        </Section>
    )
})

EquityCapital.displayName = 'EquityCapital'

const CryptoCurrencies = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Kryptovaluta" paths={['cryptoCurrencies']}>
            <p>Handlar organisationen med någon form av kryptovaluta?</p>
            <Question path={'cryptoCurrencies'}>
                <YesOrNoRadio
                    path={'cryptoCurrencies'}
                    data-cy="cryptoCurrencies"
                />
            </Question>

            <Question
                title="Beskriv handeln med kryptovaluta"
                path={'cryptoCurrenciesDescription'}
                hide={!customerDeclaration.cryptoCurrencies}
            >
                <p>Beskriv syftet och målet med handeln, inklusive varför, hur mycket det handlas för,
                    vilken kryptovaluta, var handeln äger rum, vilken roll organisationen har i handeln,
                    det vill säga förmedlare, utvinnare, handlare.</p>
                <TextInput
                    path={'cryptoCurrenciesDescription'}
                    type="textarea"
                    placeholder={'Beskriv handeln'}
                    required
                />
            </Question>
        </Section>
    )
})

CryptoCurrencies.displayName = 'CryptoCurrencies'

const FinancialInstitution = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Är organisationen en finansinstitution?" paths={['financialInstitution', 'giin']}>
            <p>Är organisationen en finansiell institution som erbjuder tjänster inom bank, finans, försäkring, värdepapper
                eller motsvarande?</p>
            <Question path={'financialInstitution'}>
                <YesOrNoRadio
                    path={'financialInstitution'}
                    data-cy="financialInstitution"
                />
            </Question>

            <Question
                title="Global Intermediary Identification Number (GIIN)"
                path={'giin'}
                hide={!customerDeclaration.financialInstitution}
            >
                <TextInput
                    path={'giin'}
                    placeholder={'GIIN'}
                    unit={<span>Ange orsak om numret saknas</span>}
                    required
                />
            </Question>
        </Section>
    )
})

FinancialInstitution.displayName = 'FinancialInstitution'

const ShareClasses = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Aktieslag" paths={['financialInstitution', 'giin']}>
            <p>Finns det flera aktieslag som påverkar vem som i realiteten kontrollerar organisationen?</p>
            <Question path={'shareClasses'}>
                <YesOrNoRadio
                    path={'shareClasses'}
                    data-cy="shareClasses"
                />
            </Question>

            <Question
                title="Beskriv de olika aktieslagen"
                path={'shareClassesDescription'}
                hide={!customerDeclaration.shareClasses}
            >
                <TextInput
                    path={'shareClassesDescription'}
                    type="textarea"
                    placeholder={'Beskrivning av aktieslagen'}
                    required
                />
            </Question>
        </Section>
    )
})

ShareClasses.displayName = 'ShareClasses'

const ShareHolderAgreement = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Aktieägaravtal som påverkar kontroll över organisationen"
                 paths={['shareHolderAgreement', 'shareHolderAgreementDescription']}>
            <p>Föreligger det stadgar, aktieägaravtal eller annan överenskommelse som har bestämmelser om vem som i
                verkligheten (direkt eller indirekt) kontrollerar organisationen?</p>
            <Question path={'shareHolderAgreement'}>
                <YesOrNoRadio
                    path={'shareHolderAgreement'}
                    data-cy="shareHolderAgreement"
                />
            </Question>

            <Question
                title="Beskriv avtalen och vilka personer som har den verkliga kontrollen över organisationen"
                path={'shareHolderAgreementDescription'}
                hide={!customerDeclaration.shareHolderAgreement}
            >
                <TextInput
                    path={'shareHolderAgreementDescription'}
                    type="textarea"
                    placeholder={'Beskrivning av aktieägaravtal'}
                    required
                />
            </Question>
        </Section>
    )
})

ShareHolderAgreement.displayName = 'ShareHolderAgreement'

const PaymentProcessor = forwardRef<HTMLDivElement>(function (_, ref) {
    return (
        <Section ref={ref} title="Ska organisationen bedriva betalningsförmedling?" paths={['paymentProcessor']}>
            <p>Ska verksamheten bedriva betalningsförmedlingsverksamhet eller liknande som är underlagt
                registreringsskyldighet och kontroll från Finansinspektionen?</p>
            <Question path={'paymentProcessor'}>
                <YesOrNoRadio
                    path={'paymentProcessor'}
                    data-cy="paymentProcessor"
                />
            </Question>
        </Section>
    )
})

PaymentProcessor.displayName = 'PaymentProcessor'

const FundOrigins = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()

    const {setValid, setTouched, isTouched} = useValidate('fundOrigins')

    const atLeastOneOptionPicked =
        !!customerDeclaration.fundOrigins.businessOperations
        || !!customerDeclaration.fundOrigins.returnOnInvestments
        || !!customerDeclaration.fundOrigins.loan
        || !!customerDeclaration.fundOrigins.rentalIncome
        || !!customerDeclaration.fundOrigins.fundManagement
        || !!customerDeclaration.fundOrigins.paidUpEquity
        || !!customerDeclaration.fundOrigins.publicSubsidies
        || !!customerDeclaration.fundOrigins.saleOfAssets
        || !!customerDeclaration.fundOrigins.other

    const errorMsg = !atLeastOneOptionPicked && 'Välj minst ett alternativ'

    useEffect(() => {
        setValid(atLeastOneOptionPicked)
    }, [setValid, atLeastOneOptionPicked])

    function onChange() {
        setTouched(true)
    }

    return (
        <Section ref={ref} title="Pengarnas ursprung" paths={['fundOrigins']}>
            <p>Varifrån kommer de pengar som sätts in på placeringskontot via Fixrate.</p>
            <p>Du kan kryssa för ett eller flera alternativ.</p>
            <p>Förståelsen av pengarnas ursprung är avgörande för banker vid etablering av nya kundrelationer,
                särskilt med företag. Detta beror på nödvändigheten att förebygga penningtvätt, följa
                strikt regelverk, hantera risk effektivt, skydda bankens rykte, och säkerställa internationellt
                samarbete.
                Genom att identifiera ursprunget till pengarna kan banker säkerställa att de inte stödjer olagliga aktiviteter och
                uppfyller både nationella och internationella krav. Detta bidrar till laglig och etisk drift, samtidigt som
                det skyddar mot ekonomiska och ryktesmässiga risker.</p>
            <p>Ge så god och detaljerad information som möjligt för de kategorier som är aktuella för din organisation.
                God och tillräcklig information bidrar till snabbare ärendehantering hos
                banken. <HelpIcon supportArticleId={'38da8d808ba3495c9e07f1809e03c7d1'} inline/></p>

            <Question path={'fundOrigins'}>
                <CheckboxWithLabel
                    id="businessOperationsCheckbox"
                    label="Överskott eller intäkter genererade från verksamheten"
                    path={'fundOrigins.businessOperations'}
                    data-cy="fundOrigins-businessOperations"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.businessOperations === true && (
                    <TextInput
                        path={'fundOrigins.businessOperationsSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-businessOperations-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Avkastning från investeringar"
                    path={'fundOrigins.returnOnInvestments'}
                    data-cy="fundOrigins-returnOnInvestments"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.returnOnInvestments === true && (
                    <TextInput
                        path={'fundOrigins.returnOnInvestmentsSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-returnOnInvestments-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Lån"
                    path={'fundOrigins.loan'}
                    data-cy="fundOrigins-loan"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.loan === true && (
                    <TextInput
                        path={'fundOrigins.loanSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-loan-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Hyresintäkter"
                    path={'fundOrigins.rentalIncome'}
                    data-cy="fundOrigins-rentalIncome"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.rentalIncome === true && (
                    <TextInput
                        path={'fundOrigins.rentalIncomeSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-rentalIncome-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Fondförvaltning på uppdrag av andra"
                    path={'fundOrigins.fundManagement'}
                    data-cy="fundOrigins-fundManagement"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.fundManagement === true && (
                    <TextInput
                        path={'fundOrigins.fundManagementSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-fundManagement-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Aktieägartillskott eller motsvarande (aktiekapital)"
                    path={'fundOrigins.paidUpEquity'}
                    data-cy="fundOrigins-paidUpEquity"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.paidUpEquity === true && (
                    <TextInput
                        path={'fundOrigins.paidUpEquitySpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                    />
                )}
                <CheckboxWithLabel
                    label="Anslag från myndighet "
                    path={'fundOrigins.publicSubsidies'}
                    data-cy="fundOrigins-publicSubsidies"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.publicSubsidies === true && (
                    <TextInput
                        path={'fundOrigins.publicSubsidiesSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-publicSubsidies-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Försäljning av bolag / fastigheter / investeringar / andra tillgångar"
                    path={'fundOrigins.saleOfAssets'}
                    data-cy="fundOrigins-saleOfAssets"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.saleOfAssets === true && (
                    <TextInput
                        path={'fundOrigins.saleOfAssetsSpecify'}
                        placeholder="Beskriv ..."
                        required
                        type="textarea"
                        data-cy="fundOrigins-saleOfAssets-textField"
                    />
                )}
                <CheckboxWithLabel
                    label="Annat"
                    path={'fundOrigins.other'}
                    data-cy="fundOrigins-other"
                    onChange={onChange}
                />
                {customerDeclaration.fundOrigins.other === true && (
                    <TextInput
                        path={'fundOrigins.specify'}
                        placeholder="Beskriv pengarnas ursprung"
                        required
                        type="textarea"
                        data-cy="fundOrigins-other-textField"
                    />
                )}
                <p className="field-error-message">{isTouched() && errorMsg}</p>
            </Question>
        </Section>
    )
})

FundOrigins.displayName = 'FundOrigins'

const HasPaymentRecievedAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Kommer insättningar att överföras utomlands när de tas ut?"
                 paths={['hasPaymentsMadeAbroad', 'paymentsMadeAbroad']}>
            <p>Kommer insättningar gjorda genom Fixrate att direkt överföras till utlandet när de tas ut? Detta är
                endast relevant om du registrerar ett utländskt avräkningskonto i Fixrate.</p>
            <Question path="hasPaymentsMadeAbroad">
                <YesOrNoRadio
                    path="hasPaymentsMadeAbroad"
                    data-cy="hasPaymentsMadeAbroad"
                />
            </Question>
            <Question title="Land hvor insättningar vil överföras" path="paymentsMadeAbroad"
                      hide={customerDeclaration.hasPaymentsMadeAbroad !== true}>
                <p>Ange till vilka länder beloppet kommer att överföras och orsaken till att beloppet överförs utomlands.</p>
                <List
                    path={'paymentsMadeAbroad'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flere land
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                placeholder={'Land'}
                                path={`paymentsMadeAbroad.${index}.country`}
                                required
                            />
                            <TextInput
                                placeholder={'Orsak'}
                                path={`paymentsMadeAbroad.${index}.reason`}
                                required
                            />
                        </Stack>
                    )}
                </List>
            </Question>
        </Section>
    )
})

HasPaymentRecievedAbroad.displayName = 'HasPaymentRecievedAbroad'

const HasPaymentsMadeAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Kommer insättningar att göras från konto utomlands?"
                 paths={['hasPaymentsReceivedAbroad', 'paymentsReceivedAbroad']}>
            <p>Kommer insättningar genom Fixrate att betalas in direkt från ett konto utomlands?</p>
            <Question path="hasPaymentsReceivedAbroad">
                <YesOrNoRadio
                    path="hasPaymentsReceivedAbroad"
                    data-cy="hasPaymentsReceivedAbroad"
                />
            </Question>

            <Question title="Land hvor insättningen vil betalas inn från" path="paymentsReceivedAbroad"
                      hide={customerDeclaration.hasPaymentsReceivedAbroad !== true}>
                <p>Ange från vilket land beloppet kommer att överföras och orsaken till att beloppet överförs från utomlands.</p>
                <List
                    path={'paymentsReceivedAbroad'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flere land
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                placeholder={'Land'}
                                path={`paymentsReceivedAbroad.${index}.country`}
                                required
                            />
                            <TextInput
                                placeholder={'Orsak'}
                                path={`paymentsReceivedAbroad.${index}.reason`}
                                required
                            />
                        </Stack>
                    )}
                </List>
            </Question>

        </Section>
    )
})

HasPaymentsMadeAbroad.displayName = 'HasPaymentsMadeAbroad'

const AmericanCompany = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} path="americanCompany"
                 title="Är organisationen en amerikansk juridisk person eller sammanslutning?"
                 paths={['americanCompany', 'americanCompanyTin']}>
            <Question path="americanCompany">
                <YesOrNoRadio
                    path="americanCompany"
                    data-cy="americanCompany"
                />
            </Question>

            <Question
                path="americanCompanyTin"
                title="Amerikanskt identifikationsnummer (TIN)"
                hide={customerDeclaration.americanCompany !== true}
            >
                <TextInput
                    placeholder={'Amerikanskt TIN'}
                    path={'americanCompanyTin'}
                    unit={<span>Ange orsak om numret saknas</span>}
                    required
                />
            </Question>
        </Section>
    )
})

AmericanCompany.displayName = 'AmericanCompany'

const CompanyTaxpayerAbroad = forwardRef<HTMLDivElement>(function (_, ref) {
    const customerDeclaration = useCustomerDeclaration<CustomerDeclarationType>()
    return (
        <Section ref={ref} title="Är organisationens skattemässiga hemvist i annat land än Sverige?"
                 paths={['companyTaxpayerAbroad', 'companyTin']}>
            <Question path="companyTaxpayerAbroad">
                <YesOrNoRadio
                    path="companyTaxpayerAbroad"
                    data-cy="companyTaxpayerAbroad"
                />
            </Question>
            <Question title="Länder där organisationen har skattemässig hemvist" path={'companyTin'}
                      hide={customerDeclaration.companyTaxpayerAbroad !== true}>
                <p>Ange organisationens utländska skatteregistreringsnummer / tax identification number</p>
                <List
                    path={'companyTin'}
                    button={({onClick}) => (
                        <CustomerDeclarationButton.Primary onClick={onClick}>
                            <CustomerDeclarationButton.Align.Left>
                                <CustomerDeclarationButton.Icon.Plus/>
                            </CustomerDeclarationButton.Align.Left>
                            Flera länder
                        </CustomerDeclarationButton.Primary>
                    )}
                >
                    {(index) => (
                        <Stack direction={'column'} spacing={2} data-index={index} key={index}>
                            <TextInput
                                path={`companyTin.${index}.country`}
                                placeholder={'Land'}
                                required
                            />
                            <TextInput
                                path={`companyTin.${index}.tin`}
                                placeholder={'Utländskt ID-nummer'}
                            />
                        </Stack>
                    )}
                </List>
            </Question>
        </Section>
    )
})

CompanyTaxpayerAbroad.displayName = 'CompanyTaxpayerAbroad'

const AgreeToUpdate = forwardRef<HTMLDivElement>(function (_, ref) {
    const path = 'agreeToUpdate'
    const agreeToUpdate = useCustomerDeclaration<boolean>(path)

    const {setValid, isTouched} = useValidate(path)

    useEffect(() => {
        setValid(agreeToUpdate === true)
    }, [setValid, agreeToUpdate])

    return (
        <Section ref={ref} title="Uppdateringsskyldighet">
            <p>Kundkännedomen måste alltid innehålla uppdaterad information om organisationen</p>
            <Question path="agreeToUpdate">
                <CheckboxWithLabel
                    label="Vi accepterar att organisationen är skyldigt att uppdatera och signera kundkännedomen på nytt om det sker förändringar."
                    path="agreeToUpdate"
                    data-cy="agreeToUpdate"
                />
                <p className="field-error-message">{agreeToUpdate !== true && isTouched() && 'Du må akseptere oppdateringsplikten'}</p>
            </Question>
        </Section>
    )
})

AgreeToUpdate.displayName = 'AgreeToUpdate'

const People = forwardRef<HTMLDivElement>(function (_, ref) {
    const {t} = useTranslation('pages-organizations')
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()

    if (!depositor) {
        return null
    }

    const allPersonsAreReady = PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)

    const customerDeclarationPeople = depositor.people.filter(person => PersonValidator.isCustomerDeclarationPerson(person))

    const goToProfile = (link) => {
        navigate(link)
    }

    return (
        <Fragment>
            <Section ref={ref} title={'Verkliga huvudmen och nyckelpersoner'}>
                <p>
                    Kundkännedomen ska innehålla en översikt över verkliga huvudmen och nyckelpersoner i
                    organisationen.
                </p>
                <p>
                    Följande information om styrelsemedlemmar, verkställande direktör / kontaktperson och verkliga
                    huvudmen är registrerad i organisationens personregister.
                </p>
                {customerDeclarationPeople.length > 0 && (
                    <table>
                        <thead>
                        <tr>
                            <th>Namn</th>
                            <th>Roller</th>
                            <th>Status</th>
                        </tr>
                        </thead>
                        <tbody>
                        {customerDeclarationPeople.map(person => (
                            <tr key={person.associatedUserId}
                                onClick={() => goToProfile(`/organizations/${depositor.id}/users/profile/${person.personId}`)}>
                                <td>{person.firstName} {person.lastName}</td>
                                <td>
                                    {person.organisationalRoles.map(role => <span
                                        key={role}>{t(organisationalRoleTMap[role])}</span>)}
                                </td>
                                <td>
                                    {
                                        PersonValidator.isCompleteForCustomerDeclaration(depositor, person)
                                            ? <span><i className="ri-check-line green"/></span>
                                            : <span><i
                                                className="ri-error-warning-line red"/><span>Mangler informasjon</span></span>
                                    }
                                </td>
                            </tr>
                        ))}
                        </tbody>
                    </table>
                )}
                <p>
                    Om informationen som beskrivs ovan inte stämmer kan du ändra detta i <Link
                    to={`/organizations/${depositor.id}/users/board`}>organisationsöversikten</Link>
                </p>
                {!allPersonsAreReady &&
                    <p className={'field-error-message'}>All information om styrelse, ledning och ägare måste fyllas i
                        innan kundkännedomen kan skapas.</p>}
            </Section>
        </Fragment>
    )
})

People.displayName = 'People'

function CustomerDeclaration() {

    const {generateCustomerDeclaration} = useCommand()
    const confirmationModal = useConfirmationModal()
    const {showInfoMessage} = useInfoMessages()
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()
    const {areAllValid, touchAll} = useValidateAll()
    const isMobile = useMediaQuery('(max-width: 800px)')

    const [validating, setValidating] = useState(false)
    const [validationOk, setValidationOk] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [progressModalOpen, setProgressModalOpen] = useState(false)
    const [hasDraft, setHasDraft] = useState(depositor.customerDeclarationDraftState !== 'NO_DRAFT')

    const scrollId = null
    const dispatch = useDispatch()


    function wait(ms) {
        return new Promise(resolve => setTimeout(resolve, ms))
    }

    useEffect(() => {
        if (!hasDraft && depositor.customerDeclarationDraftState === 'NO_DRAFT') {
            dispatch(createCustomerDeclarationDraft(depositor.id))
            setHasDraft(true)
        }
    }, [hasDraft, depositor, dispatch])

    async function onSubmitCustomerDeclaration() {
        setProgressModalOpen(true)
        setValidating(true)
        await wait(3_000)
        const valid = areAllValid() && PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)
        setValidating(false)
        setValidationOk(valid)
        await wait(2_000)
        if (!valid) {
            touchAll()
            setProgressModalOpen(false)
            return
        }
        setSubmitting(true)
        const {waitForCommand} = await generateCustomerDeclaration(depositor.id)
        const abortInfoMessage1 = showInfoMessage({
            type: 'info',
            message: 'Vi producerar dokumentet ...',
            delay: 10_000
        })
        const abortInfoMessage2 = showInfoMessage({
            type: 'info',
            message: 'Vi producerar fortfarande dokumentet. Vi ber om ursäkt ...',
            delay: 30_000
        })
        const success = await waitForCommand(120_000)
        abortInfoMessage1()
        abortInfoMessage2()
        if (success) {
            navigate(`${API_BASE_URL}/organizations/${depositor.id}/customerdecl`)
        } else {
            confirmationModal('Kundkännedom', '\n' +
                'Det tog mycket lång tid att producera dokumentet. Tveka inte att kontakta support om det inte dyker upp.', 'Fortsätt')
            navigate(`${API_BASE_URL}/organizations/${depositor.id}/customerdecl`)
        }
    }

    const scrollableContainer = useRef()
    const container = useRef()
    const about = useRef()
    const businessDescription = useRef()
    const businessSector = useRef()
    const businessPermitRequired = useRef()
    const publiclyListed = useRef()
    const hasParentCompany = useRef()
    const hasSubsidiaries = useRef()
    const financialInstitution = useRef()
    const agreeToUpdate = useRef()
    const companyTaxpayerAbroad = useRef()
    const hasPaymentRecievedAbroad = useRef()
    const hasPaymentsMadeAbroad = useRef()
    const fundOrigins = useRef()
    const people = useRef()
    const createDocument = useRef()

    const validLink = (path) => {
        return areAllValid({paths: [path]}) === true ? true : null
    }

    const errorLink = (paths = []) => {
        return areAllValid({paths}) === false ? true : null
    }

    if (!depositor) {
        return null
    } else {

        const allPersonsAreReady = PersonValidator.isDepositorReadyForCustomerDeclaration(depositor)

        return (
            <CustomerDeclarationProvider value={depositor.customerDeclaration}>
                {progressModalOpen && (
                    <Modal header={'Producerar dokument'} width={'50rem'} onCancel={() => setProgressModalOpen(false)}>
                        <div className={styles.modalContent}>
                            {!submitting && (
                                <div className={styles.progressStep}>
                                    {validating && <i className={classNames('ri-search-eye-line', styles.search)}/>}
                                    {!validating && validationOk &&
                                        <i className={classNames('ri-check-line', styles.validationResult)}/>}
                                    {!validating && !validationOk &&
                                        <i className={classNames('ri-close-line', styles.validationResult)}/>}

                                    <p>Validerar information</p>
                                    <div className={styles.progressBar}/>
                                </div>
                            )}
                            {submitting && (
                                <div className={styles.progressStep}>
                                    <i className={classNames('ri-settings-5-line', styles.cog)}/>
                                    <LoadingSpinner text={'Producerar PDF...'}/>
                                </div>
                            )}
                        </div>
                    </Modal>
                )}
                <PageHeader
                    title={isMobile ? 'Kundkännedom' : `Kundkännedom för ${depositor.name}`}
                    useBackButton
                />
                <div ref={container} className={styles.content}>
                    <div className={styles.links}>
                        <h3 className={styles.linksHeader}>Om organisationen</h3>
                        <Navigation scrollableContainer={scrollableContainer}>
                            <NavigationLink
                                success={validLink('businessDescription')}
                                error={errorLink(['businessDescription'])}
                                to={businessDescription}
                                active={scrollId === 'businessDescription'}
                            >
                                Beskrivning av verksamheten
                            </NavigationLink>

                            <NavigationLink
                                success={validLink('businessSector')}
                                error={errorLink(['businessSector'])}
                                to={businessSector}
                                active={scrollId === 'businessSector'}
                            >
                                Bransch
                            </NavigationLink>

                            <NavigationLink
                                success={validLink('businessPermitRequired')}
                                error={errorLink(['businessPermitRequired'])}
                                to={businessPermitRequired}
                                active={scrollId === 'businessPermitRequired'}
                            >
                                Krav på tilstånd
                            </NavigationLink>

                            <NavigationLink
                                to={companyTaxpayerAbroad}
                                success={validLink('companyTaxpayerAbroad')}
                                error={errorLink(['companyTaxpayerAbroad', 'companyTin'])}
                                active={scrollId === 'companyTaxpayerAbroad'}
                            >
                                Är organisationen skattemässigt hemmahörande i andra länder än Sverige?
                            </NavigationLink>

                            <NavigationLink
                                to={publiclyListed}
                                success={validLink('publiclyListed')}
                                error={errorLink(['publiclyListed'])}
                                active={scrollId === 'publiclyListed'}
                            >
                                Är organisationen börsnoterad?
                            </NavigationLink>

                            {depositor.customerDeclaration.publiclyListed ? null : (
                                <NavigationLink
                                    to={hasParentCompany}
                                    error={errorLink(['hasParentCompany', 'ultimateParent', 'relationToUltimateParent'])}
                                    success={validLink('hasParentCompany')}
                                    active={scrollId === 'hasParentCompany'}
                                >
                                    Har organisationen ett moderbolag?
                                </NavigationLink>
                            )}

                            {depositor.customerDeclaration.publiclyListed ? null : (
                                <NavigationLink
                                    to={hasSubsidiaries}
                                    error={errorLink(['hasSubsidiaries', 'relationToSubsidiaries'])}
                                    success={validLink('hasSubsidiaries')}
                                    active={scrollId === 'hasSubsidiaries'}
                                >
                                    Har organisationen dotterbolag?
                                </NavigationLink>
                            )}

                            <NavigationLink
                                to={financialInstitution}
                                success={validLink('financialInstitution')}
                                error={errorLink(['financialInstitution', 'giin'])}
                                active={scrollId === 'financialInstitution'}
                            >
                                Är organisationen en finansinstitution?
                            </NavigationLink>

                            <h3 className={styles.linksHeader}>Om medlen</h3>

                            <NavigationLink
                                to={fundOrigins}
                                success={validLink('fundOrigins')}
                                error={errorLink(['fundOrigins'])}
                                active={scrollId === 'fundOrigins'}
                            >
                                Ursprunget till medlen
                            </NavigationLink>

                            <NavigationLink
                                to={hasPaymentsMadeAbroad}
                                success={validLink('hasPaymentsReceivedAbroad')}
                                error={errorLink(['hasPaymentsReceivedAbroad', 'paymentsReceivedAbroad'])}
                                active={scrollId === 'hasPaymentsReceivedAbroad'}
                            >
                                Kommer insättningar att göras från konto utomlands?
                            </NavigationLink>

                            <NavigationLink
                                to={hasPaymentRecievedAbroad}
                                success={validLink('hasPaymentsMadeAbroad')}
                                error={errorLink(['hasPaymentsMadeAbroad', 'paymentsMadeAbroad'])}
                                active={scrollId === 'hasPaymentsMadeAbroad'}
                            >
                                Kommer insättningar att överföras utomlands när de tas ut?
                            </NavigationLink>

                            <h3 className={styles.linksHeader}>Ägande och kontroll</h3>

                            <NavigationLink
                                to={people}
                                success={allPersonsAreReady}
                                error={!allPersonsAreReady}
                                active={scrollId === 'people'}
                            >
                                Verkliga huvudmen och nyckelpersoner
                            </NavigationLink>

                            <div ref={createDocument} className={styles.navigationButtonRow}>
                                {areAllValid() ||
                                    <p className="field-error-message">Alla obligatoriska fält måste fyllas i</p>}
                                <OldButton
                                    id="submitCustomerDeclarationButton"
                                    onClick={onSubmitCustomerDeclaration}>
                                    Skapa dokumentet
                                </OldButton>
                            </div>

                        </Navigation>
                    </div>
                    <div ref={scrollableContainer} className={styles.body}>
                        <About ref={about}/>
                        <BusinessDescription ref={businessDescription}/>
                        <BusinessSector ref={businessSector}/>
                        <BusinessPermitRequired ref={businessPermitRequired}/>
                        <CompanyTaxpayerAbroad ref={companyTaxpayerAbroad}/>
                        <PubliclyListed ref={publiclyListed}/>
                        <HasParentCompany ref={hasParentCompany}/>
                        <HasSubsidiaries ref={hasSubsidiaries}/>
                        <FinancialInstitution ref={financialInstitution}/>
                        <FundOrigins ref={fundOrigins}/>
                        <HasPaymentsMadeAbroad ref={hasPaymentsMadeAbroad}/>
                        <HasPaymentRecievedAbroad ref={hasPaymentRecievedAbroad}/>
                        <People ref={people}/>
                        <AgreeToUpdate ref={agreeToUpdate}/>

                        <section ref={createDocument} className={styles.buttonRow}>
                            {areAllValid() ||
                                <p className="field-error-message">Alla obligatoriska fält måste fyllas i</p>}
                            {allPersonsAreReady || <p className="field-error-message">Personregistret måste fyllas i</p>}
                            <OldButton
                                id="submitCustomerDeclarationButton"
                                onClick={onSubmitCustomerDeclaration}>
                                Skapa dokumentet
                            </OldButton>
                        </section>
                    </div>
                </div>
            </CustomerDeclarationProvider>
        )
    }
}

export function CustomerDeclarationSE() {
    return (
        <Validate>
            <CustomerDeclaration/>
        </Validate>
    )
}

