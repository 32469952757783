import HelpPopup from '#components/HelpPopup/HelpPopup'
import React from 'react'
import styles from './LabeledInfo.module.scss'

const DEFAULT_SHOW = Symbol('default')

interface LabeledInfoProps {
    id?: string
    label?: string | React.ReactNode
    info?: string | React.ReactNode
    hide?: boolean
    show?: boolean | typeof DEFAULT_SHOW
    children?: React.ReactNode
    helpText?: string
    helpTextLabel?: string
}

const LabeledInfo = ({id, label, info, hide, show = DEFAULT_SHOW, children, helpText, helpTextLabel}: LabeledInfoProps) => {
    if (hide) return null
    if (show !== DEFAULT_SHOW && !show) return null

    if (helpTextLabel) {
        label = <HelpPopup text={helpTextLabel}>{label}</HelpPopup>
    }

    let text = info || children
    if (helpText) {
        text = <HelpPopup text={helpText}>{text}</HelpPopup>
    }

    return (
        <dl className={styles.body}>
            <dt className={styles.label}>{label}</dt>
            <dd {...(id ? { id: id } : {})} className={styles.value}>
                {text}
            </dd>
        </dl>
    )
}

export default LabeledInfo
