import { Paper } from "#app/components";
import FxDialog from "#app/components/FxDialog/FxDialog";
import FxDialogContent from "#app/components/FxDialog/FxDialogContent";
import FxDialogTitle from "#app/components/FxDialog/FxDialogTitle";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";

type Props = {
    children: React.ReactNode
    modal: boolean
    onClose: () => void
}

export default function SignAuthorizationContainer({
    children,
    modal,
    onClose,
}: Props) {
    const { t } = useTranslation();
    const title = t("pages-organizations.signAuthorizationHeading");
    if (modal) {
        return (
            <FxDialog open={true} onClose={onClose}>
                <FxDialogTitle onClose={onClose}>{title}</FxDialogTitle>
                <FxDialogContent>
                    <Box sx={{ mt: 3 }}>{children}</Box>
                </FxDialogContent>
            </FxDialog>
        );
    }
    return (
        <Paper
            title={title}
        >
            {children}
        </Paper>
    );
}
