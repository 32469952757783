import { Fragment, useEffect, useState } from 'react';
import styles from './AdCard.module.scss'
import {useTranslation} from 'react-i18next'
import {CurrencyOutput, InterestOutput} from '#components'
import {useAdCountDownText} from '#app/pages/marketplace/AdCard/AdCountDownText'
import classNames from 'classnames'
import {useSelector} from '#app/state/useSelector'
import {Alert, Box, ButtonBase, Divider, Stack, Tooltip, Typography} from '@mui/material'
import isAfter from 'date-fns/isAfter'
import {AdDto, Product} from '@fixrate/fixrate-query'
import {getMaxVolumeLimit} from '#app/pages/marketplace/DepositMarketplace'
import AdInterestLabel from "#app/components/AdInterestLabel";

const AD_TYPES = {
    NO_TAG: {message: () => '', messageType: 0},
    NEW: {message: (t) => t('pages-marketplace.new'), messageType: 1},
    POPULAR: {message: (t) => t('pages-marketplace.popular'), messageType: 2},
    ALMOST_FULL: {message: (t) => t('pages-marketplace.nearlySoldOut'), messageType: 3},
    SPECIAL: {message: (t) => t('pages-marketplace.specialOffer'), messageType: 4},
}

function getIcon(messageType) {
    switch (messageType) {
        case 4:
            return 'ri-send-plane-2-line'
        case 3:
            return 'ri-time-line'
        case 2:
            return 'ri-star-line'
        case 1:
            return 'ri-flashlight-line'
        default:
            return null
    }
}

type AdDtoProperties = Pick<AdDto, 'openOrders' | 'bankId' | 'remaining' | 'min' | 'max' | 'interest' | 'nominalInterestRate' | 'productId' | 'adTag' | 'depositorId' | 'interestRateBenchmark' | 'validity'>

interface Props {
    ad: AdDtoProperties,
    product: Product,
    onAdClick: () => void | null,
    className?: string
    preview?: boolean
    partnerProposalAd?: boolean
}

export default function AdCard({ad, partnerProposalAd, product, onAdClick = null, className = null, preview}: Props) {
    const {t} = useTranslation()
    const ordered = !!ad.openOrders && ad.openOrders.length > 0
    const [messageType, setMessageType] = useState(0)
    const [message, setMessage] = useState('')

    const bank = useSelector(state => state.banks[ad.bankId])
    const hasDepositInBank = useSelector(state => state.session?.organisationType === 'DEPOSITOR' && state.deposits.filter(d => !d.terminationDate || isAfter(new Date(d.terminationDate), new Date())).some(d => d.bankId === ad.bankId))
    const countDownText = useAdCountDownText(ad)
    const isDisabled = ad.remaining < ad.min

    const cypressTag = (disabled, depositorId) => {
        if (disabled) {
            return 'adCardDisabled'
        }
        if (depositorId) {
            return 'adCardSpecial'
        }

        return 'adCard'
    }

    function getProductName(productId) {
        const fixedProducts = [1, 2, 3, 4, 11, 12]
        const regularProducts = [5, 6, 7, 10]
        const frnProducts = [8, 9]
        return (
            <div className={styles.constraints}>
                <span className={styles.contstraints__title}>
                    {regularProducts.includes(parseInt(productId)) && t('pages-marketplace.noticePeriod')}
                    {fixedProducts.includes(parseInt(productId)) && t('pages-marketplace.duration')}
                    {frnProducts.includes(parseInt(productId)) && t('pages-marketplace.product')}
                </span>
                <span className={styles.constraint__value}>
                    <span>{t(`common.productLongName${productId}`)}</span>
                </span>
            </div>
        )
    }

    useEffect(() => {
        if (ordered) {
            setMessage(t('pages-marketplace.ordered'))
            setMessageType(4)
        } else if (countDownText) {
            setMessageType(3)
            setMessage(countDownText)
        } else {
            setMessage(AD_TYPES[ad.adTag || 'NO_TAG'].message(t))
            setMessageType(AD_TYPES[ad.adTag || 'NO_TAG'].messageType)
        }
    }, [ad, countDownText, ordered, t])


    function onClick() {
        if (preview || onAdClick === null) {
            return
        }
        if ((ad.remaining >= ad.min)) {
            onAdClick()
        }
    }



    return (
        <ButtonBase disabled={isDisabled || preview} color="primary" sx={{textAlign: 'left'}}>
            <Box
                className={classNames(styles.ad, isDisabled ? styles.disabled : '', className, preview ? styles.preview : '', partnerProposalAd ? styles.partnerProposal : '')}
                onClick={onClick}
                data-interest-rate-benchmark={ad.interestRateBenchmark}
                data-producttype={product?.type}
                data-cy={cypressTag(isDisabled, ad.depositorId)}
            >
                <p className={styles.name}>
                    <span className={classNames(styles.bankIcon, hasDepositInBank && styles.checkIconInside)}>
                        {hasDepositInBank && (
                            <Tooltip title="Du har innskudd i denne banken" arrow>
                                <i className={'ri-check-line green'}/>
                            </Tooltip>
                        )}
                        {!hasDepositInBank && <i className={'ri-bank-line'}/>}
                    </span>
                    <span>
                        <span>
                            <span className={styles.bankName}>{(bank?.label || 'Bank')}</span>
                        </span>
                        <span className={styles.capital}>
                            {CurrencyOutput.formatNoCode((bank?.totalAssets ?? 0) / 1000, 1)} {t('pages-marketplace.blnInAssets')}
                        </span>
                    </span>
                </p>
                <div className={styles.interestgroup}>
                    <p className={styles.interest}>{InterestOutput.format(ad.interest)}</p>
                    <AdInterestLabel productType={product?.type} interestRate={ad.nominalInterestRate} interestRateBenchmark={ad.interestRateBenchmark} />
                </div>
                <div className={styles.productInfo}>
                    <div className={styles.constraints}>
                        <Fragment>
                            <span className={styles.contstraints__title}>{t('pages-marketplace.amountLimit')}</span>
                            <span className={styles.constraint__value}>
                                <span>{ad.min}–{getMaxVolumeLimit(ad)} {t('pages-marketplace.mill')}</span>
                            </span>
                        </Fragment>
                    </div>
                    {getProductName(ad.productId)}
                </div>
                {partnerProposalAd && (
                    <p
                        className={classNames(
                            styles.message,
                            styles.message__special
                        )}
                    >
                        <span className={classNames(styles.bl, styles.corneredAngle)}></span>
                        <span className={styles.messageText}>
                            <i className="ri-alarm-warning-line"/>
                            <span className={styles.text}>{t('pages-marketplace.readyForOrder')}</span>
                        </span>
                    </p>
                )}
                {message && !partnerProposalAd && (
                    <p
                        className={classNames(
                            styles.message,
                            messageType === 3 ? styles.message__alert : '',
                            messageType === 1 ? styles.message__new : '',
                            messageType === 4 ? styles.message__special : '',
                        )}
                    >
                        <span className={classNames(styles.bl, styles.corneredAngle)}></span>
                        <span className={styles.messageText}>
                            {getIcon(messageType) && <i className={getIcon(messageType)}/>}
                            <span className={styles.text}>{message}</span>
                        </span>
                    </p>
                )}
            </Box>
            {isDisabled && (
                <Alert
                    severity="warning"
                    icon={<i className="ri-stop-circle-line"/>}
                    sx={{
                        position: 'absolute',
                        bottom: '0',
                        width: '100%',
                        fontSize: '1.2rem',
                        height: '3rem',
                        borderRadius: '0',
                        borderTop: '1px solid rgba(0,0,0,0.05)',
                        '& .MuiAlert-icon': {
                            fontSize: '1.6rem',
                            marginRight: '0.5rem',
                        },
                    }}
                >
                    {t('pages-marketplace.offerSoldOut')}
                </Alert>
            )}
        </ButtonBase>
    )
}
