import {CurrencyOutput, DateOutput, Table, TableCell, TableHeader, TableHeaderCell, TableRow} from '#components'
import {useSelector} from '#state/useSelector'
import DocumentLink from '#components/DocumentLink'
import {FundPlacementTransaction} from '@fixrate/fixrate-query'
import styles from './TransactionTable.module.scss'
import {useTranslation} from 'react-i18next'
import {TransactionTypeBadge} from '#components/TransactionTypeBadge/TransactionTypeBadge'
import {Tooltip} from '@mui/material'
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'

type TransactionRow = FundPlacementTransaction & { depositorName: string, fundName: string, fromExchangeName?: string, toExchangeName?: string }
type Options = {
    hideDepositor?: boolean
    hideFund?: boolean
}

type Props = {
    transactions: TransactionRow[]
    options?: Options
}

export default function TransactionTable({transactions, options}: Props) {
    const {t} = useTranslation()
    const Currency = useCurrencyOutput()

    return (
        <Table>
            <TableHeader>
                <TableHeaderCell>{t('pages-fundTransactions.date')}</TableHeaderCell>
                {!options?.hideFund && <TableHeaderCell>{t('pages-fundTransactions.fund')}</TableHeaderCell>}
                {!options?.hideDepositor && <TableHeaderCell>{t('pages-fundTransactions.depositor')}</TableHeaderCell>}
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.units')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.amount')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.accrual')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.purchaseValue')}</TableHeaderCell>
                <TableHeaderCell align={'right'}>{t('pages-fundTransactions.gainValue')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactions.type')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactions.confirmationSlip')}</TableHeaderCell>
                <TableHeaderCell>{t('pages-fundTransactions.comment')}</TableHeaderCell>
            </TableHeader>
            {transactions.map(tr => (
                <TableRow key={tr.depositorName + tr.fundName + tr.sequenceNumber}>
                    <TableCell>{DateOutput.formatDate(tr.transactionDate)}</TableCell>
                    {!options?.hideFund && (
                        <TableCell className={styles.cappedCell} style={{fontVariant: 'tabular-nums'}}>
                            <Tooltip title={tr.fundName} arrow placement={'bottom-start'}>
                                <span>{tr.fundName}</span>
                            </Tooltip>
                        </TableCell>
                    )}
                    {!options?.hideDepositor && (
                        <TableCell className={styles.cappedCell}>
                            <Tooltip title={tr.depositorName} arrow placement={'bottom-start'}>
                                <span>{tr.depositorName}</span>
                            </Tooltip>
                        </TableCell>
                    )}
                    <TableCell align={'right'}>{CurrencyOutput.formatNoCode(tr.unitQuantity)}</TableCell>
                    <TableCell align={'right'}>{Currency(tr.amount)}</TableCell>
                    <TableCell align={'right'}>
                        {
                            tr.type === 'SELL'
                                ? (tr?.saleAccrualValue && Currency(tr.saleAccrualValue)) || '-'
                                : (tr?.purchaseAccrualValue && Currency(tr.purchaseAccrualValue)) || '-'
                        }
                    </TableCell>
                    <TableCell align={'right'}>{Currency(tr.purchaseValue)}</TableCell>
                    <TableCell align={'right'}>{(tr.type === 'SELL' && Currency(tr.totalReturn)) || '-'}</TableCell>
                    <TableCell>
                        <span className={styles.typeBadge}>
                            <TransactionTypeBadge transactionType={tr.type}/>
                            {tr.fromExchangeName && (
                                <Tooltip title={<span>{tr.fromExchangeName} <i className={'ri-arrow-right-line'} aria-label={'to'}/> {tr.fundName}</span>} arrow>
                                    <i className={'ri-information-line purple'} style={{fontSize: '1.8rem', marginLeft: '0.5rem', fontWeight: 'normal'}}/>
                                </Tooltip>
                            )}
                        </span>
                    </TableCell>
                    <TableCell><DocumentCell label={t('common.download')} documentId={tr.documentId}/></TableCell>
                    <TableCell className={styles.cappedCell}>
                        {tr.commentForDepositor ?
                            (
                                <Tooltip title={tr.commentForDepositor} arrow placement={'bottom-start'}>
                                    <span>{tr.commentForDepositor}</span>
                                </Tooltip>
                            ) : (
                                <span>-</span>
                            )
                        }
                    </TableCell>
                </TableRow>
            ))}
        </Table>
    )
}

function DocumentCell({label, documentId}: { label: string, documentId: string }) {
    const document = useSelector(state => state.documents[documentId])
    return document ? <DocumentLink link={document.link} name={label}/> : <span>-</span>
}
