import {PageHeader, PageLayout, Paper} from '#components';
import AsyncButton from "#components/Button/AsyncButton";
import Pagination from '#components/Pagination/Pagination';
import ReportDownloadExcel from "#components/ReportDownloadExcel/ReportDownloadExcel";
import TransactionTable from '#pages/portfolio-depositor/FundTransactions/TransactionTable';
import {useSelector} from '#state/useSelector';
import {compareDesc} from "date-fns";
import {Fragment, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from "react-router-dom";
import styles from './FundTransactions.module.scss';
import {InputAdornment, MenuItem, Select, Stack, TextField, Typography} from "@mui/material";
import {FundBasicInfoDto} from "@fixrate/fixrate-query";
import {useQuery} from "react-query";

const ITEMS_PER_PAGE = 20
const DEPOSITOR_ID = 'depositorId'
const FUND_ID = 'fundId'
const CONFIRMATION_SLIP_NUMBER = 'confirmationSlipNumber'
export default function FundTransactions() {
    const {t} = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams();
    const depositorId = searchParams.has(DEPOSITOR_ID) ? searchParams.get(DEPOSITOR_ID) : ''
    const fundId = searchParams.has(FUND_ID) ? searchParams.get(FUND_ID) : ''
    const confirmationSlipNumber = searchParams.has(CONFIRMATION_SLIP_NUMBER) ? searchParams.get(CONFIRMATION_SLIP_NUMBER) : ''

    const [page, setPage] = useState(0)
    const [depositorFilter, setDepositorFilter] = useState(depositorId)
    const [fundFilter, setFundFilter] = useState(fundId)
    const [confirmationSlipNumberFilter, setConfirmationSlipNumberFilter] = useState(confirmationSlipNumber)

    const fundPlacements = useSelector(state => state.fundPlacements.filter(fp => fp.transactions.length > 0))
    const depositorNames: {string: string} = useSelector(state => Object.assign({}, ...Object.values(state.depositors).map(d => ({[d.id]: d.name}))))
    const fundsInPlacements = fundPlacements.map(f => f.fundId)
    const confirmationSlips = useSelector(state => state.depositor?.confirmationSlips)
    const confirmationSlip = confirmationSlips?.find(cs => cs.serialNumber === parseInt(confirmationSlipNumberFilter))
    const fundNames: {string: string} = useSelector(state => Object.assign({}, ...state.funds.filter(f => fundsInPlacements.includes(f.id))
        .map(f => ({[f.id]: `${f.name}`}))))
    const [shareClasses, setShareClasses] = useState([] as FundBasicInfoDto[])
    // We fetch share class info here instead of from redux because some share classes may not be available for purchase in the marketplace, but are still used in imported transactions.
    const {isSuccess} = useQuery({
        queryFn: async () => {
            const data = await fetch('/api/fund', {credentials: 'include'})
            return await data.json() as FundBasicInfoDto[]
        },
        onSuccess: (data) => {
            setShareClasses(data)
        }
    })
    if (!isSuccess || shareClasses.length === 0) {
        return null
    }

    const showDepositorFilter = Object.keys(depositorNames).length > 1

    const filteredFundPlacements = fundPlacements
        .filter(fp => !depositorFilter || fp.depositorId === depositorFilter)
        .filter(fp => !fundFilter || Object.values(fp.fundHistory).includes(fundFilter))

    const transactions = filteredFundPlacements
        .flatMap(fp => {
            const shareClassHistory = fp.fundShareClassHistory
            return fp.transactions.map(t => {
                const shareClassId = t.fundShareClassId ?? getShareClassIdForDate(shareClassHistory, t.transactionDate) ?? fp.fundShareClassId
                const shareClassName = shareClasses.find(sc => sc.fundShareClassId === shareClassId)?.name
                if (t.exchangeFromShareClassId != null) {
                    const fromShareClassName = shareClasses.find(sc => sc.fundShareClassId === t.exchangeFromShareClassId)?.name
                    return {...t, fundName: shareClassName, depositorName: depositorNames[fp.depositorId], fromExchangeName: fromShareClassName}
                }
                return {...t, fundName: shareClassName, depositorName: depositorNames[fp.depositorId]}
            })
        })
        .sort((a, b) => {
            if (a.transactionDate > b.transactionDate) return -1
            if (a.transactionDate < b.transactionDate) return 1
            return b.sequenceNumber - a.sequenceNumber
        })

    function getSelectedFundShareClassId() {
        if (fundFilter !== '') {
            return fundPlacements.find(fp => fp.fundId === fundFilter)?.fundShareClassId
        }
        return ''
    }

    const filteredTransactions = transactions
        .filter(tr => !confirmationSlipNumberFilter || tr.id === confirmationSlip?.transactionId)

    const visibleTransactions = filteredTransactions.slice(page * ITEMS_PER_PAGE, (page + 1) * ITEMS_PER_PAGE)

    function getShareClassIdForDate(fundShareClassHistory, inputDateString) {
        const inputDate = new Date(inputDateString)
        const dates = Object.keys(fundShareClassHistory)
            .filter(date => {
                return new Date(date) <= inputDate
            })
            .sort((a, b) => {
                return compareDesc(new Date(a), new Date(b))
            });

        if (dates.length === 0) {
            return null
        }
        const closestDate = dates[0]
        return fundShareClassHistory[closestDate];
    }

    function updateDepositorFilter(id: string) {
        const params = new URLSearchParams(searchParams)
        if (id) {
            params.set(DEPOSITOR_ID, id)
        } else {
            params.delete(DEPOSITOR_ID)
        }
        setPage(0)
        setDepositorFilter(id)
        setSearchParams(params)
    }

    function updateFundFilter(id: string) {
        const params = new URLSearchParams(searchParams)
        if (id) {
            params.set(FUND_ID, id)
        } else {
            params.delete(FUND_ID)
        }
        setPage(0)
        setFundFilter(id)
        setSearchParams(params)
    }

    function updateConfirmationSlipNumberFilter(num: string) {
        const params = new URLSearchParams(searchParams)
        if (num) {
            params.set(CONFIRMATION_SLIP_NUMBER, num)
        } else {
            params.delete(CONFIRMATION_SLIP_NUMBER)
        }
        setPage(0)
        setConfirmationSlipNumberFilter(num)
        setSearchParams(params)
    }

    function resetFilters() {
        const params = new URLSearchParams(searchParams)
        params.delete(DEPOSITOR_ID)
        params.delete(CONFIRMATION_SLIP_NUMBER)
        setPage(0)
        setDepositorFilter('')
        setFundFilter('')
        setConfirmationSlipNumberFilter('')
        setSearchParams(params)
    }

    return (
        <Fragment>
            <PageHeader title={t('pages-fundTransactions.header')}/>
            <PageLayout>
                <Paper title={t('pages-fundTransactions.title')} sx={{width: "100%"}}>
                    <Stack direction="row" justifyContent="space-between" marginBottom={2}>
                        <Stack direction="row" spacing={2} alignItems="baseline">
                            <p className={styles.toolbarLabel}>
                                <i className="ri-filter-line"/>
                                <span>{t('pages-fundTransactions.filter')}</span>
                            </p>
                            <Select displayEmpty
                                    value={fundFilter}
                                    onChange={e => updateFundFilter(e.target.value)}>
                                <MenuItem value={''}>
                                    <Typography noWrap>{t('pages-fundTransactions.allFunds')}</Typography>
                                </MenuItem>
                                {Object.keys(fundNames).sort((a, b) => fundNames[a].localeCompare(fundNames[b])).map(id => (
                                    <MenuItem key={id} value={id}>
                                        <Typography noWrap>{fundNames[id]}</Typography>
                                    </MenuItem>
                                ))}
                            </Select>
                            {showDepositorFilter && (
                                <Select displayEmpty
                                        value={depositorFilter}
                                        onChange={e => updateDepositorFilter(e.target.value)}>
                                    <MenuItem value={''}>
                                        <Typography noWrap>{t('pages-fundTransactions.allDepositors')}</Typography>
                                    </MenuItem>
                                    {Object.keys(depositorNames).sort((a, b) => depositorNames[a].localeCompare(depositorNames[b])).map(id => (
                                        <MenuItem key={id} value={id}>
                                            <Typography noWrap>{depositorNames[id]}</Typography>
                                        </MenuItem>
                                    ))}
                                </Select>
                            )}
                            <TextField placeholder={t('pages-fundTransactions.confirmationSlipNumber')}
                                       size="small"
                                       value={confirmationSlipNumberFilter}
                                       onChange={e => updateConfirmationSlipNumberFilter(e.target.value)}
                                       InputProps={{
                                           startAdornment: (
                                               <InputAdornment position="start">
                                                   <i className="ri-search-line"/>
                                               </InputAdornment>
                                           )
                                       }}/>
                            {(fundFilter || (showDepositorFilter && depositorFilter) || confirmationSlipNumberFilter) && (
                                <AsyncButton variant="contained" size="small" onClick={() => resetFilters()}>
                                    <i className="ri-restart-line" style={{marginRight: '0.4rem'}}/>
                                    <span>{t('pages-fundTransactions.resetFilter')}</span>
                                </AsyncButton>
                            )}
                        </Stack>
                        {filteredTransactions.length !== 0 && !confirmationSlipNumberFilter && (
                            <ReportDownloadExcel report="DEPOSITOR_FUND_TRANSACTIONS_EXPORT" additionalParams={new URLSearchParams({fundShareClassId: getSelectedFundShareClassId()})}/>
                        )}
                    </Stack>
                    <TransactionTable transactions={visibleTransactions} options={{hideDepositor: !showDepositorFilter || !!depositorFilter, hideFund: !!fundFilter}}/>
                    {visibleTransactions.length === 0 && (
                        <p className={styles.emptyMessage}>{(!!depositorFilter || !!fundFilter || !!confirmationSlipNumberFilter) ? t('pages-fundTransactions.noTransactionsMatchingFilter') : t('pages-fundTransactions.noTransactions')}</p>
                    )}
                    {transactions.length > ITEMS_PER_PAGE && (
                        <div style={{marginTop: '2rem'}}>
                            <Pagination totalItems={transactions.length} itemsPerPage={ITEMS_PER_PAGE} selectedPage={page} setPage={setPage}/>
                        </div>
                    )}
                </Paper>
            </PageLayout>
        </Fragment>
    );
}
