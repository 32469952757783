import { BURNT_ORANGE, PURPLE, SUCCESS_GREEN } from '#app/colors/colors'
import { InterestOutput, LinkButton, PageHeader, PageLayout } from '#app/components'
import SuccessIcon from '#app/layers/ConfirmationModal/assets/SuccessIcon.svg?url'
import IdentificationDocument from '#app/pages/profile/IdentificationDocument'
import { useSelector } from '#app/state/useSelector'
import { OnboardingWizardStatus, useOnboardingWizardStatus } from '#app/utilities/useOnboardingWizardStatus'
import Confetti from '#components/Confetti/Confetti'
import useUiSetting from "#services/useUiSetting"
import * as selectors from '#state/selectors'
import { DepositorDto, DocumentDto } from '@fixrate/fixrate-query'
import { ArrowForward } from '@mui/icons-material'
import { Alert, Button, Dialog, DialogContent, Divider, Drawer, Grid, LinearProgress, List, ListItemButton, ListItemIcon, ListItemText, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material'
import { Fragment, ReactElement, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import Accounts from '../../OrganizationDetail/Accounts/Accounts'
import NewAccount from '../../OrganizationDetail/Accounts/NewAccount'
import CustomerDeclarationInfo from '../../OrganizationDetail/CustomerDeclarationInfo/CustomerDeclarationInfo'
import AcceptTerms from '../../OrganizationDetail/TermsAndConditions/AcceptTerms'
import Board from '../../OrganizationDetail/UsersAndRoles/views/Board'
import OrderProcess from '../../OrganizationDetail/UsersAndRoles/views/OrderProcess'
import Owners from '../../OrganizationDetail/UsersAndRoles/views/Owners'
import POAList from '../../OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/POAList'
import POASigners from '../../OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/POASigners'
import BeforeRegistration from './BeforeRegistration'
import ConfettiIllustration from './Confetti.svg?url'
import BankIcon from "./icons/bank.svg"
import BusinessIcon from "./icons/business.svg"
import CoinsIcon from "./icons/coins.svg"
import VerifiedDocumentIcon from "./icons/document-verified.svg"
import DoughnutPercentage from './icons/doughnut-percentage.svg'
import HandshakeIcon from "./icons/handshake.svg"
import InviteIcon from "./icons/invitations.svg"
import RolesIcon from "./icons/roles.svg"
import UserVerifiedIcon from "./icons/user-verified.svg"
import UserZoomIcon from "./icons/user-zoom.svg"
import styles from './OrganizationWizard.module.scss'
import Philip from './philip-portrait.jpg'
import InvitationsStep from './steps/InvitationsStep'
import MissingInformationStep from './steps/MissingInformationStep'
import StatusStep from './steps/StatusStep'
import WizardContent from './WizardContent'
import Tada from './illustrations/tada.svg'
import { useDispatch } from 'react-redux'
import { switchOrganisation } from '#app/services/thunks/session'
import { userIsAccountCreator } from '#app/utilities/accountCreatorUtils'

type StepName = 'start' | 'terms' | 'identificationDocument' | 'board' | 'accounts' | 'customerDeclaration' | 'owners' | 'orderProcess' | 'customerDeclarationInfo' | 'signingRights' | 'powerOfAttorney' | 'invitations' | 'status' | 'partnerSendOffer'

export type WizardStep = {
    name: StepName
    done: boolean
    visible: boolean
    content: ReactElement | null
    icon: string | null
}

function GET_STEPS(
        {
            depositor,
            identificationDocument,
            userId,
            wizardStatus
        } :
        {
            depositor: DepositorDto | undefined,
            identificationDocument: DocumentDto | undefined,
            userId: string | undefined,
            wizardStatus: OnboardingWizardStatus
        }
    ): WizardStep[] {

    const status = wizardStatus
    const isSelfServiced = depositor?.depositorSupportCategory === 'SELF_SERVICED'
    const isPartnerAccountant = depositor?.users.find(u => u.id === userId)?.roles.find(r => r === 'DEPOSITOR_PARTNER_ACCOUNTANT')

    return [
        {
            content: null,
            name: 'start',
            done: true,
            visible: true,
            icon: null
        },
        {
            content: <AcceptTerms inline />,
            name: 'terms',
            done: status?.status['terms'],
            visible: true,
            icon: HandshakeIcon
        },
        {
            content: <Board inline={true} depositor={depositor}/>,
            name: 'board',
            done: status?.status['board'],
            visible: true,
            icon: RolesIcon
        },
        {
            content: <OrderProcess depositor={depositor} visibleItems={['orderProcess']} inline/>,
            name: 'orderProcess',
            done: status?.status['orderProcess'],
            visible: !isSelfServiced,
            icon: CoinsIcon
        },
        {
            content: <IdentificationDocument signContext={'/organizations/onboarding/' + depositor?.id + '/identificationDocument'} inline showSign={identificationDocument ? true : false} showAddress={ identificationDocument ? false : true}/>,
            name: 'identificationDocument',
            done: status?.status['identificationDocument'],
            visible: userIsAccountCreator(depositor.users.find(u => u.id === userId)),
            icon: UserVerifiedIcon
        },
        {
            content: <Owners inline={true} depositor={depositor}/>,
            name: 'owners',
            done: status?.status['owners'],
            visible: !isSelfServiced,
            icon: BusinessIcon
        },
        {
            content: <POASigners depositor={depositor} inline/>,
            name: 'signingRights',
            done: status?.status['signingRights'],
            visible: !isSelfServiced,
            icon: BusinessIcon
        },
        {
            content: <POAList depositor={depositor} inline hideWarnings={true}/>,
            name: 'powerOfAttorney',
            done: status?.status['powerOfAttorney'],
            visible: !isSelfServiced,
            icon: BusinessIcon
        },
        {
            content: depositor.settlementAccounts.length < 1 ? <NewAccount inline={true} depositorId={depositor.id} /> : <Accounts inline={true} allowMultipleAccounts={false}/>,
            name: 'accounts',
            done: status?.status['accounts'],
            visible: true,
            icon: BankIcon
        },
        {
            content: <MissingInformationStep depositor={depositor} />,
            name: 'customerDeclarationInfo',
            done: status?.status['customerDeclarationInfo'],
            visible: true,
            icon: UserZoomIcon
        },
        {
            content: <CustomerDeclarationInfo inline/>,
            name: 'customerDeclaration',
            done: status?.status['customerDeclaration'],
            visible: true,
            icon: VerifiedDocumentIcon
        },
        {
            content: <InvitationsStep depositor={depositor}/>,
            name: 'invitations',
            done: status?.status['invitations'],
            visible: true && !isPartnerAccountant,
            icon: InviteIcon
        },
        {
            content: <StatusStep depositor={depositor}/>,
            name: 'status',
            done: status?.status['status'],
            visible: true && !isPartnerAccountant,
            icon: DoughnutPercentage
        },
        {
            content: null,
            name: 'partnerSendOffer',
            done: false,
            visible: !!isPartnerAccountant,
            icon: DoughnutPercentage
        },
    ]
}

type Params = {
    step?: StepName | string
}

export const OrganizationWizard = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const {step} = useParams<Params>()
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down('md'))
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'))
    const partnerId = useSelector(state => state.session.associations?.find(a => a.organisationType === 'PARTNER')?.organisation?.id)

    const [visibleErrorSteps, setVisibleErrorSteps] = useState<Array<number>>([])
    const [weHelpYouModal, setWeHelpYouModal] = useState(false)

    const [lastSavedProgress, setLastSavedProgress] = useState(0)
    const [showSuccessDialog, setShowSuccessDialog] = useState(false)
    const [hideSuccessModalForEver, setHideSuccessModalForEver] = useUiSetting('hideSuccessModalForEver', false)

    const deposits = useSelector(state => state.deposits)
    const selectedDepositor = useSelector(state => state.depositor)
    const orders = useSelector(state => state.orders)
    const userId = useSelector(state => state.session?.id)
    const [finishedSteps, setFinishedSteps] = useUiSetting('onboardingWizardStep', selectedDepositor ? {[selectedDepositor.id]: 0} : {})
    const finishedStepForDepositor = finishedSteps[selectedDepositor?.id] ?? 0

    const onboardingWizardStatus = useOnboardingWizardStatus(selectedDepositor)

    const identificationDocument = useSelector(selectors.identificationDocument)
    const steps = GET_STEPS({
        depositor: selectedDepositor,
        identificationDocument: identificationDocument,
        userId: userId,
        wizardStatus: onboardingWizardStatus
    }).filter(step => step.visible).map((step, index) => ({...step, index}))
    const initialStep = steps.find(s => s.name === step)
    const [currentStepIndex, setCurrentStepIndex] = useState(initialStep?.index ?? 1)
    const currentStep = steps.find(s => s.index === currentStepIndex) ?? steps.at(1)
    const currentProgress = onboardingWizardStatus.progress
    const isPartnerAccountant = selectedDepositor?.users.find(u => u.id === userId)?.roles.find(r => r === 'DEPOSITOR_PARTNER_ACCOUNTANT')

    useEffect(() => {
        if (currentProgress === 100 && lastSavedProgress !== 100 && lastSavedProgress !== 0 && !partnerId) {
            setShowSuccessDialog(!hideSuccessModalForEver)
            setHideSuccessModalForEver(true)
        } else if (lastSavedProgress === 100) {
            setHideSuccessModalForEver(true)
        }
        setLastSavedProgress(currentProgress)
    }, [currentProgress, hideSuccessModalForEver, lastSavedProgress, setHideSuccessModalForEver])

    function changeStep(index: number) {
        setCurrentStepIndex(index)
        if (index > finishedStepForDepositor) {
            setFinishedSteps({...finishedSteps, [selectedDepositor?.id]: index})
        }
        navigate('/organizations/onboarding/' + selectedDepositor.id + '/' + steps.find(s => s.index === index)?.name)
    }
    
    function navigateToPartner(url: string) {
        dispatch(switchOrganisation('PARTNER', partnerId, () => navigate(url)))
    }

    function goToNext(index: number) {
        if (currentStep.done !== true) {
            if (!visibleErrorSteps.includes(currentStepIndex)) {
                setVisibleErrorSteps(prev => [...prev, currentStepIndex])
            }
        } else {
            changeStep(index)
        }
    }

    function stepIsDisabled(index: number) {
        if (steps[index].name === "customerDeclaration" && steps.find(s => s.name === "customerDeclarationInfo")?.done !== true) {
            return true
        }

        if (finishedStepForDepositor > index - 1) {
            return false
        }

        if (currentStep.done === true && index - 1 === currentStep.index) {
            return false
        }

        if (finishedStepForDepositor < index - 1) {
            return true
        }

        if (steps[index - 1].done === true) {
            return false
        }

        return true
    }

    if (!selectedDepositor) {
        return null
    }

    return (
        <>
            {showSuccessDialog && <Confetti/>}
            <PageHeader icon={'ri-building-line'} title={t('pages-OrganizationWizard.getStarted')}></PageHeader>
            <PageLayout 
                noStyles={isTablet ? true : false}
                sx={{
                    height: {xs: "calc(100% - 11.5rem)", md: "100%"},
                    py: {xs: 0, md: 2, lg: 4.8},
                    px: {xs: 0, md: 3, lg: 6.4},
                    width: {xs: "100%", lg: "calc(100% - 40rem)"}
                }}>
                { selectedDepositor.depositorSupportCategory === 'UNKNOWN' ? (
                    <BeforeRegistration/>
                ) : (
                    <>
                        <Dialog
                            maxWidth={'xs'}
                            fullWidth={true}
                            onClose={() => setShowSuccessDialog(false)}
                            open={showSuccessDialog}
                        >
                            <DialogContent
                                sx={{
                                    backgroundImage: 'url(' + ConfettiIllustration + '), url(' + SuccessIcon + ')',
                                    backgroundRepeat: 'no-repeat',
                                    backgroundPosition: 'center -3rem, center 7rem',
                                    padding: '5rem 4rem',
                                    textAlign: 'center',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    justifyContent: 'center',
                                }}
                            >
                                <div className={styles.successModalContent}>
                                    <h1>{t('pages-OrganizationWizard.registrationComplete')}</h1>
                                    <p>{t('pages-OrganizationWizard.readyForFirstOrder')}</p>
                                </div>
                                <LinkButton data-cy="goToMarketplaceButton" to={'/marketplace'}>{t('pages-OrganizationWizard.goToMarketplace')}</LinkButton>
                                <Button onClick={() => setShowSuccessDialog(false)} sx={{marginTop: '1rem'}} fullWidth={true} variant={'text'}>{t('pages-OrganizationWizard.close')}</Button>
                            </DialogContent>
                        </Dialog>
                        <Dialog open={weHelpYouModal} maxWidth={'xs'} fullScreen={isTablet}>
                            <img src={Philip} style={{maxWidth: '100%'}} alt={''}/>
                            <DialogContent
                                sx={{
                                    marginTop: '1rem',
                                    marginBottom: '1rem',
                                    padding: {lg: '4rem'},
                                    textAlign: 'center',
                                }}
                            >
                                <h1>{t('pages-OrganizationWizard.happyToHelp')}</h1>
                                <p className="gray">{t('pages-OrganizationWizard.helpDescription')}</p>
                                <p className={styles.contactLine}>
                                <span>
                                    <i className="ri-mail-line"/>
                                    <span>post@fixrate.no</span>
                                </span>
                                    <span>
                                    <i className="ri-phone-line"/>
                                    <span>+47 412 51 918</span>
                                </span>
                                </p>
                                <Button data-cy="continueToRegistrationButton" fullWidth={true} variant={'contained'} onClick={() => window.location.reload()}>{t('pages-OrganizationWizard.continueToRegistration')}</Button>
                            </DialogContent>
                        </Dialog>
                        <Grid maxWidth={"100%"} height={"100%"} container spacing={{xs: 0, lg: 2}}>
                        <Drawer
                            variant={isTablet ? "temporary" : "permanent"}
                            anchor="right"
                            open={false}
                            sx={{
                                "& .MuiDrawer-paper": {
                                    top: {
                                        xs: "0",
                                        lg: "5.2rem"
                                    },
                                    right: "0",
                                    height: {
                                        xs: "100%",
                                        lg: "calc(100% - 5rem)"
                                    },
                                    width: "40rem"
                                }
                            }}>
                            <Stack>
                                <Divider />
                                <Stack p={3}>
                                    <Typography variant="h3">{t('pages-OrganizationWizard.completeRegistration')}</Typography>
                                    <Stack mt={1}>
                                        <Typography variant="caption" fontWeight="700" color={PURPLE[500]}>{InterestOutput.formatWithDecimals(currentProgress, 0)} {t('pages-OrganizationWizard.statusFinished')}</Typography>
                                        <LinearProgress sx={{width: '100%', marginTop: '0.6rem', height: "0.8rem", borderRadius: "10rem"}} variant={'determinate'} value={currentProgress}/>
                                    </Stack>
                                </Stack>
                                <Divider />
                                <List sx={{pt: 0}}>
                                    { steps.filter(s => s.visible).filter(s => s.index !== 0).map(step => (
                                        <Fragment key={step.index}>
                                            <ListItemButton
                                                sx={{px: 3, py: 2, mt: 0}}
                                                disabled={stepIsDisabled(step.index)}
                                                onClick={() => changeStep(step.index)}
                                                selected={currentStepIndex === step.index}>
                                                <ListItemIcon
                                                    sx={{
                                                        minHeight: "2.8rem",
                                                        minWidth: "2.8rem",
                                                        backgroundColor: (finishedStepForDepositor >= step.index && step.done) ? SUCCESS_GREEN[500] : step.index === currentStepIndex ? PURPLE[500] : finishedStepForDepositor > step.index && !step.done ? BURNT_ORANGE[500] : PURPLE[50]
                                                    }}>
                                                    {(finishedStepForDepositor >= step.index && step.done) ? <i className="ri-check-line" style={{color: "white"}}/> : (finishedStepForDepositor > step.index && !step.done && step.index !== currentStepIndex) ? <i className="ri-information-line" style={{color: "white"}}/> : <Typography color={step.index === currentStepIndex ? "white" : PURPLE[900]} fontWeight={700}>{step.index}</Typography>}
                                                </ListItemIcon>
                                                <ListItemText sx={{"& .MuiListItemText-primary": { fontSize: "1.4rem" }}}>
                                                    {t('pages-OrganizationWizard.' + step.name)}
                                                </ListItemText>
                                            </ListItemButton>
                                            <Divider component={"li"}/>
                                        </Fragment>
                                    ))}
                                </List>
                            </Stack>
                        </Drawer>
                            {currentStepIndex !== 0 && (
                                <Grid item maxWidth={'100%'} width="100%" height="100%">
                                    {(currentProgress === 100 && deposits.length === 0 && orders.length === 0) && (
                                        <Alert
                                            sx={{width: '100%', marginBottom: '2rem', boxShadow: '1px 1px 2px 0 rgba(0, 0, 0, 0.2)'}} severity="success"
                                            action={
                                                <Button
                                                    onClick={() => isPartnerAccountant ? navigateToPartner("/marketplace/category/deposits/under-20") : navigate('/marketplace')}
                                                    color="inherit"
                                                    size="small"
                                                    variant={'outlined'}
                                                    endIcon={<ArrowForward/>}>
                                                    {isPartnerAccountant ? t('pages-OrganizationWizard.findOffers') : t('pages-OrganizationWizard.goToMarketplace')}
                                                </Button>
                                            }
                                        >
                                            {isPartnerAccountant ? t('pages-OrganizationWizard.accountantReadyMessage') : t('pages-OrganizationWizard.readyForFirstOrder')}
                                        </Alert>
                                    )}
                                    { currentStep?.name === "partnerSendOffer" ? (
                                        <Paper>
                                            <Stack py={6} alignItems="center" justifyContent="center" spacing={3}>
                                                <img src={Tada} alt="" style={{maxWidth: "20rem"}}/>
                                                <Stack alignItems="center" justifyContent="center" spacing={1}>
                                                    <Typography variant="h1">{t('pages-OrganizationWizard.allDone')}!</Typography>
                                                    <Typography>{t('pages-OrganizationWizard.accountantReadyMessage')}</Typography>
                                                </Stack>
                                                <Button variant="contained" onClick={() => navigateToPartner("/marketplace/category/deposits/under-20")}>
                                                    {t('pages-OrganizationWizard.findOffers')}
                                                </Button>
                                            </Stack>
                                        </Paper>
                                    ) : (
                                        <WizardContent
                                            step={currentStep}
                                            visibleError={visibleErrorSteps.includes(currentStepIndex) && !currentStep.done}
                                            hideVisibleError={() => setVisibleErrorSteps(prev => prev.filter(i => i !== currentStepIndex))}
                                        />
                                    )}
                                    <Stack direction="row" 
                                        mt={{
                                            xs: 0,
                                            md: 3
                                        }} 
                                        sx={{
                                            borderTop: {
                                                xs: "0.1rem solid rgba(0,0,0,0.1)",
                                                md: "none",
                                            },
                                            position: {
                                                xs: "fixed",
                                                md: "relative"
                                            },
                                            bottom: {
                                                xs: 0,
                                            },
                                            py: {
                                                xs: 1.4,
                                                md: 0
                                            },
                                            px: {
                                                xs: 2,
                                                md: 0
                                            },
                                            width: "100%",
                                            backgroundColor: { xs: "white", md: "transparent" },
                                        }}>
                                        {currentStepIndex !== 1 && (
                                            <Button
                                                data-cy="previous-step-button"
                                                sx={{fontSize: isMobile ? '1.4rem' : null}}
                                                startIcon={<i className="ri-arrow-left-line"/>}
                                                variant={'outlined'}
                                                size={isMobile ? 'small' : 'medium'}
                                                onClick={() => changeStep(Math.max(1, currentStepIndex - 1))}
                                            >
                                                {t('pages-OrganizationWizard.previousStep')}
                                            </Button>
                                        )}
                                        {currentStepIndex !== steps.length - 1 && (
                                            <Button
                                                data-cy="next-step-button"
                                                endIcon={<i className="ri-arrow-right-line"/>}
                                                sx={{marginLeft: 'auto', fontSize: isMobile ? '1.4rem' : null}}
                                                variant={'contained'}
                                                size={isMobile ? 'small' : 'medium'}
                                                onClick={() => goToNext(currentStepIndex + 1)}
                                            >
                                                {t('pages-OrganizationWizard.nextStep')}
                                            </Button>
                                        )}
                                        {(currentStepIndex === steps.length - 1) && !isPartnerAccountant && (
                                            <Button 
                                                disabled={currentProgress !== 100}
                                                endIcon={<i className="ri-arrow-right-line"/>}
                                                sx={{marginLeft: 'auto', fontSize: isMobile ? '1.4rem' : null}}
                                                variant={'contained'}
                                                size={isTablet ? 'small' : 'medium'}
                                                onClick={() => navigate('/marketplace')}>
                                                {t('pages-OrganizationWizard.goToMarketplace')}
                                            </Button>
                                        )}
                                    </Stack>
                                </Grid>
                            )}
                        </Grid>
                    </>
                )}
            </PageLayout>
        </>
    )
}
