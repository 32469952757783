import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput'
import { getIconText } from '#app/components/PageHeader/PageHeader'
import { switchOrganisation } from '#app/services/thunks/session'
import useCurrentCountryCode from '#app/services/useCurrentCountryCode'
import LabeledInfo from '#components/LabeledInfo/LabeledInfo'
import { formatOrganizationNumber } from '#services/formatnumber'
import { useSelector } from '#state/useSelector'
import { OrganisationType } from '@fixrate/fixrate-query'
import { Alert, Avatar, Button, Divider, Paper, Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styles from './CustomerInfo.module.scss'
import { InterestBadge } from "#components/InterestBadge/InterestBadge";
import { isFinishedWithOnboarding } from '../CustomerOverview/CustomerOverview'


type Params = {
    depositorId?: string
}

export default function CustomerDetailInfo() {
    const {t} = useTranslation()
    const params = useParams<Params>()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const Currency = useCurrencyOutput()
    const organisationCountry = useCurrentCountryCode()
    const partner = useSelector(state => state.partner)
    const session = useSelector(state => state.session)
    const customer = useSelector((state => state.partner?.customers.find(c => c.depositorId === params.depositorId)))
    const canAccessCustomerAccount = session.associations.find(a => a.organisation?.id === params.depositorId)

    const finishedWithOnboarding = isFinishedWithOnboarding(customer)
    const hasPendingPartnerProposals = customer?.partnerRelations?.find(pr => pr.partnerId === partner.id)?.partnerProposals?.some(pp => pp.status === 'PENDING') || false

    function selectRole(organisationType: OrganisationType, orgId: string) {
        dispatch(switchOrganisation(organisationType, orgId, () => navigate('/organizations')))
    }

    if (!customer) {
        return null
    }

    return (
        <Paper sx={{px: 4, py: 3}}>
            {customer.accepted == null && (
                <Alert icon={<i className="ri-mail-line"/>} severity="info" sx={{mb: 2}}>
                    {t('pages-customers.hasNotYetAccepted')}
                </Alert>
            )}
            {customer.accepted === false && (
                <Alert icon={<i className="ri-close-line"/>} severity="error" sx={{mb: 2}}>
                    {t('pages-customers.declinedRequest')}
                </Alert>
            )}
            <Stack spacing={2} divider={<Divider/>}>
                <Stack direction={{xs: 'column-reverse', md: 'row'}} spacing={2} justifyContent="space-between" alignItems="flex-start">
                    <Stack spacing={2} direction={'row'} alignItems="flex-start">
                        <Avatar sx={{width: '8rem', height: '8rem', fontSize: '2.6rem'}}>{getIconText(customer.name)}</Avatar>
                        <Stack direction={'column'} alignItems={'flex-start'}>
                            <h3 className={styles.customerName}>{customer.name}</h3>
                            <p className={styles.orgNumber}>
                                <i className="ri-building-line"/>
                                <span>{formatOrganizationNumber(customer.nationalIdentity, organisationCountry)}</span>
                            </p>
                        </Stack>
                    </Stack>
                    <Stack direction={{xs: 'row-reverse', md: 'column'}} spacing={2} alignItems="flex-end">
                        {customer.accepted && (
                            <Button data-cy="logInAsCustomerButton" disabled={!canAccessCustomerAccount} endIcon={<i className="ri-arrow-right-line"/>} variant="contained" size="small" onClick={() => selectRole('DEPOSITOR', customer.depositorId)}>
                                {t('pages-customers.logInAsCustomer')}
                            </Button>
                        )}
                        { finishedWithOnboarding && (
                            (customer.warnings.length > 0 && !hasPendingPartnerProposals) ? (
                                <Alert
                                    severity={'error'}
                                    sx={{'& .MuiAlert-icon': {mr: "0.5rem", fontSize: "1.8rem"}, py: 0, px: 1}}>{t('pages-customers.numberOfWarnings', {count: customer.warnings.length})}</Alert>
                            ) : (
                                <Alert
                                    severity={'success'}
                                    sx={{'& .MuiAlert-icon': {mr: "0.5rem", fontSize: "1.8rem"}, py: 0, px: 1}}>{t('pages-customers.allGood')}</Alert>
                            )
                        )}
                    </Stack>
                </Stack>
                {customer.accepted && customer.portfolios.map(portfolio => {
                    const balance = portfolio.calculatedTotalBalance || portfolio.calculatedBalance
                    const interestRate = portfolio.calculatedTotalInterestRate || portfolio.calculatedInterestRate
                    const fundValue = portfolio.fundPlacements.map(placement => placement.currentValue).reduce((acc, value) => acc + value, 0)
                    return (
                    <Stack key={portfolio.portfolioId} direction={'column'}>
                        {customer.portfolios.length > 1 && <Typography variant={'h6'} pb={0}>{portfolio.currency}</Typography>}
                        <Stack direction="row" flexWrap={'wrap'} alignItems="center" justifyContent={'flex-start'} spacing={{xs: 1, md: 4}}>
                            <Stack spacing={0.5} maxWidth={'30rem'} minWidth={'25rem'}>
                                <p className={styles.customerKeyPointLabel}>{t('pages-customers.calculatedBalance')}</p>
                                {balance && <p className={styles.customerKeyPointValue}>{Currency(balance || 0, {withCurrency: customer.portfolios.length === 1})}</p>}
                                {!balance && <p className={styles.customerKeyPointEmptyValue}>{t('pages-customers.noDeposits')}</p>}
                            </Stack>
                            <Stack spacing={0.5} maxWidth={'30rem'} alignItems="flex-start">
                                <p className={styles.customerKeyPointLabel}>{t('pages-customers.averageInterestRate')}</p>
                                {interestRate && <InterestBadge interest={interestRate || 0} />}
                                {!interestRate && <p className={styles.customerKeyPointEmptyValue}>{t('pages-customers.noDeposits')}</p>}
                            </Stack>
                        </Stack>
                        {fundValue > 0 && (
                            <Stack direction="row" flexWrap={'wrap'} alignItems="center" justifyContent={'flex-start'} pt={2} spacing={{xs: 1, md: 4}}>
                                <Stack spacing={0.5} maxWidth={'30rem'} minWidth={'25rem'}>
                                    <p className={styles.customerKeyPointLabel}>{t('pages-customers.fund')}</p>
                                    <p className={styles.customerKeyPointValue}>{Currency(fundValue, {withCurrency: customer.portfolios.length === 1})}</p>
                                </Stack>
                                <Stack spacing={0.5} maxWidth={'30rem'} alignItems="flex-start">
                                    <p className={styles.customerKeyPointLabel}>{'Antall plasseringer'}</p>
                                    <p className={styles.customerKeyPointValue}>{portfolio.fundPlacements.filter(fp => fp.unitQuantity > 0 ).length}</p>
                                </Stack>
                            </Stack>
                        )}
                    </Stack>
                )})}
                {customer.contactName && (
                    <Stack direction={{md: "row"}} alignItems={{md: "center"}} justifyContent="space-between">
                        <LabeledInfo label={t('pages-customers.customerDetailInfoContactPerson')} info={customer.contactName || '-'}/>
                        <LabeledInfo label={t('pages-customers.customerDetailInfoEmailAddress')} info={customer.contactEmail || '-'}/>
                        <LabeledInfo label={t('pages-customers.customerDetailInfoPhone')} info={customer.contactPhone || '-'}/>
                    </Stack>
                )}
            </Stack>
        </Paper>
    )
}
