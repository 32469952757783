import {useDispatch} from 'react-redux'
import {getLastOrders} from '#services/thunks/statistics'
import {Alert, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from '@mui/material'
import { InterestBadge } from '#app/components/InterestBadge/InterestBadge'
import { useTranslation } from 'react-i18next'
import {CurrencyOutput, DateOutput, LoadingSpinner} from '#app/components'
import {Currency, InterestRateBenchmark} from '@fixrate/fixrate-query'
import { useSelector } from '#app/state/useSelector'
import AdInterestLabel from "#components/AdInterestLabel";
import {useQuery} from "react-query";

export interface LastOrdersData {
    business: string,
    created: string,
    interest: number,
    interestRateBenchmark?: InterestRateBenchmark,
    nibor: number,
    productId: string,
    totalAssets: number,
    volume: number,
    currency: Currency
}

export const LastOrders = () => {
    const {t} = useTranslation()
    const dispatch = useDispatch<(arg0: (any) => void) => Promise<LastOrdersData[]>>()
    const currency = useSelector(state => state.session?.association?.currentCurrency)

    const {data: orders, isFetching, error} = useQuery(['lastOrders', currency], async () => await dispatch(getLastOrders()))

    const assetsToText = (assets) => {
        if (assets <= 5000) {
            return t('common.billionsBetween', {min: 0, max: 5})
        } else if (assets <= 15000) {
            return t('common.billionsBetween', {min: 5, max: 15})
        } else {
            return t('common.billionsMoreThan', {count: 15})
        }
    }

    return orders?.length > 0 ? (
        <>
            { isFetching && !orders && <LoadingSpinner /> }
            { !error ? (
                <TableContainer>
                    <Table sx={{overflow: 'auto'}}>
                        <TableHead sx={{color: 'white'}}>
                            <TableRow>
                                <TableCell style={{textAlign: "right", width: "12rem"}}>{t('pages-BankDashboard.volume')}</TableCell>
                                <TableCell style={{width: "10rem"}} align={'center'}>{t('pages-BankDashboard.interest')}</TableCell>
                                <TableCell>{t('pages-BankDashboard.bankSegment')}</TableCell>
                                <TableCell>{t('pages-BankDashboard.product')}</TableCell>
                                <TableCell>{t('pages-BankDashboard.date')}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders?.sort((a, b) => new Date(b.created).getTime() - new Date(a.created).getTime()).map(order => (
                                <TableRow key={Object.values(order).join('-')} sx={{backgroundColor: 'white'}}>
                                    <TableCell style={{textAlign: "right", width: "12rem"}}>
                                        {CurrencyOutput.formatMillion(order.volume * 1e6, order.currency)}
                                    </TableCell>
                                    <TableCell style={{width: "19rem"}} align={'center' }>
                                        {order.interestRateBenchmark
                                            ? <AdInterestLabel productType={'FLOATING'} interestRate={order.interest} interestRateBenchmark={order.interestRateBenchmark} />
                                            : <InterestBadge interest={order.interest} />
                                        }
                                    </TableCell>
                                    <TableCell>{assetsToText(order.totalAssets)}</TableCell>
                                    <TableCell>{t(`common.productLongName${order.productId}`)}</TableCell>
                                    <TableCell>{DateOutput.format(new Date(order.created), "yyyy-MM-dd")}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            ) : (
                <Alert severity="warning">{t('pages-BankDashboard.loadingFailed')}</Alert>
            )}
        </>
    ) : null
}
