import styles from './AnimatedHamburger.module.scss'
import classNames from 'classnames'

export default function AnimatedHamburger({open, darkMode} : {open: boolean, darkMode}) {
    return (
        <div className={classNames(styles.hamburger, open ? styles.open : null, darkMode ? styles.darkMode : null )}>
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
    )
}