import { useSelector } from '#state/useSelector'
import isAfter from 'date-fns/isAfter'
import { useTranslation } from 'react-i18next'
import { BenchmarkGraph } from './BenchmarkGraph'
import {Box, Stack, Typography} from "@mui/material";
import {PURPLE} from "#app/colors/colors";
import {InterestBadge} from "#components/InterestBadge/InterestBadge";

const PRODUCT_31D_ID = '5'

export const Header = () => {
    const {t} = useTranslation()

    const activeDeposits = useSelector(state => state.deposits.filter(deposit => !deposit.terminationDate || isAfter(new Date(deposit.terminationDate), new Date())))
    const active31dDeposits = activeDeposits.filter(deposit => deposit.product.id === PRODUCT_31D_ID)

    // Using calculatedTotalBalance here. This is the balance with accrued interests. This includes all interest the customer has earned on the deposit
    // since the deposit was created until today.
    const activeDepositsBalance = activeDeposits.reduce((acc, deposit) => acc + deposit.calculatedBalance, 0)

    // activeDepositsTotalBalance rounded down to millions
    const activeDepositsBalanceMillions = Math.floor(activeDepositsBalance / 1000000)

    // Using calculatedBalance here. This is the balance without accrued interests. This is the balance that is used to calculate the interest rate.
    // For most deposits, this is the balance pr. Jan 1st (or when the deposit was created).
    const active31dDepositsCalculatedBalance = active31dDeposits.reduce((acc, deposit) => acc + deposit.calculatedBalance, 0)

    const average31dInterestRatePerVolume = active31dDeposits.reduce((acc, deposit) => acc + deposit.calculatedBalance * deposit.effectiveInterestRate, 0) / active31dDepositsCalculatedBalance

    return (
        <Stack direction={{sm: 'column', md: 'row'}} justifyContent={'space-between'} mt={0} spacing={2}>
            <Stack direction={{sm: 'row', md: 'column'}} spacing={2} justifyContent={'space-around'} pt={0} width={{sm: 1, md: 1/3}}>
                <Box>
                    <Typography variant={'subtitle1'} >{t('pages-BankDashboard.banksDeposits')}</Typography>
                    <Typography mt={0} component={'p'} fontSize="2.6rem" fontWeight="800" color={PURPLE[500]}>{t(`common.volume${activeDepositsBalanceMillions === 1 ? '' : '_other'}`, {count: activeDepositsBalanceMillions})}</Typography>
                </Box>
                <Box>
                    <Typography variant={'subtitle1'} >{t('pages-BankDashboard.averageInterestRate')}</Typography>
                    <InterestBadge interest={average31dInterestRatePerVolume} sx={{py: 1, px: 1, mt: 0.5, mb: 0.5}}/>
                </Box>
            </Stack>
            <Stack direction={'column'} width={{sm: 1, md: 2/3}}>
                <BenchmarkGraph height={200}/>
            </Stack>
        </Stack>
    )
}
