import { DateOutput, If } from '#components'
import { SignableDocument } from '#components/SignableDocument/SignableDocument'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { DocumentDto } from '@fixrate/fixrate-query'
import { Alert } from '@mui/material'
import compareAsc from 'date-fns/compareAsc'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Proxy } from '../../OrganizationDetail/UsersAndRoles/views/PowerOfAttorney/PowerOfAttorney'
import { Attorney } from '../Authorization'
import SignAuthorizationContainer from './SignAuthorizationContainer'

const SignAuthorization = function ({
                                        authorizationDocument,
                                        startElectronicSignature,
                                        depositorId,
                                        forUser,
                                        inline,
                                        onClose,
                                        contextUrl
                                    }: {
                                        authorizationDocument: DocumentDto,
                                        startElectronicSignature: (processId: string, signatureType: string, documentId: string) => void,
                                        depositorId: string,
                                        forUser: Attorney | Proxy,
                                        inline?: boolean,
                                        onClose?: () => void,
                                        contextUrl?: string
                                    }) {

    const {t} = useTranslation()
    const navigate = useNavigate()

    const lookupSignatureStatus = useSelector(selectors.lookupSignatureStatus)

    async function startSignature() {
        const processId = uuidv4()
        try {
            await startElectronicSignature(processId, 'SINGLE_DOCUMENT', authorizationDocument.id)
            const urlAfterSign = forUser.isUser ? `/organizations/${depositorId}/users/authorization/${forUser.id}` : `/organizations/${depositorId}/users/authorizationforinvite/${forUser.id}`
            navigate(`/signature/${processId}?context=${contextUrl ? contextUrl : urlAfterSign}`)
        } catch (err) {
            console.error(err)
        }
    }

    if (!authorizationDocument) {
        return null
    }

    // Sorts signature list by timeoutDate, oldest first
    const sortedSignatureList = authorizationDocument.allSignatures.slice().sort((s1, s2) => compareAsc(new Date(s1.timeoutDate), new Date(s2.timeoutDate)))
    const firstSignatureExpiry = sortedSignatureList.length > 0 && sortedSignatureList[0].timeoutDate

    return (
        <SignAuthorizationContainer onClose={onClose} modal={inline ? true : false}>
            <p>
                {t('pages-organizations.authorizationSignatureMessagePart1')}
            </p>
            <p>
                {t('pages-organizations.authorizationSignatureMessagePart2')}
            </p>

            <If condition={firstSignatureExpiry}>
                <p className="authorization__signature-expiry">
                    {t('pages-organizations.allMustSignBy', {date: DateOutput.formatDayMonthTime(firstSignatureExpiry)})}
                </p>
            </If>

            <If condition={authorizationDocument.allSignatures.length > 0}>
                <div className="authorization__signers">
                    <span className="authorization__signers-header">{t('pages-organizations.signedBy')}:</span>
                    <ul className="authorization__signers-list">{
                        authorizationDocument.allSignatures.map(signature => (
                            <li
                                className="authorization__signers-element"
                                key={signature.signerId}
                            >
                                {signature.name}
                            </li>
                        ))
                    }
                    </ul>
                </div>
            </If>

            { authorizationDocument.missingSignatures.length > 0 && (
                <Alert severity="warning">
                    {t('pages-organizations.lacksSignatureFrom')}: {
                        authorizationDocument.missingSignatures.map((signature, index) => (
                            <span key={signature.signerId}>
                                {signature.name}
                                {index < authorizationDocument.missingSignatures.length - 1 && ', '}
                            </span>
                        ))
                    }
                </Alert>
            )}
            <div className="authorization__action">
                <SignableDocument
                    document={authorizationDocument}
                    hideRemoveButton={true}
                    isCheckingSignatureStatus={lookupSignatureStatus(authorizationDocument.id)}
                    linkText={t('pages-organizations.downloadPowerOfAttorney')}
                    onStartSignature={startSignature}
                    showSigningError={false}
                />
            </div>
        </SignAuthorizationContainer>
    )
}

export default SignAuthorization

