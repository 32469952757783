import { PURPLE, SILVER_GRAY } from "#app/colors/colors";
import { MovieViewer } from "#app/components/MovieViewer/MovieViewer";
import { RichTextContent, RichTextRenderer } from "#app/components/PayloadCMS/RichTextRenderer/RichTextRenderer";
import { Employee, Media, Webinar } from "#app/services/payloadCMS/payload-types";
import {
    Box,
    Button,
    Card,
    CardContent,
    Stack,
    Tooltip,
    Typography
} from "@mui/material";
import { format, isFuture } from "date-fns";
import { useState } from "react";
import { useTranslation } from 'react-i18next';
import {getWebinarCategoryDescription, getWebinarCategoryName, hvelvetsKvalerDescription} from "./customerEvents";
import {EventOutlined} from "@mui/icons-material";

const localeLang = "nb-NO"

export default function WebinarCard({ webinar, onReadMore } : { webinar: Webinar, onReadMore: () => void }) {
    const {t} = useTranslation()
    const eventDay = new Date(webinar.dateFrom).toLocaleString(localeLang, { weekday: "long" })
    const eventMonth = new Date(webinar.dateFrom).toLocaleString(localeLang, { month: "short" })
    const isUpcoming = isFuture(new Date(webinar.dateTo))
    const [videoVisible, setVideoVisible] = useState(false)
    const episodeNumber = webinar?.episodeNumber ? webinar?.episodeNumber : null
    const hasEpisodeNumber = episodeNumber !== null

    return (
        <Card key={webinar.eventUrl} sx={{maxWidth: "84rem", width: '100%', pb: 2}}>
            <CardContent>
                <Stack direction={"column"} spacing={2} alignItems="flex-start" flexWrap={{xs: "wrap", md: "nowrap"}}>
                    <Stack direction="row" bgcolor={PURPLE[50]} px={1} borderRadius={10} alignItems={'center'}>
                        <EventOutlined sx={{mr: 1}}/>
                        <Typography variant={'subtitle1'}>{format(new Date(webinar.dateFrom), "dd.MM.yyy 'kl.' HH:mm")}</Typography>
                    </Stack>
                    <Stack spacing={2}>
                        <Stack spacing={1}>
                            <Stack spacing={1}>
                                <Typography variant={'h3'}>
                                    {getWebinarCategoryName(webinar)}
                                    {hasEpisodeNumber ? (" – " + t('pages-BankDashboard.episode') + " " + episodeNumber) : ""}
                                    { getWebinarCategoryDescription(webinar) && (
                                        <Tooltip sx={{fontSize: "1.6rem"}} title={getWebinarCategoryDescription(webinar)}>
                                            <i className="ri-question-line" style={{fontSize: "1.8rem", marginLeft: "0.5rem"}}/>
                                        </Tooltip>
                                    )}
                                </Typography>
                                <Typography>{webinar.title}</Typography>

                                <Stack direction="row" mt={2} spacing={2}>
                                    { isUpcoming && (
                                        <Button variant={"contained"} href={webinar.eventUrl} target="_blank" size="small">{t('pages-BankDashboard.joinWebinar')}</Button>
                                    )}
                                    { !isUpcoming && webinar.vimeoID && (
                                        <Button variant={"contained"} size="small" onClick={() => setVideoVisible(true)}>{t('pages-BankDashboard.watchWebinar')}</Button>
                                    )}
                                    <Button onClick={onReadMore} variant="text" size="small" color="primary">
                                        {t('pages-BankDashboard.readMore')}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                        { videoVisible && <MovieViewer videoActive={setVideoVisible} vimeoUrl={`https://player.vimeo.com/video/${webinar.vimeoID}?h=607f521a8b&badge=0&autoplay=1&loop=1&autopause=0player_id=0&app_id=58479&texttrack=no`} /> }
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}
