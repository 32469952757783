import { PURPLE } from "#app/colors/colors";
import { useSelector } from "#app/state/useSelector";
import { getMissingAuthorizationDocumentSignatures } from "#app/utilities/authorizationDocumentUtils";
import { nameWithRoles } from "#app/utilities/nameWithRoles";
import { DepositorDto, DepositorWarning } from "@fixrate/fixrate-query";
import { ErrorOutline } from "@mui/icons-material";
import { Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { isAfter } from "date-fns";
import { useTranslation } from "react-i18next";
import { AdvancedPersonOptions } from "../components/AdvancedPersonOptions";
import { UserInviteStatus } from "../components/UserInviteStatus";
import { invitationWarnings, peopleWithTasks } from "./InvitationsStep";
import { personHasWarning } from "./MissingInformationStep";

export default function StatusStep({
    depositor,
}: {
    depositor: DepositorDto;
}) {
    const { t } = useTranslation()
    const statusPeople = peopleWithTasks(depositor)
    const sessionId = useSelector(state => state.session.id)
    const documents = useSelector(state => state.documents)
    const warningsForUser = useSelector(state => state.depositor.warnings.filter(warning => warning.personId === sessionId))

    console.log(warningsForUser)

    const getUserIcon = (person) => {
        if (personHasWarning({
            type: ["INVITE_HAS_EXPIRED"],
            depositor: depositor,
            person: person,
        })) {
            return "ri-mail-forbid-fill"
        }
        if (personHasWarning({
            type: invitationWarnings,
            depositor: depositor,
            person: person,
        })) {
            return "ri-error-warning-fill"
        }
        if (person.associatedUserId) {
            return "ri-account-circle-fill"
        } else if (person.associatedInviteId) {
            return "ri-mail-send-fill"
        } else {
            return "ri-account-circle-fill"
        }
    }

    return (
        <>
            <Typography>
                {t('pages-OrganizationWizard.invitationsStepIntro')}
            </Typography>
            <Table sx={{ mt: 3 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('common.name')}</TableCell>
                        <TableCell>{t('common.status')}</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {statusPeople?.map((person) => (
                        <TableRow key={person.personId}>
                            <TableCell>
                                <Stack direction="row" spacing={1}>
                                    <i style={{color: PURPLE[500], fontSize: "1.8rem"}} className={getUserIcon(person)} />
                                    <Stack>
                                        <Typography fontWeight={600}>{person?.firstName} {person?.lastName}</Typography>
                                        <Typography variant="caption">{nameWithRoles(person, depositor, t).allRoles}</Typography>
                                    </Stack>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <Stack spacing={0.5} alignItems="flex-start">
                                    {personHasWarning({
                                        type: invitationWarnings,
                                        depositor: depositor,
                                        person: person,
                                    }) && (
                                        <Chip
                                            icon={<ErrorOutline />}
                                            label={t('pages-OrganizationWizard.missingInvitation')}
                                            color="error"
                                        />
                                    )}
                                    {personHasWarning({
                                        type: ["INVITE_HAS_EXPIRED"],
                                        depositor: depositor,
                                        person: person,
                                    }) && (
                                        <Chip
                                            icon={<ErrorOutline />}
                                            label={t('pages-OrganizationWizard.invitationExpired')}
                                            color="error"
                                        />
                                    )}
                                    {!personHasWarning({
                                        type: [...invitationWarnings, "INVITE_HAS_EXPIRED"],
                                        depositor: depositor,
                                        person: person,
                                    }) && (
                                        !person.associatedUserId && (
                                            <Chip
                                                icon={<i className="ri-mail-send-line" />}
                                                label={t('pages-OrganizationWizard.mustAcceptInvitation')}
                                                color="error"
                                            />
                                        )
                                    )}
                                    <Stack alignItems={"flex-start"} spacing={1}>
                                        {depositor?.warnings.filter(warning => warning.personId === person.personId).filter(w => !invitationWarnings.includes(w.type)).map((warning: DepositorWarning) => {
                                            if (warning.type === "AUTHORIZATION_DOCUMENT_NOT_SIGNED") {
                                                return (
                                                    <Chip
                                                        key={warning.type}
                                                        icon={<ErrorOutline />}
                                                        label={t('pages-OrganizationWizard.authorizationDocumentNotSigned', {signers: getMissingAuthorizationDocumentSignatures(documents, person, depositor)})}
                                                        color="error"
                                                    />
                                                )
                                            } else {
                                                return (
                                                    <Chip
                                                        key={warning.type}
                                                        icon={<ErrorOutline />}
                                                        label={t('enum-DepositorWarning.' + warning.type)}
                                                        color="error"
                                                    />
                                                )
                                            }
                                        })}
                                    </Stack>
                                    { !person.associatedUserId && <UserInviteStatus person={person} depositor={depositor} /> }
                                </Stack>
                            </TableCell>
                            <TableCell>
                                <AdvancedPersonOptions depositor={depositor} person={person} />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    );
}