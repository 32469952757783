import { PURPLE } from "#app/colors/colors";
import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import classNames from "classnames";
import { Dispatch, SetStateAction } from 'react';

interface Props {
    header: string,
    infoVisible: boolean,
    setInfoVisible: Dispatch<SetStateAction<boolean>>,
    children: React.ReactNode,
}

export default function Brregbox({header, infoVisible = true, setInfoVisible, children}: Props) {
    return (
        <Accordion sx={{border: "0.1rem solid " + PURPLE[100]}}>
            <AccordionSummary expandIcon={<ExpandMore sx={{color: PURPLE[900]}}/>} sx={{backgroundColor: PURPLE[50] + "90"}}>
                <Stack spacing={1} direction="row" alignItems="center">
                    <i className="ri-archive-drawer-line"/>
                    <Typography fontWeight={600} variant="body2" color={PURPLE[900]}>
                        {header}
                    </Typography>
                </Stack>
            </AccordionSummary>
            <AccordionDetails sx={{borderTop: "0.1rem solid " + PURPLE[100]}}>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}