import { Fragment, useEffect, useState } from 'react';
import {ChangelogObject} from '#pages/newcustomerdeclarations/Changelog/types'
import {isEmptyObject, isObject} from '#services/objectUtils'
import {CategoryAccordion} from '#pages/newcustomerdeclarations/Changelog/CategoryAccordion'
import {useEndpoint} from '#command'
import {useTranslation} from 'react-i18next'
import {LoadingSpinner} from '#components'
import { TFunction } from 'react-i18next';

// Should match fixrate-documents://src/main/resources/templates/customerdeclaration.ftl
export const CHANGELOG_GROUPS = {
    customerInformation: [
        'nationalIdentity',
        'name',
        'address',
        'postCode',
        'city',
        'businessCode1',
        'businessCode2',
        'businessCode3',
        'yearlyTurnover',
        'hasOperationsAbroad',
        'operationsAbroad',
        'companyTaxpayerAbroad',
        'companyTin',
        'americanCompany',
        'americanCompanyTin',
        'publiclyListed',
        'hasParentCompany',
        'ultimateParent',
        'relationToUltimateParent',
        'mainlyPassiveIncome',
        'cryptoCurrencies',
        'paymentProcessor',
        'financialInstitution',
        'giin',
    ],
    beneficialOwnership: [
        'people',
    ],
    originOfFunds: [
        'fundOrigins',
        'hasPaymentsMadeAbroad',
        'paymentsMadeAbroad',
        'hasPaymentsReceivedAbroad',
        'paymentsReceivedAbroad',
    ],
}

export const ATTRIBUTES_WITH_LIST_TYPE: { [index: string]: string } = {
    'people': 'name',
    'tin': 'country',
    'operationsAbroad': 'country',
    'paymentsMadeAbroad': 'country',
    'paymentsReceivedAbroad': 'country',
    'companyTin': 'country',
}

interface Props {
    depositorId: string
}

export function Changelog({depositorId}: Props) {

    const {t} = useTranslation()
    const {getCustomerDeclarationChangelog} = useEndpoint()
    const [changelog, setChangelog] = useState<ChangelogObject | null>(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)

    useEffect(() => {
        setLoading(true)
        getCustomerDeclarationChangelog(depositorId)
            .then(setChangelog)
            .catch(err => {
                console.error('Failed to fetch changelog', err)
                setError(true)
            })
            .finally(() => setLoading(false))
    }, [depositorId, getCustomerDeclarationChangelog])

    if (loading) {
        return <LoadingSpinner/>
    }

    if (error || !changelog) {
        return <p><i className="ri-error-warning-line"/> {t('pages-newcustomerdeclarations.loadingFailed')}</p>
    }

    const groupedChangelogObjects: { [key: string]: ChangelogObject } = Object.assign(
        {},
        ...Object.entries(CHANGELOG_GROUPS).map(([group, keys]) => {
            const presentKeys = keys.filter(key => changelog.value[key])
            return ({
                [group]: {
                    changeType: [...new Set(presentKeys.map(key => changelog.value[key].changeType))].length === 1 ? changelog.value[presentKeys[0]].changeType : 'CHANGED',
                    value: Object.assign({}, ...presentKeys.map(key => ({[key]: changelog.value[key]}))),
                },
            })
        }),
    )

    return (
        <Fragment>
            <CategoryAccordion currentKey={'customerInformation'} changelogObject={groupedChangelogObjects.customerInformation}/>
            <CategoryAccordion currentKey={'people'} changelogObject={groupedChangelogObjects.beneficialOwnership.value['people']}/>
            <CategoryAccordion currentKey={'originOfFunds'} changelogObject={groupedChangelogObjects.originOfFunds}/>
        </Fragment>
    );
}

export function formatValue(value: unknown, t?: TFunction): string {
    if (isEmptyValue(value)) {
        return '-'
    } else if (`${value}` === 'true') {
        return t('common.yes')
    } else if (`${value}` === 'false') {
        return t('common.no')
    } else {
        return `${value}`
    }
}

export function formatTranslationKey(baselineKey: string, key: string): string {
    const unmaskedKey = `${baselineKey}--${key}`
    let previousKey = ''
    const maskedKeyParts = []
    unmaskedKey.split('--').forEach(key => {
        if (Object.keys(ATTRIBUTES_WITH_LIST_TYPE).includes(previousKey)) {
            maskedKeyParts.push('*')
        } else {
            maskedKeyParts.push(key)
        }
        previousKey = key
    })
    return maskedKeyParts.join('--')
}

export function containsEmptyValue(changelogObject: ChangelogObject) {
    return isEmptyValue(changelogObject.value)
}

export function isEmptyValue(value: unknown) {
    return isEmptyObject(value) || value === null || value === ''
}

export function hasNestedChangelogObjects(changelogObject: ChangelogObject): boolean {
    return isObject(changelogObject.value) && Object.keys(changelogObject.value).some(key => changelogObject.value[key]?.changeType)
}

export function isLastChangeObject(changelogObject: ChangelogObject): boolean {
    return changelogObject?.changeType !== 'CHANGED' && (isEmptyObject(changelogObject.value) || !isObject(changelogObject.value))
}

export function hasNoChildren(changelogObject: ChangelogObject): boolean {
    if (isLastChangeObject(changelogObject)) {
        return changelogObject.changeType === 'UNCHANGED' || containsEmptyValue(changelogObject)
    }
    return Object.keys(changelogObject.value).every(childKey => hasNoChildren(changelogObject.value[childKey]))
}
