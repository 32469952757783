import { useState } from 'react';
import {CurrencyOutput, DateOutput, NumberInput, Table, TableCell, TableHeader, TableHeaderCell, TableRow} from '#components'
import {useSelector} from '#state/useSelector'
import DocumentLink from '#components/DocumentLink'
import {FundPlacementTransaction} from '@fixrate/fixrate-query'
import styles from './AdminTransactionTable.module.scss'
import {useTranslation} from 'react-i18next'
import {Box, Button, IconButton, MenuItem, TextField, Tooltip} from '@mui/material'
import DateInput from '#components/DateInput'
import {LoadingButton} from '@mui/lab'
import {useFieldState} from '@fixrate/fieldstate'
import {useCommand} from '#command'
import {TransactionTypeBadge} from "#components/TransactionTypeBadge/TransactionTypeBadge";
import { useCurrencyOutput } from '#app/components/CurrencyOutput/useCurrencyOutput';
import ChipSelect from "#pages/FundMarketplace/ChipSelect";
import {fundPlacementTransactionTypeTMap} from "#services/enumTranslationKeyMapping";

type TransactionRow = FundPlacementTransaction & {
    depositorName: string
    fundName: string
    decimalPrecision: number
}

type Options = {
    hideDepositor?: boolean
    hideFund?: boolean
}

type Props = {
    transactions: TransactionRow[]
    setFundPlacementIdFilter: (fundPlacementId: string) => void
    options?: Options
}

export default function AdminTransactionTable({transactions, setFundPlacementIdFilter, options}: Props) {
    const {t} = useTranslation()

    const tableHeader = (
        <TableHeader>
            <TableHeaderCell>{t('pages-fundTransactions.transactionDate')}</TableHeaderCell>
            <TableHeaderCell>{t('pages-fundTransactions.serialNumber')}</TableHeaderCell>
            <TableHeaderCell>{t('pages-fundTransactions.settlementDate')}</TableHeaderCell>
            {!options?.hideFund && <TableHeaderCell>{t('pages-fundTransactions.fund')}</TableHeaderCell>}
            {!options?.hideDepositor && <TableHeaderCell>{t('pages-fundTransactions.depositor')}</TableHeaderCell>}
            <TableHeaderCell align={'right'}>{t('pages-fundTransactions.navPrice')}</TableHeaderCell>
            <TableHeaderCell align={'right'}>{t('pages-fundTransactions.units')}</TableHeaderCell>
            <TableHeaderCell align={'right'}>{t('pages-fundTransactions.amount')}</TableHeaderCell>
            <TableHeaderCell align={'right'}>{t('pages-fundTransactions.accrual')}</TableHeaderCell>
            <TableHeaderCell align={'right'}>{t('pages-fundTransactions.rounding')}</TableHeaderCell>
            <TableHeaderCell>{t('pages-fundTransactions.type')}</TableHeaderCell>
            <TableHeaderCell>{t('pages-fundTransactions.confirmationSlip')}</TableHeaderCell>
            <TableHeaderCell >{t('pages-fundTransactions.adminControls')}</TableHeaderCell>
        </TableHeader>
    )
    const numberOfVisibleColumns = tableHeader.props.children.length

    return (
        <Table>
            {tableHeader}
            {transactions.map(tr => (
                <TransactionRowComponent
                    key={tr.depositorName + tr.fundName + tr.sequenceNumber}
                    tr={tr}
                    onFilterClick={() => setFundPlacementIdFilter(tr.fundPlacementId)}
                    options={options}
                    numberOfVisibleColumns={numberOfVisibleColumns}
                    isTableRow
                />
            ))}
        </Table>
    )
}

type TransactionRowProps = {
    tr: TransactionRow
    onFilterClick: () => void
    options?: Options
    numberOfVisibleColumns: number
    isTableRow: true
}

function TransactionRowComponent({tr, onFilterClick, options, numberOfVisibleColumns}: TransactionRowProps) {
    const {t} = useTranslation()
    const {editFundPlacementTransaction} = useCommand()
    const Currency = useCurrencyOutput()

    const [editMode, setEditMode] = useState(false)

    const transactionDateField = useFieldState(tr.transactionDate ? new Date(tr.transactionDate) : null)
    const settlementDateField = useFieldState(tr.settlementDate ? new Date(tr.settlementDate) : null)
    const unitPriceField = useFieldState(tr.unitPrice)
    const unitQuantityField = useFieldState(tr.unitQuantity)
    const amountField = useFieldState(tr.amount)
    const roundingErrorField = useFieldState(tr.roundingError)
    const commentField = useFieldState(tr.comment ?? '')
    const commentForDepositorField = useFieldState(tr.commentForDepositor ?? '')
    const typeField = useFieldState(tr.type)

    const [submitting, setSubmitting] = useState(false)

    async function submit() {
        if (!validateForm()) {
            return
        }

        setSubmitting(true)

        const {waitForCommand} = await editFundPlacementTransaction(
            tr.fundPlacementId,
            tr.id,
            transactionDateField.value,
            settlementDateField.value,
            unitPriceField.value,
            unitQuantityField.value,
            amountField.value,
            roundingErrorField.value,
            commentField.value,
            commentForDepositorField.value,
            typeField.value,
        )
        const success = await waitForCommand()
        setSubmitting(false)
        if (success) {
            exitEditMode()
        }
    }

    function enterEditMode() {
        resetForm()
        setEditMode(true)
    }

    function exitEditMode() {
        resetForm()
        setEditMode(false)
    }

    function resetForm() {
        transactionDateField.reset()
        settlementDateField.reset()
        unitPriceField.reset()
        unitQuantityField.reset()
        amountField.reset()
        roundingErrorField.reset()
        commentField.reset()
        commentForDepositorField.reset()
        typeField.reset()
    }

    function validateForm(): boolean {
        return transactionDateField.validate() && settlementDateField.validate() && unitPriceField.validate() && unitQuantityField.validate() && amountField.validate() && roundingErrorField.validate()
        && commentField.validate() && commentForDepositorField.validate() && typeField.validate()
    }

    return (
        <>
        <TableRow>
            {editMode ? (
                <TableCell>
                    <DateInput
                        value={transactionDateField.value}
                        onChange={transactionDateField.setValue}
                        onBlur={transactionDateField.onBlur}
                    />
                </TableCell>
            ) : (
                <TableCell>{DateOutput.formatDate(tr.transactionDate)}</TableCell>
            )}
            <TableCell>
                <span>{tr.sequenceNumber}</span>
            </TableCell>
            {editMode ? (
                <TableCell>
                    <DateInput
                        value={settlementDateField.value}
                        onChange={settlementDateField.setValue}
                        onBlur={settlementDateField.onBlur}
                    />
                </TableCell>
            ) : (
                <TableCell>{DateOutput.formatDate(tr.settlementDate)}</TableCell>
            )}
            {!options?.hideFund && (
                <TableCell className={styles.cappedCell} style={{fontVariant: 'tabular-nums'}}>
                    <Tooltip title={tr.fundName} arrow placement={'bottom-start'}>
                        <span>{tr.fundName}</span>
                    </Tooltip>
                </TableCell>
            )}
            {!options?.hideDepositor && (
                <TableCell className={styles.cappedCell}>
                    <Tooltip title={tr.depositorName} arrow placement={'bottom-start'}>
                        <span>{tr.depositorName}</span>
                    </Tooltip>
                </TableCell>
            )}
            {editMode ? (
                <TableCell>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <NumberInput
                            sx={{width: '14rem', backgroundColor: 'white'}}
                            value={unitPriceField.value}
                            onChange={unitPriceField.setValue}
                            onBlur={unitPriceField.onBlur}
                            formatFn={v => Currency(v, { decimals: tr.decimalPrecision })}
                        />
                    </Box>
                </TableCell>
            ) : (
                <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{Currency(tr.unitPrice, { decimals: tr.decimalPrecision })}</TableCell>
            )}
            {editMode ? (
                <TableCell>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <NumberInput
                            sx={{width: '20rem', backgroundColor: 'white'}}
                            value={unitQuantityField.value}
                            onChange={unitQuantityField.setValue}
                            onBlur={unitQuantityField.onBlur}
                            formatFn={v => CurrencyOutput.formatNoCode(v, tr.decimalPrecision)}
                        />
                    </Box>
                </TableCell>
            ) : (
                <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{CurrencyOutput.formatNoCode(tr.unitQuantity, tr.decimalPrecision)}</TableCell>
            )}
            {editMode ? (
                <TableCell>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <NumberInput
                            sx={{width: '20rem', backgroundColor: 'white'}}
                            value={amountField.value}
                            onChange={amountField.setValue}
                            onBlur={amountField.onBlur}
                            formatFn={v => Currency(v)}
                        />
                    </Box>
                </TableCell>
            ) : (
                <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{Currency(tr.amount)}</TableCell>
            )}
            <TableCell align={'right'}>
                {
                    tr.type === 'SELL'
                        ? (tr?.saleAccrualValue && Currency(tr.saleAccrualValue)) || '-'
                        : (tr?.purchaseAccrualValue && Currency(tr.purchaseAccrualValue)) || '-'
                }
            </TableCell>
            {editMode ? (
                <TableCell>
                    <Box sx={{display: 'flex', justifyContent: 'flex-end'}}>
                        <NumberInput
                            sx={{width: '10rem', backgroundColor: 'white'}}
                            value={roundingErrorField.value}
                            onChange={roundingErrorField.setValue}
                            onBlur={roundingErrorField.onBlur}
                            formatFn={v => Currency(v)}
                        />
                    </Box>
                </TableCell>
            ) : (
                <TableCell align={'right'} style={{fontVariant: 'tabular-nums'}}>{Currency(tr.roundingError)}</TableCell>
            )}
            {editMode ? (
                <TableCell>
                    <ChipSelect
                        value={typeField.value}
                        onChange={e => typeField.setValue(e.target.value)}
                    >
                        {['BUY', 'SELL', 'DIVIDEND', 'RETROCESSION', 'RETROCESSION_CASH', 'RETROCESSION_UNITS'].map(transactionType => (
                            <MenuItem key={transactionType} value={transactionType}>
                                {t(fundPlacementTransactionTypeTMap[transactionType])}
                            </MenuItem>
                        ))}
                    </ChipSelect>
                </TableCell>
            ) : (
                <TableCell>
                    <span className={styles.typeBadge}>
                        <TransactionTypeBadge transactionType={tr.type}/>
                    </span>
                </TableCell>
            )}
            <TableCell><DocumentCell label={t('common.download')} documentId={tr.documentId}/></TableCell>

            {editMode ? (
                <TableCell>
                    <Box>
                        <LoadingButton variant={'contained'} size={'small'} onClick={submit} disabled={submitting} loading={submitting}>
                            {t('common.save')}
                        </LoadingButton>
                        <Button variant={'outlined'} size={'small'} sx={{ml: 1}} onClick={exitEditMode} disabled={submitting}>
                            {t('common.cancel')}
                        </Button>
                    </Box>
                </TableCell>
                ) : (
                <TableCell style={{position: 'relative'}}>
                    <Tooltip title={t('common.edit')} arrow>
                        <IconButton onClick={enterEditMode}>
                            <i className="ri-edit-line"/>
                        </IconButton>
                    </Tooltip>
                    <Tooltip title={'Filtrer på denne plasseringen'} arrow>
                        <IconButton onClick={onFilterClick}>
                            <i className="ri-filter-line"/>
                        </IconButton>
                    </Tooltip>
                </TableCell>
            )}
        </TableRow>
        {editMode && (
            <TableRow>
                <TableCell colSpan={numberOfVisibleColumns}>
                    <Box sx={{display: 'flex', flexDirection: 'row', gap: 1, alignItems: 'center', justifyContent: 'flex-end'}}>
                        <TextField
                            sx={{backgroundColor: 'white', width: '25rem', maxWidth: '100%'}}
                            label={'Kommentar'}
                            value={commentField.value}
                            onChange={e => commentField.setValue(e.target.value)}
                            onBlur={commentField.onBlur}
                            multiline
                        />
                        <TextField
                            sx={{backgroundColor: 'white', width: '25rem', maxWidth: '100%'}}
                            label={'Kommentar for innskyter'}
                            value={commentForDepositorField.value}
                            onChange={e => commentForDepositorField.setValue(e.target.value)}
                            onBlur={commentForDepositorField.onBlur}
                            multiline
                        />
                    </Box>
                </TableCell>
            </TableRow>
        )}
        </>
    )
}

function DocumentCell({label, documentId}: { label: string, documentId: string }) {
    const document = useSelector(state => state.documents[documentId])
    return document ? <DocumentLink link={document.link} name={label}/> : <span>-</span>
}
