import AsyncButton from "#app/components/Button/AsyncButton";
import FxDialog from "#app/components/FxDialog/FxDialog";
import FxDialogActions from "#app/components/FxDialog/FxDialogActions";
import FxDialogContent from "#app/components/FxDialog/FxDialogContent";
import FxDialogTitle from "#app/components/FxDialog/FxDialogTitle";
import Modal from "#app/components/Modal";
import ResendUserInvite from "#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/DepositorPersonList/ResendUserInvite";
import EditPersonModal from "#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/EditPersonModal";
import InviteModal from "#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/InviteModal";
import NameOutput from "#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/NameOutput";
import { useAuthorization } from "#app/services/authorization";
import { useCommand } from "#app/services/beta";
import { useSelector } from "#app/state/useSelector";
import { DepositorDto, OrganisationalPersonDto } from "@fixrate/fixrate-query";
import { Box, Button, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, Paper, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { copyUserInviteLink } from "../steps/InvitationsStep";

const AdvancedPersonOptions = ({person, depositor} : {person: OrganisationalPersonDto, depositor: DepositorDto}) => {
    const {t} = useTranslation()
    const auth = useAuthorization(depositor.id)
    const sessionId = useSelector(state => state.session.id)
    const { removeUserInvite, removeUserFromDepositor, removePerson } = useCommand()

    const [advancedMenuAnchorRef, setAdvancedMenuAnchorRef] = useState<null | HTMLElement>(null);
    const advancedMenuOpen = Boolean(advancedMenuAnchorRef);
    const [confirmCopyModalInviteId, setConfirmCopyModalInviteId] = useState<null | string>(null)
    const [userInvite, setUserInvite] = useState(false)
    const [editPerson, setEditPerson] = useState(false)
    const [invitePerson, setInvitePerson] = useState(false)

    const [removeInviteModalVisible, setRemoveInviteModalVisible] = useState(false)
    const [removeUserModalVisible, setRemoveUserModalVisible] = useState(false)
    const [removePersonModalVisible, setRemovePersonModalVisible] = useState(false)

    const personInvite = depositor.userInvites.find(ui => ui.id === person.associatedInviteId)
    const linkIsCopied = personInvite?.id === confirmCopyModalInviteId

    const openAdvancedMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAdvancedMenuAnchorRef(event.currentTarget)
    };

    const closeAdvancedMenu = () => {
        setAdvancedMenuAnchorRef(null);
    }
    const advancedMenuItems = [
        {
            label: linkIsCopied ?  t('pages-OrganizationWizard.linkCopied') : t('blocks-UsersList.copyInviteLink'),
            icon: linkIsCopied ? <i className="ri-check-line" /> : <i className="ri-file-copy-line" />,
            onClick: () => copyUserInviteLink(personInvite, setConfirmCopyModalInviteId),
            visible: personInvite
        },
        {
            label: t('blocks-UsersList.sendAgain'),
            icon: <i className="ri-mail-send-line" />,
            onClick: () => {
                setUserInvite(true)
                closeAdvancedMenu()
            },
            visible: personInvite
        },
        {
            label: t('common.editInformation'),
            icon: <i className="ri-edit-line" />,
            onClick: () => {
                setEditPerson(true)
                closeAdvancedMenu()
            },
            visible: true
        },
        {
            label: t('blocks-UsersList.menuItemRemoveInvite'),
            icon: <i className="ri-mail-forbid-line" />,
            onClick: () => setRemoveInviteModalVisible(true),
            visible: personInvite && (auth.depositor.hasAdminRole || auth.depositor.hasPartnerRole)
        },
        {
            label: t('blocks-UsersList.menuItemRemoveUser'),
            icon: <i className="ri-user-unfollow-line" />,
            onClick: () => setRemoveUserModalVisible(true),
            visible: person.associatedUserId !== sessionId && person.associatedUserId && (auth.depositor.hasAdminRole || auth.depositor.hasPartnerRole)
        },
        {
            label: t('blocks-UsersList.menuItemRemovePerson'),
            icon: <i className="ri-user-unfollow-line" />,
            onClick: () => setRemovePersonModalVisible(true),
            visible: !personInvite && !person.associatedUserId && (auth.depositor.hasAdminRole || auth.depositor.hasPartnerRole)
        }
    ]

    async function onRemoveUser() {
        try {
            await removeUserFromDepositor(depositor.id, person.associatedUserId)
        } catch (err) {
            console.error(err)
        }
        setRemoveUserModalVisible(false)
    }

    async function onRemovePerson() {
        try {
            await removePerson(depositor.id, person.personId)
        } catch (err) {
            console.error(err)
        }
        setRemovePersonModalVisible(false)
    }

    async function onRemoveInvite() {
        try {
            await removeUserInvite(depositor.id, person.associatedInviteId)
        } catch (err) {
            console.error(err)
        }
        setRemoveInviteModalVisible(false)
    }

    return (
        <Box>
            <IconButton             
                onClick={openAdvancedMenu}
                size="small"
                aria-controls={advancedMenuOpen ? 'advanced-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={advancedMenuOpen ? 'true' : undefined}>
                <i className="ri-more-2-fill" />
            </IconButton>
            <Paper>
                <Menu 
                    transformOrigin={{ horizontal: 'center', vertical: 'top' }}
                    anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }} 
                    onClose={closeAdvancedMenu} 
                    open={advancedMenuOpen} 
                    id="advanced-menu"
                    sx={{"& .MuiList-root": { minWidth: "22rem" }}}
                    anchorEl={advancedMenuAnchorRef}>
                    { advancedMenuItems.filter(item => item.visible).map((item, i) => (
                        <MenuItem key={i} sx={{"& .MuiListItemIcon-root": { minWidth: "0" }}} onClick={item.onClick}>
                            <ListItemIcon sx={{backgroundColor: "transparent", width: "auto", fontSize: "1.6rem", minWidth: "0", minHeight: "0", alignItems: "center", lineHeight: "1", color: "inherit"}}>
                                {item.icon}
                            </ListItemIcon>
                            <ListItemText>
                                <Typography fontSize="1.4rem">
                                    {item.label}
                                </Typography>
                            </ListItemText>
                        </MenuItem>
                    ))}
                </Menu>
            </Paper>
            {editPerson && (
                <EditPersonModal
                    hideInvitationAlert={true}
                    wizardMode
                    person={person}
                    open={true}
                    onClose={() => setEditPerson(false)}
                />
            )}

            {invitePerson && (
                <InviteModal personId={person.personId} close={() => setInvitePerson(false)} />
            )}

            {userInvite && (
                <ResendUserInvite
                    depositorId={depositor.id}
                    inviteId={personInvite.id}
                    inviteEmail={personInvite.email}
                    close={() => setUserInvite(false)}
                    returnTo={""}
                />
            )}
            <FxDialog
                open={removeInviteModalVisible}
                onClose={() => setRemoveInviteModalVisible(false)}
                onSubmit={() => onRemoveInvite()}
            >
                <FxDialogTitle onClose={() => setRemoveInviteModalVisible(false)}>
                    {t('blocks-UsersList.removeInviteHeading')}
                </FxDialogTitle>
                <FxDialogContent>
                    <Typography mt={3}>
                        {t('blocks-UsersList.removeInviteConfirmation', {
                            userName: NameOutput.withBirthYearOnDuplicates(person, depositor),
                            organizationName: depositor.name,
                        })}
                    </Typography>
                </FxDialogContent>
                <FxDialogActions>
                    <Button onClick={() => setRemoveInviteModalVisible(false)}>{t('common.cancel')}</Button>
                    <AsyncButton onClick={() => onRemoveInvite()}>{t('blocks-UsersList.removeInviteHeading')}</AsyncButton>
                </FxDialogActions>
            </FxDialog>
            <FxDialog
                open={removeUserModalVisible}
                onClose={() => setRemoveUserModalVisible(false)}
                onSubmit={() => onRemoveUser()}
            >
                <FxDialogTitle onClose={() => setRemoveUserModalVisible(false)}>
                    {t('blocks-UsersList.removeUserHeading')}
                </FxDialogTitle>
                <FxDialogContent>
                    <Typography mt={3}>
                        {t('blocks-UsersList.removeUserConfirmation', {
                            userName: NameOutput.withBirthYearOnDuplicates(person, depositor),
                            organizationName: depositor.name,
                        })}
                    </Typography>
                </FxDialogContent>
                <FxDialogActions>
                    <Button onClick={() => setRemoveUserModalVisible(false)}>{t('common.cancel')}</Button>
                    <AsyncButton onClick={() => onRemoveUser()}>{t('blocks-UsersList.removeUserHeading')}</AsyncButton>
                </FxDialogActions>
            </FxDialog>
            <FxDialog
                open={removePersonModalVisible}
                onClose={() => setRemovePersonModalVisible(false)}
                onSubmit={() => onRemovePerson()}
            >
                <FxDialogTitle onClose={() => setRemovePersonModalVisible(false)}>
                    {t('blocks-UsersList.removePersonHeading')}
                </FxDialogTitle>
                <FxDialogContent>
                    <Typography mt={3}>
                        {t('blocks-UsersList.removePersonConfirmation', {
                            userName: NameOutput.withBirthYearOnDuplicates(person, depositor),
                            organizationName: depositor.name,
                        })}
                    </Typography>
                </FxDialogContent>
                <FxDialogActions>
                    <Button onClick={() => setRemovePersonModalVisible(false)}>{t('common.cancel')}</Button>
                    <AsyncButton onClick={() => onRemovePerson()}>{t('blocks-UsersList.removePersonHeading')}</AsyncButton>
                </FxDialogActions>
            </FxDialog>
        </Box>
    )
}

export { AdvancedPersonOptions }