import { DepositorDto, DocumentDto, OrganisationalPersonDto } from "@fixrate/fixrate-query"

export const getPersonAuthorizationDocumentId = (person: OrganisationalPersonDto, depositor: DepositorDto) => {
    const documentId = person?.associatedUserId ? depositor.users.find(u => u.id === person.associatedUserId)?.authorizationDocumentId : depositor.userInvites.find(ui => ui.id === person.associatedInviteId)?.authorizationDocumentId
    return documentId
}

export const getMissingAuthorizationDocumentSignatures = (documents: { [key: string] : DocumentDto }, person: OrganisationalPersonDto, depositor: DepositorDto) => {
    const personAuthorizationDocument = documents[getPersonAuthorizationDocumentId(depositor.people.find(p => p.personId === person.personId), depositor)]
    return personAuthorizationDocument?.missingSignatures.map(ms => ms.name).join(', ') || ''
}