import AsyncButton from '#app/components/Button/AsyncButton'
import Modal from '#app/components/Modal'
import { useCommand } from '#command'
import { DateOutput, FileUpload } from '#components'
import OldButton from '#components/Button'
import If from '#components/If/If'
import ModalConfirmation from '#components/Modal/ModalConfirmation'
import Paper from '#components/Paper'
import ProgressBar from '#components/ProgressBar/ProgressBar'
import { Table, TableCell, TableRow } from '#components/Table'
import TableHeader from '#components/Table/TableHeader'
import TableHeaderCell from '#components/Table/TableHeaderCell'
import DeleteIcon from '#icons/DeleteIcon'
import { useAuthorization } from '#services/authorization'
import { sortDatesAscending } from '#services/dateandtime'
import { PersonValidator } from '#services/PersonValidator'
import useCurrentDepositor from '#services/useCurrentDepositor'
import useSupportPane from "#services/useSupportPane"
import { showConfirmationModal } from '#state/reducers/confirmationModal'
import * as selectors from '#state/selectors'
import { useSelector } from '#state/useSelector'
import { Button, Stack } from '@mui/material'
import classNames from 'classnames'
import { Fragment, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import {useNavigate, useSearchParams} from 'react-router-dom'
import { useTracking } from 'react-tracking'
import { v4 as uuidv4 } from 'uuid'
import CustomerDeclarationIllustration from './customer-declaration-illustration.svg?url'
import CustomerDeclarationDocumentLink from './CustomerDeclarationDocumentLink'
import styles from './CustomerDeclarationInfo.module.scss'

const distributePopupSearchParam = 'showDistributePopup'


function currentStep(depositor) {
    const custom = PersonValidator.isCustomCustomerDeclaration(depositor)
    const approved = !custom || depositor?.customCustomerDeclarationApproved != null
    switch (depositor?.customerDeclarationDraftState) {
        case 'NO_DRAFT':
        case 'CHANGED': {
            return {step: 0, element: custom ? <Upload/> : <Update/>}
        }
        case 'CREATED': {
            return {step: !custom || !approved ? 1 : 2, element: !custom || approved ? <Sign/> : <Approve/>}
        }
        case 'SIGNED': {
            return {step: custom ? 3 : 2, element: <Distribute/>}
        }
    }
}

function useSteps(depositor) {
    const {t} = useTranslation()
    const findActiveDeposits = useSelector(selectors.depositorHasActiveDeposits)
    const hasActiveDeposits = findActiveDeposits(depositor.id)

    return PersonValidator.isCustomCustomerDeclaration(depositor) ?
        [t('pages-organizations.upload'), t('pages-organizations.approve'), t('pages-organizations.sign'), ...(hasActiveDeposits ? [t('pages-organizations.sendToBank')] : [t('pages-organizations.activate')])] :
        [t('pages-organizations.fillOut'), t('pages-organizations.sign'), ...(hasActiveDeposits ? [t('pages-organizations.sendToBank')] : [t('pages-organizations.activate')])]
}

function Update() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const depositor = useCurrentDepositor()
    const findActiveDeposits = useSelector(selectors.depositorHasActiveDeposits)
    const hasActiveDeposits = findActiveDeposits(depositor.id)

    function openCustomerDeclaration() {
        navigate(`/organizations/${depositor.id}/customerdeclaration`)
    }

    const isChanged = depositor?.customerDeclarationDraftState === 'CHANGED'
    const hasPersonGalleryChanged = PersonValidator.hasCustomerDeclarationPersonGalleryChanged(depositor)
    const isFirstCustomerDeclaration = !depositor?.customerDeclarationId

    const supportPane = useSupportPane()
    const session = useSelector(state => state.session)
    const {trackEvent} = useTracking({page: 'CustomerDeclaration', session})

    function showSupportArticle() {
        trackEvent({event: 'open-context-help'})
        supportPane.show('1c9f9f40107e416a92d65e4db1f49ac6')
    }

    return (
        <div className={styles.action}>
            <If condition={!isFirstCustomerDeclaration && hasPersonGalleryChanged && !isChanged}>
                <If.True>
                    <p className={styles.warning}><i
                        className={classNames('ri-error-warning-line', styles.warningIcon)}/> {t('pages-organizations.personGalleryChangesMessage')}
                    </p>
                </If.True>
            </If>
            <If condition={isChanged}>
                <If.True>
                    <p className="field-info-message">{t('pages-organizations.continueEditingMessage')}</p>
                </If.True>
                <If.False>
                    <p>{t('pages-organizations.createNewMessage')}</p>
                </If.False>
            </If>
            <If condition={hasActiveDeposits}>
                <p>{t('pages-organizations.hasActiveDepositsMessage')}</p>
            </If>
            <div className={styles.actionButtons}>
                <Stack direction={"row"} spacing={3}>
                    <AsyncButton
                        id="enterCustomerDeclarationDataButton"
                        onClick={openCustomerDeclaration}
                    >
                        {t('pages-organizations.fillOutCustomerDeclaration')}
                    </AsyncButton>
                    <Button variant={'outlined'} onClick={showSupportArticle} startIcon={<i className="ri-lifebuoy-line"/>}>
                        {t('pages-organizations.helpForCompletion')}
                    </Button>
                </Stack>
            </div>
        </div>
    )
}

function Upload() {
    const {t} = useTranslation()
    const depositor = useCurrentDepositor()
    const {uploadCustomerDeclarationBasis, deleteCustomerDeclarationBasis} = useCommand()
    const documents = useSelector(state => state.documents &&
        Object.values(state.documents)
            .filter(d => d.documentType === 'CUSTOMER_DECLARATION_BASIS' && d.owner === depositor.id)
            .sort((d1, d2) => sortDatesAscending(d1.created, d2.created)),
    )
    const [documentToDelete, setDocumentToDelete] = useState(null)
    const [isUploadingCustomerDeclaration, setIsUploadingCustomerDeclaration] = useState(false)

    const documentUrl = (document) => '/api/document/id/' + document.id + '/data'

    const onDeleteDocument = (document) => {
        setDocumentToDelete(document)
    }

    const onCancel = () => {
        setDocumentToDelete(null)
    }

    const deleteDocument = async () => {
        await deleteCustomerDeclarationBasis(depositor.id, documentToDelete.id)
        setDocumentToDelete(null)
    }

    const deleteModal = documentToDelete && (
        <ModalConfirmation
            onCancel={onCancel} onSubmit={deleteDocument}
            text={t('pages-organizations.deleteFileConfirmation', {fileName: documentToDelete.filename})}
            header={t('pages-organizations.deleteFileHeading')}
        />
    )

    const onDrop = (files) => {
        setIsUploadingCustomerDeclaration(true)
        files.forEach(file => {
            const reader = new FileReader()
            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {
                // console.log('Uploading file ' + file?.path + ', type=' + file.type)
                uploadCustomerDeclarationBasis(depositor.id, new Blob([reader.result], {type: file.type}), file?.path)
            }
            reader.readAsArrayBuffer(file)
        })
        setIsUploadingCustomerDeclaration(false)
    }

    const uploadedTableRows = documents.map(document => (
        <TableRow key={document.id}>
            <TableCell>{DateOutput.formatDateTime(document.created)}</TableCell>
            <TableCell><a className={styles.anchor} href={documentUrl(document)}>{document.filename}</a></TableCell>
            <TableCell><DeleteIcon
                className={styles.deleteIcon}
                onClick={() => onDeleteDocument(document)}
            /></TableCell>
        </TableRow>
    ))

    return (
        <div className={styles.action}>
            <p>{t('pages-organizations.customerDeclarationMustBeCreatedByFixrate')}</p>

            <p>{t('pages-organizations.customerDeclarationBasisUpload')}</p>

            <FileUpload uploadFiles={onDrop} isUploadingDocument={isUploadingCustomerDeclaration} multiple={true} />

            <p />
            <p />

            <Table>
                <TableHeader>
                    <TableHeaderCell>{t('pages-organizations.uploadTimestamp')}</TableHeaderCell>
                    <TableHeaderCell>{t('pages-organizations.fileName')}</TableHeaderCell>
                    <TableHeaderCell />
                </TableHeader>
                {uploadedTableRows}
            </Table>
            {deleteModal}
        </div>
    )
}

function Approve() {
    const {t} = useTranslation()

    const depositor = useCurrentDepositor()
    const auth = useAuthorization()
    const isApproved = depositor.customCustomerDeclarationApproved != null
    const hasApprovePermission = auth.permissions.includes('DEPOSITOR__CUSTOMER_DECLARATION__APPROVE')
    const {approveCustomerDeclaration} = useCommand()
    const [approveChecked, setApproveChecked] = useState(false)

    async function onApprove() {
        await approveCustomerDeclaration(depositor.id, depositor?.customerDeclarationDraft)
    }

    return (
        <div className={styles.action}>
            <CustomerDeclarationDocumentLink
                className={styles.actionDocument}
                document={depositor?.customerDeclarationDraft}
                depositor={depositor}
                linkText={t('pages-organizations.newCustomerDeclaration')}
            />
            <p>{t('pages-organizations.customCustomerDeclarationMustBeApproved')}</p>
            <p>{t('pages-organizations.contactFixrateIfNotApplicable')}</p>

            <If condition={!isApproved && hasApprovePermission}>
                <div>
                    <label className={styles.checkbox}>
                        <input
                            type="checkbox"
                            name="contentApproved"
                            data-cy="contentApprovedCheckbox"
                            checked={approveChecked}
                            onChange={e => setApproveChecked(e.target.checked)}
                        />
                        {t('pages-organizations.customerDeclarationApproved')}
                    </label>

                    <div className={styles.actionButtons}>
                        <OldButton
                            id="approveNewCustomerDeclarationButton"
                            data-cy="approveNewCustomerDeclarationButton"
                            onClick={onApprove}
                            disabled={!approveChecked}
                        >
                            {t('common.continue')}
                        </OldButton>
                    </div>
                </div>
            </If>
        </div>
    )
}

function Sign() {
    const {t} = useTranslation()
    const navigate = useNavigate()

    const depositor = useCurrentDepositor()
    const auth = useAuthorization()
    const hasSignCustomerDeclarationPermission = auth.permissions.includes('DEPOSITOR__CUSTOMER_DECLARATION__SIGN')
    const {removeCustomerDeclaration, startElectronicSignature} = useCommand()
    const isCheckingSignatureStatus = useSelector(state => selectors.lookupSignatureStatus(state)(depositor?.customerDeclarationDraft))

    async function onRemoveDocument() {
        await removeCustomerDeclaration(depositor.id)
        navigate(`/organizations/${depositor.id}/customerdeclaration`)
    }

    async function onStartSignature() {
        const processId = uuidv4()
        await startElectronicSignature(processId, 'SINGLE_DOCUMENT', depositor?.customerDeclarationDraft)
        navigate(`/signature/${processId}?context=/organizations/${depositor.id}/customerdecl?${distributePopupSearchParam}`)
    }

    return (
        <div className={styles.action}>
            <CustomerDeclarationDocumentLink
                className={styles.actionDocument}
                document={depositor?.customerDeclarationDraft}
                depositor={depositor}
                linkText={t('pages-organizations.newCustomerDeclaration')}
            />
            <If condition={PersonValidator.isCustomCustomerDeclaration(depositor)}>
                <If.True>
                    <p>{t('pages-organizations.customCustomerDeclarationMustBeSigned')}</p>
                </If.True>
                <If.False>
                    <p>{t('pages-organizations.customerDeclarationMustBeSigned')}</p>
                </If.False>
            </If>
            <If condition={PersonValidator.isCustomCustomerDeclaration(depositor)}>
                <p>{t('pages-organizations.contactFixrateIfNotApplicable')}</p>
            </If>

            <Stack direction="row" spacing={2} mt={3}>
                <If condition={!PersonValidator.isCustomCustomerDeclaration(depositor) && !isCheckingSignatureStatus}>
                    <AsyncButton onClick={onRemoveDocument} variant={'outlined'}>
                        {t('pages-organizations.continueEditing')}
                    </AsyncButton>
                </If>
                <If condition={hasSignCustomerDeclarationPermission && !isCheckingSignatureStatus}>
                    <AsyncButton
                        id="signNewCustomerDeclarationButton"
                        data-cy="signNewCustomerDeclarationButton"
                        onClick={onStartSignature}
                    >
                        {t('pages-organizations.signCustomerDeclaration')}
                    </AsyncButton>
                </If>
            </Stack>
        </div>
    )
}

function Distribute() {
    const {t} = useTranslation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const depositor = useCurrentDepositor()
    const [searchParams, setSearchParams] = useSearchParams();
    const distributePopupOpen = searchParams.has(distributePopupSearchParam)

    const findActiveDeposits = useSelector(selectors.depositorHasActiveDeposits)
    const hasActiveDeposits = findActiveDeposits(depositor.id)
    const hasActiveOrders = useSelector(state => state.orders.filter(order => order.depositor.id === depositor.id && order.orderState !== 'CANCELED' && order.orderState !== 'COMPLETED').length > 0)
    const {distributeCustomerDeclaration, removeCustomerDeclaration} = useCommand()

    const [submitting, setSubmitting] = useState(false)

    async function onDistribute() {
        const confirmationTexts = hasActiveDeposits || hasActiveOrders ? ({
            title: t('pages-organizations.customerDeclarationDistributedHeading'),
            text: t('pages-organizations.customerDeclarationDistributedMessage'),
            buttonText: t('common.continue'),
        }) : ({
            title: t('pages-organizations.customerDeclarationActivatedHeading'),
            text: t('pages-organizations.customerDeclarationActivatedMessage'),
            buttonText: t('common.continue'),
        })

        setSubmitting(true)

        try {
            const {waitForCommand} = await distributeCustomerDeclaration(depositor.id)
            const success = await waitForCommand()
            if (success) {
                dispatch(showConfirmationModal(confirmationTexts))
            }
        } finally {
            if (setSubmitting instanceof Function && submitting) {
                setSubmitting(false)
            }
        }
    }

    async function onRemoveDocument() {
        await removeCustomerDeclaration(depositor.id)
        navigate(`/organizations/${depositor.id}/customerdeclaration`)
    }

    return (
        <div className={styles.action}>
            <CustomerDeclarationDocumentLink
                className={styles.actionDocument}
                document={depositor?.customerDeclarationDraft}
                depositor={depositor}
                linkText={t('pages-organizations.newCustomerDeclaration')}
            />
            <If condition={hasActiveDeposits}>
                <If.True>
                    <p>{t('pages-organizations.distributionMessageActiveDeposits')}</p>
                </If.True>
                <If.False>
                    <p>{t('pages-organizations.distributionMessage')}</p>
                </If.False>
            </If>
            <If condition={PersonValidator.isCustomCustomerDeclaration(depositor)}>
                <p>{t('pages-organizations.distributionMessageCustom')}</p>
            </If>

            <Stack direction={"row"} spacing={2} mt={3}>
                <If condition={!PersonValidator.isCustomCustomerDeclaration(depositor)}>
                    <AsyncButton onClick={onRemoveDocument} variant={'outlined'}>
                        {t('pages-organizations.continueEditing')}
                    </AsyncButton>
                </If>
                <AsyncButton
                    id="distributeCustomerDeclarationButton"
                    onClick={onDistribute}
                    disabled={submitting}
                >
                    {hasActiveDeposits ? t('pages-organizations.distributeToBanks') : t('pages-organizations.activateCustomerDeclaration')}
                </AsyncButton>
            </Stack>
            {distributePopupOpen && (
                <Modal onCancel={() => setSearchParams((params) => {
                            params.delete(distributePopupSearchParam)
                            return params
                       })}
                       className={styles.activateCustomerDeclarationPopup}>
                    <img src={CustomerDeclarationIllustration} alt={''}/>
                    <h2>{hasActiveDeposits ? t('pages-organizations.distributeToBanks') : t('pages-organizations.activateCustomerDeclaration')}</h2>
                    <If condition={hasActiveDeposits}>
                        <If.True>
                            <p>{t('pages-organizations.distributionMessageActiveDeposits')}</p>
                        </If.True>
                        <If.False>
                            <p>{t('pages-organizations.distributionMessage')}</p>
                        </If.False>
                    </If>
                    <If condition={PersonValidator.isCustomCustomerDeclaration(depositor)}>
                        <p>{t('pages-organizations.distributionMessageCustom')}</p>
                    </If>
                    <Stack mt={3}>
                        <AsyncButton
                            data-cy="popUp-distributeCustomerDeclarationButton"
                            onClick={onDistribute}
                            disabled={submitting}
                            className={styles.centerButton}
                        >
                            {hasActiveDeposits ? t('pages-organizations.distributeToBanks') : t('pages-organizations.activateCustomerDeclaration')}
                        </AsyncButton>
                        <If condition={!PersonValidator.isCustomCustomerDeclaration(depositor)}>
                            <AsyncButton
                                onClick={onRemoveDocument}
                                variant={'outlined'}
                                className={styles.centerButton}
                            >
                                {t('pages-organizations.continueEditing')}
                            </AsyncButton>
                        </If>
                    </Stack>
                </Modal>
            )}
        </div>
    )
}

function Progress() {
    const depositor = useCurrentDepositor()

    return (
        <ProgressBar
            currentStep={currentStep(depositor).step}
            texts={useSteps(depositor)}
        />
    )
}

type Props = {
    inline?: boolean
}

export default function CustomerDeclarationWizardSection({inline = false}: Props) {
    const {t} = useTranslation()

    const depositor = useCurrentDepositor()

    const auth = useAuthorization(depositor?.id)
    const hasCustomerDeclarationPermission = auth.permissions.includes('DEPOSITOR__CUSTOMER_DECLARATION__EDIT')
        || auth.permissions.includes('DEPOSITOR__CUSTOMER_DECLARATION__APPROVE')
        || auth.permissions.includes('DEPOSITOR__CUSTOMER_DECLARATION__SIGN')

    if (!hasCustomerDeclarationPermission) return null

    if (inline) {
        return (
            <Fragment>
                <Progress/>
                {currentStep(depositor).element}
            </Fragment>
        )
    }
    return (
        <Paper title={t('pages-organizations.createNewCustomerDeclaration')}>
            <Progress/>
            {currentStep(depositor).element}
        </Paper>
    )
}
