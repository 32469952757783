import React, { useState } from 'react';
import {useSelector} from '#state/useSelector'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import {AdDto, Product} from '@fixrate/fixrate-query'
import { differenceInDays } from 'date-fns/esm'
import {useTracking} from "react-tracking"
import {Box, Collapse, Grid, LinearProgress, Pagination, Paper, Stack, Tab, Typography} from '@mui/material';
import {TabContext, TabList, TabPanel} from "@mui/lab";
import {useTranslation} from "react-i18next";
import {TransitionGroup} from "react-transition-group";
import AdInterestLabel from "#components/AdInterestLabel";
import {BENTO_PADDING} from "#pages/BankDashboard/components/bento/BentoCard";
import {InterestOutput} from "#components";

const ADS_PER_PAGE = 6

export const MyAds = () => {
    const {t} = useTranslation()

    const [tab, setTab] = useState<'activeAds'|'inactiveAds'>('activeAds')

    const session = useSelector(state => state.session)
    const { trackEvent } = useTracking({ page: 'BankDashboard', session })

    const products = useSelector(state => state.products)
    const ads = useSelector(state => state.ads.filter(ad => ad.bankId === state.bank?.id))
    const [page, setPage] = useState(0)

    const activeAds = ads.filter(ad => !ad.validity || isAfter(new Date(ad.validity), new Date()))
        .slice().sort((ad1, ad2) => isAfter(new Date(ad2.published), new Date(ad1.published)) ? 1 : -1)

    const lastInactiveAds = ads.filter(ad => ad.validity || isBefore(new Date(ad.validity), new Date()))
        .slice().sort((ad1, ad2) => isAfter(new Date(ad2.validity), new Date(ad1.validity)) ? 1 : -1)
        .slice(0, 3)

    const selectedActiveAds = activeAds.slice(page * ADS_PER_PAGE, (page * ADS_PER_PAGE) + ADS_PER_PAGE)

    const handleTabChange = (event: React.SyntheticEvent, newValue: 'activeAds'|'inactiveAds') => {
        setTab(newValue)
        setPage(0)
    }


    return (
        <Stack>
            <TabContext value={tab}>
                <TabList onChange={handleTabChange} sx={{px: BENTO_PADDING}}>
                    <Tab label={t('pages-BankDashboard.activeAds')} value="activeAds" icon={<i className='ri-play-line'/>}/>
                    <Tab label={t('pages-BankDashboard.inactiveAds')} value="inactiveAds" icon={<i className='ri-archive-line' />}/>
                </TabList>
                <TabPanel value={'activeAds'} sx={{py: 1, px: BENTO_PADDING}}>
                    {activeAds.length > 0 && (
                        <Grid container spacing={1} pb={2}>
                            <TransitionGroup component={null}>
                                    {selectedActiveAds.map(ad =>
                                        <Grid item key={ad.id} md={12} lg={6} component={Collapse}>
                                            <AdListItem ad={ad} product={products[ad.productId]}/>
                                        </Grid>
                                    )}
                            </TransitionGroup>
                        </Grid>
                    )}
                    {activeAds.length === 0 && <p>{t('pages-BankDashboard.noActiveAds')}</p>}
                    {activeAds.length > ADS_PER_PAGE &&
                        <Pagination
                            count={Math.ceil((activeAds.length / ADS_PER_PAGE))}
                            color={'primary'}
                            onChange={(_, page) => setPage(page-1)}
                        /> }
                </TabPanel>
                <TabPanel value={'inactiveAds'} sx={{py: 1, px: BENTO_PADDING}}>
                    {lastInactiveAds.length > 0 && (
                        <Grid container spacing={1} pb={2}>
                            <TransitionGroup component={null}>
                                {lastInactiveAds.map(ad =>
                                    <Grid item key={ad.id} md={12} lg={6} component={Collapse}>
                                        <AdListItem ad={ad} product={products[ad.productId]}/>
                                    </Grid>
                                )}
                            </TransitionGroup>
                        </Grid>
                    )}
                </TabPanel>
            </TabContext>
        </Stack>
    )
}

interface AdListItemProps {
    ad: AdDto,
    product: Product,
    inactive?: boolean,
    index?: number
}

const AdListItem = ({ad, product, inactive}: AdListItemProps) => {
    const {t} = useTranslation()
    const volumeOrdered = ad.volume - ad.remaining
    const daysActive = differenceInDays(new Date(ad.validity), new Date(ad.published))

    return (
        <Paper elevation={3} sx={{py: 3, px: 3,  width: '100%'}}>
            <Stack direction={'row'} justifyContent={'space-between'} alignItems={'center'} flexWrap={'wrap'} spacing={2}>
                <Stack direction={'column'} spacing={1} minWidth={'15rem'} >
                    <Box>
                        <Typography variant={'h3'}>{t(`common.volume${ad.volume === 1 ? '' : '_other'}`, {count: ad.volume})}</Typography>
                        <Typography variant={'subtitle1'}>{t(`pages-BankDashboard.volumeOrdered${volumeOrdered === 1 ? '' : '_other'}`, {count: volumeOrdered})}</Typography>
                    </Box>
                    <LinearProgress variant="determinate" value={(volumeOrdered / ad.volume) * 100} sx={{height: '1rem', borderRadius: '10rem', width: '100%'}}/>
                    {inactive &&
                        <Typography variant={'subtitle2'}>
                            {t(`pages-BankDashboard.activeDays${daysActive === 1 ? '' : '_other'}`, {count: daysActive})}
                        </Typography>
                    }
                </Stack>
                <Stack direction={'column'} spacing={1} alignItems={'flex-end'} justifyContent={'space-around'}>
                    <Typography variant={'interestBig'} ml={2}>{InterestOutput.format(ad.interest)}</Typography>
                    <AdInterestLabel productType={product.type} interestRate={ad.nominalInterestRate} interestRateBenchmark={ad.interestRateBenchmark} />
                </Stack>
            </Stack>
        </Paper>
    )
}
