import { PURPLE } from '#app/colors/colors';
import { nameWithRoles } from '#app/utilities/nameWithRoles';
import { useCommand } from '#command';
import NameOutput from '#pages/organizations/OrganizationDetail/UsersAndRoles/components/NameOutput';
import { PersonValidator } from '#services/PersonValidator';
import { useSelector } from '#state/useSelector';
import { DepositorDto, OrganisationalPersonDto, OrganisationalRole, SecurityRole } from '@fixrate/fixrate-query';
import { Box, Button, ButtonGroup, CircularProgress, ClickAwayListener, Grow, ListItemIcon, ListItemText, MenuItem, MenuList, Paper, Popper, Stack, SxProps, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EditPersonModal from './EditPersonModal';
import InviteModal from './InviteModal';
import styles from './PersonBadge.module.scss';

interface Props {
    person: OrganisationalPersonDto,
    depositor: DepositorDto,
    role?: OrganisationalRole,
    securityRole: SecurityRole,
    canDelete: boolean,
    disabled?: boolean,
    showActions?: boolean,
    getHeader?: (person: OrganisationalPersonDto) => React.ReactNode
    personalWarningsVisible?: boolean
    namesWithRoles?: boolean
    sx?: SxProps
}

export default function PersonBadge({person, depositor, role, securityRole, disabled, showActions, canDelete, getHeader, personalWarningsVisible = true, namesWithRoles, sx}: Props) {
    const {t} = useTranslation()
    const [loading, setLoading] = useState(false)
    const [buttonOpen, setButtonOpen] = useState(false)
    const [addInformationModalOpen, setAddInformationModalOpen] = useState(false)
    const [openInviteModal, setOpenInviteModal] = useState(false)
    const anchorRef = useRef<HTMLDivElement>(null);
    const {
        setPersonSecurityRoles: setPersonSecurityRolesCommand,
        setPersonOrganisationalRoles: setPersonOrganisationalRolesCommand
    } = useCommand()

    const isOwnAdminBadge = useSelector(state => securityRole === 'DEPOSITOR_ADMIN' && person.associatedUserId === state.session.id)
    const needsInvite = PersonValidator.needsInvite(depositor, person)

    function getIconStyles(person: OrganisationalPersonDto) {
        if(PersonValidator.isInvite(person)) return 'ri-mail-send-line'
        if(needsInvite) return 'ri-mail-add-line'
        return 'ri-account-circle-line'
    }

    const hasWarnings = PersonValidator.isCustomerDeclarationPerson(person) && !PersonValidator.isCompleteForCustomerDeclaration(depositor, person)
    const showWarnings = personalWarningsVisible && hasWarnings

    async function deleteRole(organisationalRole?: OrganisationalRole, securityRole?: SecurityRole) {

        setLoading(true)

        if(securityRole) {
            let updatedRoles = person.securityRoles.filter(r => r !== securityRole)

            // Special case. When deleting DEPOSITOR_ACCOUNT_HOLDER, we should also delete DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION
            // NB! The opposite is not true. We should not add DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION when a user is added to this list
            if (securityRole === 'DEPOSITOR_ACCOUNT_HOLDER') {
                updatedRoles = updatedRoles.filter(r => r !== 'DEPOSITOR_ACCOUNT_HOLDER_WITHOUT_AUTHORIZATION')
            }

            const {waitForCommand} = await setPersonSecurityRolesCommand(depositor.id, person.personId, updatedRoles)
            const success = await waitForCommand()
            if (!success) {
                console.error("Failed to update roles", updatedRoles)
            }

        } else if(organisationalRole) {
            const updatedRoles = person.organisationalRoles.filter(r => r !== organisationalRole)

            const {waitForCommand} = await setPersonOrganisationalRolesCommand(depositor.id, person.personId, updatedRoles)
            const success = await waitForCommand()
            if (!success) {
                console.error("Failed to update roles", updatedRoles)
            }
        }

        setLoading(false)
    }

    const buttonMenuOptions = [
        {icon: 'ri-edit-line', dataCy: "editPerson", text: t('pages-organizations.editPersonInfo'), onClick: () => setAddInformationModalOpen(true), visible: true},
        {icon: 'ri-close-line', dataCy: "deleteRole", text: t('pages-organizations.removeRole'), onClick: () => deleteRole(role, securityRole), visible: canDelete && !isOwnAdminBadge},
        {icon: 'ri-mail-send-line', dataCy: "sendInvitation", text: t('pages-organizations.sendInviteHeader'), onClick: () => setOpenInviteModal(true), visible: needsInvite},
    ]

    return (
        <Stack component="li" spacing={0.5} sx={sx}>
            { getHeader && <div className={styles.personBadgeHeader}>{getHeader(person)}</div> }
            <Grow in={true} timeout={800}>
                <ButtonGroup
                    data-cy-organisational-role={role}
                    data-cy-security-role={securityRole}
                    variant="contained"
                    color="backgroundWhite"
                    disabled={disabled ? true : false}
                    ref={anchorRef}
                    sx={{"& .MuiButtonGroup-firstButton": {borderColor: "rgba(0,0,0,0.1)"}}}>
                    <Button
                        sx={{fontSize: "1.4rem"}}
                        onClick={() => setAddInformationModalOpen(true)}
                        startIcon={showWarnings ? <i className="ri-error-warning-line red" /> : <i className={getIconStyles(person)}/> } >
                        { loading ? (
                            <CircularProgress size={20} />
                        ): (
                            <Stack alignItems={"flex-start"}>
                                <span data-cy="badge-name">{NameOutput.withBirthYearOnDuplicates(person, depositor)}</span>
                                {namesWithRoles && <Typography variant="caption">{nameWithRoles(person, depositor, t).orgRoles}</Typography> }
                            </Stack>
                        )}
                    </Button>
                    { showActions !== false && (
                        <Button
                            data-cy="badge-menu"
                            onClick={() => setButtonOpen(!buttonOpen)}
                            aria-controls={buttonOpen ? 'split-button-menu' : undefined}
                            aria-expanded={buttonOpen ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu">
                            <i className={"ri-more-2-fill"}/>
                        </Button>
                    )}
                </ButtonGroup>
            </Grow>
            <Popper
                sx={{
                    zIndex: 1,
                }}
                open={buttonOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
                placement="bottom-end"
            >
                {({ TransitionProps, placement }) => (
                <Grow
                    {...TransitionProps}
                >
                    <Paper>
                    <ClickAwayListener onClickAway={() => setButtonOpen(false)}>
                        <MenuList
                            disablePadding
                            id="split-button-menu"
                            autoFocusItem
                            sx={{color: PURPLE[900], textAlign: "left"}}>
                            { buttonMenuOptions.filter(o => o.visible).map((option, index) => (
                                <MenuItem data-cy={option?.dataCy} sx={{"& .MuiListItemIcon-root": {minWidth: 0}}} key={index} onClick={option.onClick}>
                                    <ListItemIcon sx={{fontSize: "1.8rem", backgroundColor: "transparent", minHeight: 0, minWidth: 0, color: "inherit"}}>
                                        <i className={option.icon} />
                                    </ListItemIcon>
                                    <ListItemText>
                                        <Typography fontSize={"1.4rem"}>{option.text}</Typography>
                                    </ListItemText>
                                </MenuItem>
                            ))}
                        </MenuList>
                    </ClickAwayListener>
                    </Paper>
                </Grow>
                )}
            </Popper>
            { showWarnings && (
                <Box>
                    <Button size={"small"} color="error" variant="outlined" data-cy="addMissingInformation" onClick={() => setAddInformationModalOpen(true)}>
                        {t('pages-organizations.missingInformation')}
                    </Button>
                </Box>
            )}
            <EditPersonModal open={addInformationModalOpen} person={person} onClose={() => setAddInformationModalOpen(false)}/>
            { openInviteModal && (
                <InviteModal personId={person.personId} close={() => setOpenInviteModal(false)}/>
            )}
        </Stack>
    )
}
