import styles from './AutoCloseModalConfirmation.module.scss'

type Props = {
    text,
    onClose: () => void
}

export default function AutoCloseModalConfirmation({text, onClose}: Props) {

    setTimeout(onClose, 1000)

    return (
        <div className={styles.popup}>
            <p className={styles.body}>
                {text}
            </p>
        </div>
    )
}
