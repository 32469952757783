import FxDialog from '#app/components/FxDialog/FxDialog';
import FxDialogActions from '#app/components/FxDialog/FxDialogActions';
import FxDialogContent from '#app/components/FxDialog/FxDialogContent';
import FxDialogTitle from '#app/components/FxDialog/FxDialogTitle';
import { useCommand } from '#command';
import { validateEmailAddress as ValidatorEmail } from '#services/validateFields';
import { Box, Button, InputLabel, TextField } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export interface ResendUserInviteProps {
    close?: () => void
    returnTo: string
    depositorId: string
    inviteId: string
    inviteEmail: string
    title?: string
}

export default function ResendUserInvite({depositorId, close = null, returnTo, inviteId, inviteEmail, title}: ResendUserInviteProps) {

    const {t} = useTranslation()

    const [email, setEmail] = useState(inviteEmail)
    const [error, setError] = useState({
        email: null,
    })

    const navigate = useNavigate()
    const {resendUserInvite} = useCommand()

    function validateEmail() {
        if (!email) {
            throw t('blocks-UsersList.inviteEmailMissing')
        }

        if (!ValidatorEmail(email)) {
            throw t('blocks-UsersList.inviteEmailInvalid')
        }
    }

    async function submit() {
        try {
            setError(prevState => ({...prevState, email: null}))
            validateEmail()
        } catch (err) {
            setError(prevState => ({...prevState, email: err}))
            return
        }

        try {
            const {waitForCommand} = await resendUserInvite(depositorId, inviteId, email)
            const success = await waitForCommand()
            if (success) {
                closeModal()
            }
        } catch (err) {
            console.error(err)
        }
    }

    function onBlur() {
        try {
            setError(prevState => ({...prevState, email: null}))
            validateEmail()
        } catch (err) {
            setError(prevState => ({...prevState, email: err}))
        }
    }

    const closeModal = () => {
        if (close) {
            close()
        } else {
            navigate(returnTo)
        }
    }

    return (
        <FxDialog
            maxWidth="sm"
            onClose={closeModal}
            open={true}
        >
            <FxDialogTitle onClose={closeModal}>
                {title ? title : t('blocks-UsersList.inviteResendHeading')}
            </FxDialogTitle>
            <FxDialogContent>
                <Box mt={2}>
                    <InputLabel
                        htmlFor="emailField"
                    >
                        {t('blocks-UsersList.inviteEmail')}
                    </InputLabel>
                    <TextField
                        id="emailField"
                        onBlur={onBlur}
                        onChange={e => setEmail(e.target.value)}
                        type="text"
                        value={email}
                        sx={{width: "30rem"}}
                    />
                    <p className="field-error-message">
                        {error.email}
                    </p>
                </Box>
            </FxDialogContent>
            <FxDialogActions>
                <Button onClick={closeModal}>
                    {t('common.cancel')}
                </Button>
                <Button
                    disabled={!!error.email}
                    onClick={submit}
                    variant="contained"
                    data-cy="sendButton"
                >
                    {t('common.send')}
                </Button>
            </FxDialogActions>
        </FxDialog>
    )
}
