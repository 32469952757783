import SendUserInvite from "#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/DepositorPersonList/SendUserInvite";
import InviteModal from "#app/pages/organizations/OrganizationDetail/UsersAndRoles/components/InviteModal";
import { personIsAccountCreator } from "#app/utilities/accountCreatorUtils";
import { DepositorDto, DepositorWarningType, OrganisationUserInviteDto } from "@fixrate/fixrate-query";
import { ErrorOutline } from "@mui/icons-material";
import { Alert, Button, Chip, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip, Typography } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { personHasWarning } from "./MissingInformationStep";

export const invitationWarnings: DepositorWarningType[] = ["PERSON_NEEDS_INVITE", "AUTHORIZATION_SIGNER_NEEDS_INVITE", "INVITE_IS_NOT_SENT"]

export const peopleWithTasks = (depositor: DepositorDto) => {
    return depositor?.people.filter(person => (
        person.securityRoles.includes('DEPOSITOR_SIGN_AUTHORIZATION') ||
        personIsAccountCreator(person) ||
        person.securityRoles.includes('DEPOSITOR_ORDER') ||
        person.securityRoles.includes('DEPOSITOR_PAYMENT')
    ) || []
)}

export const hasInvitationWarnings = (depositor: DepositorDto) => {
    const invitationPeople = peopleWithTasks(depositor)
    return invitationPeople.some(person => personHasWarning({
        type: invitationWarnings,
        depositor: depositor,
        person: person,
    }))
}

export async function copyUserInviteLink(userInvite: OrganisationUserInviteDto, setConfirmCopyModalInviteId: (id: string) => void) {
    const inviteLink = window.location.href.split(window.location.pathname)[0] + '/invite/' + userInvite?.id
    await navigator.clipboard.writeText(inviteLink)
    setConfirmCopyModalInviteId(userInvite.id)
    setTimeout(() => {
        setConfirmCopyModalInviteId(null)
    }, 2000)
}

export default function InvitationsStep({
    depositor,
}: {
    depositor: DepositorDto;
}) {
    const { t } = useTranslation()
    const [userInvite, setUserInvite] = useState<OrganisationUserInviteDto | null>(null)
    const [invitePerson, setInvitePerson] = useState(null)
    const [confirmCopyModalInviteId, setConfirmCopyModalInviteId] = useState<null | string>(null)
    const invitationPeople = peopleWithTasks(depositor)

    return (
        <>
            <Typography>
                {t('pages-OrganizationWizard.invitationsStepIntro')}
            </Typography>
            <Table sx={{ mt: 3 }}>
                <TableHead>
                    <TableRow>
                        <TableCell>{t('common.name')}</TableCell>
                        <TableCell>{t('common.status')}</TableCell>
                        <TableCell>{t('common.invitation')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {invitationPeople?.map((person) => (
                        <TableRow key={person.personId}>
                            <TableCell>
                                <Stack direction="row" spacing={0.6} alignItems={"center"}>
                                    {/*<i className={getUserIcon(person)} style={{fontSize: "2rem"}} />*/}
                                    <span>{person?.firstName} {person?.lastName}</span>
                                </Stack>
                            </TableCell>
                            <TableCell>
                                {personHasWarning({
                                    type: invitationWarnings,
                                    depositor: depositor,
                                    person: person,
                                }) && (
                                    <Chip
                                        icon={<ErrorOutline />}
                                        label={t('pages-OrganizationWizard.missingInvitation')}
                                        color="error"
                                    />
                                )}
                                {personHasWarning({
                                    type: ["INVITE_HAS_EXPIRED"],
                                    depositor: depositor,
                                    person: person,
                                }) && (
                                    <Chip
                                        icon={<ErrorOutline />}
                                        label={t('pages-OrganizationWizard.invitationExpired')}
                                        color="error"
                                    />
                                )}
                                {!personHasWarning({
                                    type: [...invitationWarnings, "INVITE_HAS_EXPIRED"],
                                    depositor: depositor,
                                    person: person,
                                }) && (
                                    <Chip
                                        icon={person.associatedUserId ? <i className="ri-user-follow-fill" /> : <i className="ri-mail-send-line" />}
                                        label={person.associatedUserId ? t('pages-OrganizationWizard.userRegistered') : t('pages-OrganizationWizard.invitationSent')}
                                        color="success"
                                    />
                                )}
                            </TableCell>
                            <TableCell>
                                {personHasWarning({
                                    type: invitationWarnings,
                                    depositor: depositor,
                                    person: person,
                                }) && (
                                    <Button
                                        onClick={() => {
                                            if(personHasWarning({type: ["INVITE_IS_NOT_SENT"], depositor: depositor, person: person})) {
                                                setUserInvite(depositor.userInvites.find(ui => ui.id === person.associatedInviteId))
                                            } else {
                                                setInvitePerson(person)
                                            }
                                        }}
                                        startIcon={
                                            <i className="ri-add-circle-line" />
                                        }
                                        variant="outlined"
                                        size="small"
                                    >
                                        {t('pages-OrganizationWizard.sendInvitation')}
                                    </Button>
                                )}
                                {personHasWarning({
                                    type: ["INVITE_HAS_EXPIRED"],
                                    depositor: depositor,
                                    person: person,
                                }) && (
                                    <Button
                                        onClick={() => {
                                            setUserInvite(depositor.userInvites.find(ui => ui.id === person.associatedInviteId))
                                        }}
                                        startIcon={
                                            <i className="ri-add-circle-line" />
                                        }
                                        variant="outlined"
                                        size="small"
                                    >
                                        {t('pages-OrganizationWizard.sendNewInvitation')}
                                    </Button>
                                )}
                                {!personHasWarning({
                                    type: [...invitationWarnings, "INVITE_HAS_EXPIRED"],
                                    depositor: depositor,
                                    person: person,
                                }) && depositor.userInvites.find(ui => ui.id === person.associatedInviteId) && (
                                    <Stack direction="row" spacing={2}>
                                        <Tooltip 
                                            open={confirmCopyModalInviteId === depositor.userInvites.find(ui => ui.id === person.associatedInviteId)?.id} 
                                            title={t('pages-OrganizationWizard.invitationLinkCopied')}>
                                            <Button onClick={() => copyUserInviteLink(depositor.userInvites.find(ui => ui.id === person.associatedInviteId), setConfirmCopyModalInviteId)} startIcon={confirmCopyModalInviteId === depositor.userInvites.find(ui => ui.id === person.associatedInviteId)?.id ? <i className="ri-check-line" /> : <i className="ri-file-copy-line" />} variant="outlined" size="small">
                                                {t('blocks-UsersList.copyToClipboard')}
                                            </Button>
                                        </Tooltip>

                                        <Button variant="outlined" size="small" onClick={() => setUserInvite(depositor.userInvites.find(ui => ui.id === person.associatedInviteId))}>
                                            {t('blocks-UsersList.sendAgain')}
                                        </Button>
                                    </Stack>
                                )}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            { !hasInvitationWarnings && (
                <Alert severity="success" sx={{my: 2}}>
                    {t('pages-OrganizationWizard.allInvitationsSent')}
                </Alert>
            )}
            {invitePerson && (
                <InviteModal personId={invitePerson.personId} close={() => setInvitePerson(false)} />
            )}

            {userInvite && (
                <SendUserInvite
                    depositorId={depositor.id}
                    inviteId={userInvite.id}
                    inviteEmail={userInvite.email}
                    close={() => setUserInvite(null)}
                    returnTo={window.location.pathname}
                />
            )}
        </>
    );
}
