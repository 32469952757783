import {Alert, Box, Button, Card, Divider, Stack, Typography} from '@mui/material'
import FixrateIcon, {IconName} from '#app/components/FixrateIcon/FixrateIcon'
import {BURNT_ORANGE, SILVER_GRAY, SUCCESS_GREEN} from '#app/colors/colors'

interface Props {
    title: string
    description: string
    icon: IconName
    onClick: () => void
    buttonText: string
    warnings: string[]
    disabled: boolean
}

export default function StatusCard({title, description, icon, onClick, buttonText, warnings, disabled}: Props) {
    return (
        <Card sx={{width: {xs: '100%', md: '39rem'}}}>
            <Stack justifyContent={'space-between'} height={'100%'}>
                <Stack direction={'row'} justifyContent="space-between" alignItems="center">
                    <Stack flex={3} spacing={1} pl={4} pt={3} pb={2}>
                        <Typography variant="h3">
                            {warnings.length > 0 && <i className="ri-error-warning-line" style={{color: BURNT_ORANGE[500], marginRight: 5}}/>}
                            {warnings.length === 0 && <i className="ri-checkbox-circle-line" style={{color: SUCCESS_GREEN[500], marginRight: 5}}/>}
                            <span>{title}</span>
                        </Typography>
                        <Typography color={SILVER_GRAY[500]} fontSize={'1.4rem'}>{description}</Typography>
                    </Stack>
                    <Box flex={2} sx={{width: '30rem'}}>
                        <FixrateIcon name={icon}/>
                    </Box>
                </Stack>
                {warnings.length > 0 && (
                    <Stack px={4} pb={2} spacing={0.5} height={'100%'} alignItems={'flex-start'}>
                        {warnings.map(warning => (
                            <Alert key={warning} icon={false} severity={'error'} sx={{py: 0, px: 1}}>{warning}</Alert>
                        ))}
                    </Stack>
                )}
                <Box>
                    <Divider/>
                    <Button disabled={disabled} sx={{px: 4, justifyContent: 'space-between', textDecoration: 'none', '&:hover': {textDecoration: 'none'}, borderRadius: 0}} endIcon={<i className="ri-arrow-right-line"/>} fullWidth onClick={onClick}>{buttonText}</Button>
                </Box>
            </Stack>
        </Card>
    )
}
