import { createRoot } from 'react-dom/client';
import config from '#app/config'
import {EndPointProvider} from '#command'
import '#services/i18n'
import {initializeChartJs} from '#services/initializeChartJs'
import {installSentry} from '#services/sentry'
import reduxStore from '#store'
import 'chartjs-adapter-date-fns'
import nb from 'date-fns/locale/nb'
import merge from 'lodash/merge'
import {Suspense} from 'react'
import {defaults} from 'react-chartjs-2'
import Modal from 'react-modal'
import {QueryClient, QueryClientProvider} from 'react-query'
import {Provider} from 'react-redux'
import {BrowserRouter} from 'react-router-dom'
import {track} from 'react-tracking'
import 'swiper/css'
import 'swiper/css/scrollbar'
import '../style/base.scss'
import '../style/blocks.scss'
import '../style/swiper-overrides.scss'
import '../style/fonts/montserrat.css'
import './hotjar'
import '@fixrate/fixrate-analytics-widgets/index.css'
import Root from './pages/Root'
import * as hubspot from './services/hubspot'
import {LocalStorageProvider} from '#services/LocalStorageContext'
import {SuspenseLoader} from './layers/SuspenseLoader/SuspenseLoader'
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import * as analytics from '#app/services/analytics'
//
// Performs technical initialization that does not depend on redux store or user session
//   - bootstrap global variables
//   - connect to external services
//   - start background services
//   - etc.
//
const queryClient = new QueryClient()
initializeChartJs()

installSentry()

hubspot.loadHubspot()

// react-chartjs-2 configuration
merge(defaults, {
    global: {
        defaultFontFamily: 'Montserrat',
        defaultFontColor: 'rgba(0, 0, 0, 0.7)',
        defaultFontSize: 12,
        defaultUsePointStyle: true,
        elements: {
            line: {
                fill: false,
            },
        },
        legend: {
            labels: {
                usePointStyle: true,
            },
        },
    },
    scale: {
        adapters: {
            date: {
                locale: nb,
            },
        },
    },
})

// The root app element in index.html
const appElement = document.getElementById('app')

// Register appElement so that Modal may hide it from screen readers when a modal window is open
Modal.setAppElement(appElement)

if (!config().isProduction && !('reduxStore' in window)) {
    Object.defineProperty(window, 'reduxStore', {
        get() {
            return reduxStore.getState()
        },
    })
}

const TrackedApp = track(
    {app: 'Marketplace'},
    {
        dispatch: (data) => {
            analytics.pageEvent(window.location.pathname, data)
        },
    },
)(Root)

// Rendering React application
const root = createRoot(appElement);
root.render(
    <Suspense fallback={SuspenseLoader}>
        <Provider store={reduxStore}>
            <QueryClientProvider client={queryClient}>
                <BrowserRouter>
                    <EndPointProvider>
                        <LocalStorageProvider>
                            <TrackedApp/>
                        </LocalStorageProvider>
                    </EndPointProvider>
                </BrowserRouter>
            </QueryClientProvider>
        </Provider>
    </Suspense>
)
