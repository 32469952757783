import { BURNT_ORANGE, PURPLE, SILVER_GRAY, SUCCESS_GREEN } from "#app/colors/colors";
import { InterestOutput } from "#app/components";
import FixrateIllustration from "#app/components/FixrateIllustration/FixrateIllustration";
import { switchOrganisation } from "#app/services/thunks/session";
import { useOnboardingWizardStatus } from "#app/utilities/useOnboardingWizardStatus";
import { PartnerCustomerDto } from "@fixrate/fixrate-query";
import { Box, Button, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Paper, Stack, Typography, useTheme } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";
import Tada from '../../../organizations/AddNewOrganization/OrganizationWizard/illustrations/tada.svg'

const CustomerDetailRegistrationStatus = ({customer} : {customer: PartnerCustomerDto}) => {
    const theme = useTheme()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { t } = useTranslation()
    const onboardingWizardStatus = useOnboardingWizardStatus(customer)
    const onboardingWizardSteps = onboardingWizardStatus ? Object.keys(onboardingWizardStatus?.status)?.map(key => ({
        name: key,
        done: onboardingWizardStatus?.status[key]
    })) : []

    const lastStepDone = onboardingWizardSteps?.findIndex(step => step.done === false) - 1 || 0

    const registrationComplete = onboardingWizardStatus?.progress === 100

    function onSelectRole(navigateAfterSwitch: () => void) {
        dispatch(switchOrganisation('DEPOSITOR', customer.depositorId, navigateAfterSwitch))
    }

    return (
        <Stack direction={{ lg: "row" }} alignItems="stretch">
            <Paper
                sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: {lg: "calc(100% - 42rem)"},
                    zIndex: 2,
                    mb: 0,
                    borderRadius: `${theme.shape.borderRadius}px`,
                    borderTopRightRadius: 0,
                    borderBottomRightRadius: 0,
                }}
            >
                <Stack px={{xs: 4, lg: 6}} py={8} alignItems={{xs: "flex-start", lg: "center"}} justifyContent="center" spacing={2}>
                    <Typography variant="h1">
                        {registrationComplete ? t('pages-customers.readyForProposal') : t('pages-customers.finishAndSendOffer')}
                    </Typography>
                    <Typography fontSize={{xs: "1.6rem", md: "2rem"}} color={SILVER_GRAY[500]}>
                        {registrationComplete ? t('pages-OrganizationWizard.accountantReadyMessage') : t('pages-customer.finishAndSendOfferDescription')}
                    </Typography>
                    <Stack maxWidth={{md: "40rem"}}>
                        { registrationComplete ? 
                            <img src={Tada} alt="" style={{maxWidth: "20rem"}}/>
                            : <FixrateIllustration
                                name="manCoffeeDocument"
                                color="default"/> 
                        }
                    </Stack>
                    { registrationComplete ? (
                        <Button
                            variant="contained"
                            size="large"
                            href="/marketplace/category/deposits/under-20"
                        >
                            {t('pages-OrganizationWizard.findOffers')}
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() =>
                                onSelectRole(() =>
                                    navigate(
                                        onboardingWizardStatus?.currentSlug
                                    )
                                )
                            }
                        >
                            {t('common.finishRegistration')}
                        </Button>
                    )}
                </Stack>
            </Paper>
            <Stack
                px={{xs: 4, lg: 5}}
                py={4}
                width={{lg:"42rem"}}
                spacing={2}
                sx={{
                    backgroundColor: PURPLE[700],
                    color: PURPLE[25],
                    borderRadius: {
                        xs: `0 0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px`,
                        md: `0 ${theme.shape.borderRadius}px ${theme.shape.borderRadius}px 0`
                    },
                    position: "relative",
                    zIndex: 1,
                    boxShadow: "1px 1px 3px rgba(0, 0, 0, 0.2)",
                }}
            >
                <Box>
                    <Typography variant="h2" color={"inherit"}>
                        { onboardingWizardStatus?.progress === 100 ? t('common.registrationComplete') : t('common.registrationProcess')}
                    </Typography>
                    <Typography
                        variant="caption"
                        fontWeight="700"
                        color={PURPLE[25]}
                    >
                        {InterestOutput.formatWithDecimals(
                            onboardingWizardStatus?.progress,
                            0
                        )}{" "}
                        {t("pages-OrganizationWizard.statusFinished")}
                    </Typography>
                    <LinearProgress
                        sx={{
                            "& .MuiLinearProgress-barColorPrimary": {
                                backgroundColor: PURPLE[200],
                            },
                            width: "100%",
                            marginTop: "0.6rem",
                            height: "0.8rem",
                            borderRadius: "10rem",
                        }}
                        variant={"determinate"}
                        value={onboardingWizardStatus?.progress}
                    />
                </Box>
                <Stack>
                    <List sx={{ py: 0 }}>
                        {onboardingWizardSteps.map((step, index) => (
                            <Fragment key={step.name}>
                                <ListItem sx={{ px: 0, py: 1.5, my: 0 }}>
                                    <ListItemIcon
                                        sx={{
                                            minHeight: "2.8rem",
                                            minWidth: "2.8rem",
                                            width: "2.8rem",
                                            height: "2.8rem",
                                            backgroundColor: ((step.done && lastStepDone >= index) || onboardingWizardStatus?.progress === 100)
                                                ? SUCCESS_GREEN[500]
                                                : BURNT_ORANGE[500],
                                        }}
                                    >
                                        {((step.done && lastStepDone >= index) || onboardingWizardStatus?.progress === 100) ? (
                                            <i
                                                className="ri-check-line"
                                                style={{ color: "white" }}
                                            />
                                        ) : (
                                            <i
                                                className="ri-information-line"
                                                style={{ color: "white" }}
                                            />
                                        )}
                                    </ListItemIcon>
                                    <ListItemText
                                        sx={{
                                            "& .MuiListItemText-primary": {
                                                fontSize: "1.4rem",
                                            },
                                        }}
                                    >
                                        {t(
                                            "pages-OrganizationWizard." +
                                                step.name
                                        )}
                                    </ListItemText>
                                </ListItem>
                            </Fragment>
                        ))}
                    </List>
                </Stack>
            </Stack>
        </Stack>
    );
};

export {CustomerDetailRegistrationStatus}