import MessageBanner from '#app/components/MessageBanner/MessageBanner';
import ConfirmationModal from '#app/layers/ConfirmationModal/ConfirmationModal';
import ConfirmModal from '#app/layers/ConfirmModal/ConfirmModal';
import DummyChatBubble from '#app/layers/DummyChatBubble/DummyChatBubble';
import InfoMessages from '#app/layers/InfoMessages/InfoMessages';
import SupportPane from '#app/layers/SupportPane/SupportPane';
import TimeoutWarning from '#app/layers/TimeoutWarning/TimeoutWarning';
import { ErrorBoundary } from '#blocks/ErrorBoundary/ErrorBoundary';
import StatusMessageBanner from '#blocks/StatusMessageBanner/StatusMessageBanner';
import AcceptInvite from '#blocks/UsersList/AcceptInvite';
import BankAds from '#pages/BankAds/BankAds';
import { BankDashboard } from '#pages/BankDashboard/BankDashboard';
import CustomerList from '#pages/Customers/Customers';
import { Fixtures } from '#pages/fixtures/Fixtures';
import FundAdminOverview from "#pages/FundAdminOverview/FundAdminOverview";
import AdminFundPlacements from "#pages/FundAdminOverview/FundPlacements/AdminFundPlacements";
import FundBuyOrderOverview from '#pages/FundBuyOrderOverview/FundBuyOrderOverview';
import FundCheckoutConfirmation from '#pages/FundCheckoutConfirmation/FundCheckoutConfirmation';
import FundCustomers from '#pages/FundCustomers/FundCustomers';
import FundInvoiceOverview from "#pages/FundInvoiceReports/FundInvoiceOverview";
import FundNavOverviewCalendar from '#pages/FundNavOverview/FundNavOverviewCalendar';
import { FundRetrocessionDistribution } from "#pages/FundRetrocessionDistribution/FundRetrocessionDistribution";
import FundSellOrderOverview from '#pages/FundSellOrderOverview/FundSellOrderOverview';
import FundTransactions from '#pages/FundTransactions/FundTransactions';
import FundTransactionsImport from "#pages/FundTransactions/FundTransactionsImport";
import FundTransactionsImportCustomer from "#pages/FundTransactions/FundTransactionsImportCustomer";
import TestingGrounds from '#pages/TestingGrounds/TestingGrounds';
import { setHubspotConversationSettings } from '#services/hubspot';
import { useFeatures } from '#services/useFeatures';
import useReload from '#services/useReload';
import useUiSetting from "#services/useUiSetting";
import * as selectors from '#state/selectors';
import { useSelector } from '#state/useSelector';
import { isBefore } from 'date-fns';
import { useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, Route, Routes, useLocation, useParams } from 'react-router-dom';
import Analytics from './Analytics/Analytics';
import AnalyticsDashboards from './Analytics/AnalyticsDashboards';
import Bank from './bank/Bank';
import DepositorOfferBank from './DepositorOfferBank/DepositorOfferBank';
import DepositorOfferDepositor from './DepositorOfferDepositor/DepositorOfferDepositor';
import Documents from './documents/Documents';
import Inbox from './Inbox/Inbox';
import IndexRedirectPage from './IndexRedirectPage';
import InterestRateChangeBank from './InterestRateChangeBank/InterestRateChangeBank';
import Marketplace from './marketplace/DepositMarketplace';
import MarketplaceCategoryRoute from './marketplace/MarketplaceCategoryRoute';
import SendPartnerProposal from './marketplace/SendPartnerProposal/SendPartnerProposal';
import { CAKE_CAMPAIGN } from './marketplace/StartOrder/StartOrder';
import Menu from './menu/Menu';
import MyBank from './mybank/MyBank';
import NewCustomerDeclarations from './newcustomerdeclarations/NewCustomerDeclarations';
import OrdersBank from './orders/bank/OrdersBank';
import OrdersDepositor from './orders/depositor/OrdersDepositor';
import Organizations from './organizations/Organizations';
import PartnerContract from './partnerContract/partnerContract';
import PartnerLogoutLanding from './PartnerDepositorProposalWizard/PartnerLogoutLanding';
import DepositorAccountProposals from './PartnerProposals/DepositorAccountProposals';
import DepositorPartnerSignature from './PartnerProposals/DepositorPartnerSignature';
import DepositorProposalRoutes from './PartnerProposals/DepositorProposalRoutes';
import PartnerProposalRoutes from './PartnerProposals/PartnerProposalRoutes';
import RedirectProposalInvite from './PartnerProposals/RedirectProposalInvite';
import PartnerUsers from './partnerUsers/PartnerUsers';
import ReportsBank from './portfolio-bank/BankReports';
import PortfolioBank from './portfolio-bank/PortfolioBank';
import PortfolioDepositor from './portfolio-depositor/PortfolioDepositor';
import DepositorReportsRoutes from './portfolio-depositor/Reports/DepositorReportsRoutes';
import Profile from './profile/Profile';
import Reports from './report/Reports';
import Settings from './settings/Settings';
import Signature from './signature/Signature';
import SignatureInbox from './SignatureInbox/SignatureInbox';

function RedirectAdLink() {
    // Encapsulates the redirection to be able to capture adId
    const { adId } = useParams();
    return <Navigate to={`/marketplace/category/deposits/overview/${adId || ''}`} />;
}


export default function Layout() {
    const {t, i18n} = useTranslation()
    const location = useLocation()
    const hasOnlySignAuthorizationRole = useSelector(state => selectors.hasOnlySignAuthorizationRole(state))
    const numberOfAuthorizationDocumentsToSignForUser = useSelector(state => selectors.authorizationDocumentsToSign(state).length)
    const messagesIsLoaded = useSelector(state => state.loaded.messages)
    const isDepositor = useSelector(selectors.isDepositorSelector)
    const association = useSelector(state => state.session?.association)
    const isPartner = association?.organisationType === "PARTNER"
    const authenticated = useSelector(state => state.session?.authenticated)
    const email = useSelector(state => state.session?.email)
    const hubspotVisitorToken = useSelector(state => state.session?.hubspotVisitorToken)
    const redirectIsResolved = useRef(false) // Using a ref so that this redirect is only resolved once after the component is loaded and the depositor slice is loaded
    const pageTitleKey = location.pathname.split('/')[1]
    const features = useFeatures()
    const reloadMessage = useReload()
    const loggedIn = useSelector(selectors.isLoggedIn)

    const showFreeCakeMessage = CAKE_CAMPAIGN.active
    const [visibleMessage, setVisibleMessage] = useUiSetting('10MRDCakeMessage', true)

    const showAnnualStatementsMessage = isBefore(new Date(), new Date('2024-03-01'))
    const [visibleAnnualMessage, setVisibleAnnualMessage] = useUiSetting('annualStatementsMessage', true)

    useEffect(() => {
        const title = i18n.exists(`pagetitles.${pageTitleKey}`) ? t(`pagetitles.${pageTitleKey}`) : ''
        document.title = (window['FIXRATE'] ? (window['FIXRATE'].ENVIRONMENT + '-') : '') + 'Fixrate' + (title ? ' - ' + title : '')
    }, [location, pageTitleKey, t, i18n])

    if (!redirectIsResolved.current && messagesIsLoaded) {
        if (hasOnlySignAuthorizationRole && numberOfAuthorizationDocumentsToSignForUser > 0 && pageTitleKey !== 'invite') {
            const pathToSignatures = '/sign'
            if (location.pathname !== pathToSignatures && !location.pathname.startsWith('/signature')) {
                return <Navigate to={pathToSignatures}/>
            }
        }
        redirectIsResolved.current = true
    }

    // todo This should maybe be placed elsewhere
    // If deleted, the chat box state may be still there after logout
    setHubspotConversationSettings({ authenticated: authenticated, email: email, hubspotVisitorToken: hubspotVisitorToken })

    const redirectFromBaseUrl = () => {
        if (authenticated && association?.organisationType === 'BANK') {
            return <Navigate to='/bank-dashboard'/>
        }
        if (authenticated && association?.organisationType === 'PARTNER') {
            return <Navigate to='/customer'/>
        }
        return <Navigate to='/marketplace'/>
    }

    return (
        <>
            <Menu />
            <div style={{ flex: '1' }}>
                {reloadMessage}
                <StatusMessageBanner/>
                {(showFreeCakeMessage && visibleMessage && loggedIn) && (
                    <MessageBanner
                        title={CAKE_CAMPAIGN.banner.title}
                        icon="party"
                        buttonText={t('common.close')}
                        onClick={() => setVisibleMessage(false)}
                        description={CAKE_CAMPAIGN.banner.message}
                    />
                )}
                {isDepositor && showAnnualStatementsMessage && visibleAnnualMessage && (
                    <MessageBanner
                        title='Angående årsoppgaver'
                        buttonText={t('common.close')}
                        onClick={() => setVisibleAnnualMessage(false)}
                        description='Årsoppgaver distribueres fortløpende av bankene. Årsoppgavene finner du under Rapporter i menyen på venstre side, på fanen Årsrapporter.'
                    />
                )}
                <ErrorBoundary>
                    <Routes>
                        <Route path='/' element={redirectFromBaseUrl()} />
                        <Route path='/marketplace/category/*' element={<MarketplaceCategoryRoute/>}/>
                        <Route path='/marketplace/*' element={<MarketplaceCategoryRoute/>}/>
                        <Route path='/login' element={<Navigate to='/marketplace/category/deposits/overview?login'/>}/>
                        <Route path='/marketplace/overview/:adId' element={<RedirectAdLink/>}/>
                        <Route path='/marketplace/overview'
                               element={<Navigate to='/marketplace/category/deposits/overview/'/>}/>
                        <Route path='/marketplace/under-20/:adId'
                               element={<Marketplace key={'/marketplace/under-20'} depositMarketPlaceType={'LOW'}
                                                     url='/marketplace/under-20'/>}/>
                        <Route path='/marketplace/under-20/'
                               element={<Marketplace key={'/marketplace/under-20'} depositMarketPlaceType={'LOW'}
                                                     url='/marketplace/under-20'/>}/>
                        <Route path='/marketplace/over-20/:adId'
                               element={<Marketplace key={'/marketplace/over-20'} depositMarketPlaceType={'HIGH'}
                                                     url='/marketplace/over-20'/>}/>
                        <Route path='/marketplace/over-20'
                               element={<Marketplace key={'/marketplace/over-20'} depositMarketPlaceType={'HIGH'}
                                                     url='/marketplace/over-20'/>}/>
                        <Route path='/proposals' element={<DepositorAccountProposals/>}/>
                        <Route path='/marketplace/send-proposal/:proposalAdId' element={<SendPartnerProposal/>}/>
                        <Route path='/partner/proposals/*'
                               element={isPartner ? <PartnerProposalRoutes/> : <DepositorProposalRoutes/>}/>
                        <Route path='/partner-proposal/:partnerId/:proposalId/:inviteId'
                               element={<RedirectProposalInvite/>}/>
                        <Route path='/partner/signature/:partnerId' element={<DepositorPartnerSignature/>}/>
                        <Route path='/partner/logged-out/:partnerId' element={<PartnerLogoutLanding/>}/>
                        {/* TODO: This is fallback routes, to be removed in 2 months */}
                        <Route path='/marketplace/:param1/:param2'
                               element={<Marketplace depositMarketPlaceType={'SINGLE_AD'}
                                                     url='/marketplace/overview'/>}/>
                        <Route path='/marketplace/:param1' element={<Marketplace depositMarketPlaceType={'SINGLE_AD'}
                                                                                 url='/marketplace/overview'/>}/>

                        {features.fund &&
                            <>
                                <Route path="/checkout/fund/buy" element={<FundCheckoutConfirmation type={'BUY'}/>}/>
                                <Route path="/checkout/fund/sell" element={<FundCheckoutConfirmation type={'SELL'}/>}/>
                            </>
                        }
                        <Route path="/bank-dashboard/new-ad" element={<BankDashboard/>}/>
                        <Route path="/bank-dashboard" element={<BankDashboard/>}/>
                        <Route path="/orders/*" element={<OrdersDepositor/>}/>
                        <Route path="/bank-orders/*" element={<OrdersBank/>}/>
                        <Route path="/portfolio/*" element={<PortfolioDepositor/>}/>
                        <Route path="/reports/*" element={<DepositorReportsRoutes/>}/>
                        <Route path="/portfolio-bank/*" element={<PortfolioBank/>}/>
                        <Route path="/bank-reports/*" element={<ReportsBank/>}/>
                        <Route path="/organizations/*" element={<Organizations/>}/>
                        <Route path="/bank/:id" element={<Bank/>}/>
                        <Route path="/signature/:processId" element={<Signature/>}/>
                        <Route path="/ads/deactivate/:id" element={<BankAds/>}/>
                        <Route path="/ads/deactivate" element={<BankAds/>}/>
                        <Route path="/ads/new-ad/:type" element={<BankAds/>}/>
                        <Route path="/ads/new-ad" element={<BankAds/>}/>
                        <Route path="/ads" element={<BankAds/>}/>
                        <Route path="/profile/*" element={<Profile/>}/>
                        <Route path="/report" element={<Reports/>}/>
                        <Route path="/settings" element={<Settings/>}/>
                        <Route path="/identification" element={<h1>Laster</h1>}/>
                        <Route path="/documents" element={<Documents/>}/>
                        <Route path="/my-bank/:showForm/:userId" element={<MyBank/>}/>
                        <Route path="/my-bank/:showForm" element={<MyBank/>}/>
                        <Route path="/my-bank" element={<MyBank/>}/>
                        <Route path="/analytics/depositor/dashboard" element={<AnalyticsDashboards/>}/>
                        <Route path="/analytics/*" element={<Analytics/>}/>
                        <Route path="/newcustomerdeclarations/:depositorId" element={<NewCustomerDeclarations/>}/>
                        <Route path="/newcustomerdeclarations" element={<NewCustomerDeclarations/>}/>
                        <Route path="/interestratechange-bank/*" element={<InterestRateChangeBank/>}/>
                        <Route path="/invite/:inviteId" element={<AcceptInvite/>}/>
                        <Route path="/inbox" element={<Inbox/>}/>
                        <Route path="/sign/*" element={<SignatureInbox/>}/>
                        <Route path="/depositoroffer/*" element={<DepositorOfferDepositor/>}/>
                        <Route path="/depositoroffer-bank/:depositorOfferId/:modal" element={<DepositorOfferBank/>}/>
                        <Route path="/depositoroffer-bank/:depositorOfferId" element={<DepositorOfferBank/>}/>
                        <Route path="/depositoroffer-bank" element={<DepositorOfferBank/>}/>
                        <Route path="/partner/users/:showForm/:userId" element={<PartnerUsers/>}/>
                        <Route path="/partner/users/:showForm" element={<PartnerUsers/>}/>
                        <Route path="/partner/users" element={<PartnerUsers/>}/>
                        <Route path="/partner/contract" element={<PartnerContract/>}/>
                        <Route path="/customer/*" element={<CustomerList/>}/>
                        {features.fund &&
                            <>
                                <Route path="/fund-buy-orders" element={<FundBuyOrderOverview/>}/>
                                <Route path="/fund-sell-orders" element={<FundSellOrderOverview/>}/>
                                <Route path="/fund-transactions" element={<FundTransactions/>}/>
                                <Route path="/fund-transactions/import" element={<FundTransactionsImport/>}/>
                                <Route path="/fund-transactions/import/:depositorId"
                                       element={<FundTransactionsImportCustomer/>}/>
                                <Route path="/fund-admin-overview" element={<FundAdminOverview/>}/>
                                <Route path="/fund-admin-overview/fund-placements" element={<AdminFundPlacements/>}/>
                                <Route path="/fund-invoice" element={<FundInvoiceOverview/>}/>
                                <Route path="/fund-retrocession-distribution" element={<FundRetrocessionDistribution/>}/>
                                <Route path="/fund-nav-overview" element={<FundNavOverviewCalendar/>}/>
                                <Route path="/fund-customers/*" element={<FundCustomers/>}/>
                            </>
                        }
                        <Route path="/testing-grounds" element={<TestingGrounds/>}/>
                        <Route path="/fixtures" element={<Fixtures/>}/>
                        <Route path="/" element={<IndexRedirectPage/>}/>
                    </Routes>
                </ErrorBoundary>
            </div>
            <InfoMessages/>
            <ConfirmModal/>
            <ConfirmationModal/>
            <TimeoutWarning/>
            <SupportPane/>
            <DummyChatBubble/>
        </>
    )
}
